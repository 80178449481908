export enum HTTPResponseStatus {
    NotFound = 404
}

export class HTTPError extends Error {
    constructor(
        title: string,
        public status: HTTPResponseStatus,
        public details?: string
    ) {
        super(`HTTPError: ${title}`);
    }
}
