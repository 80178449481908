import { ReactNode } from 'react';
import { Card, PageHeader, PageHeaderProps, Typography } from 'antd';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const { Paragraph } = Typography;

const SectionWrapper = styled(Card)<SpaceProps>`
    background-color: ${({ theme }) => theme.colors.white};
    ${space}
`;
const SectionHeader = styled(PageHeader)<SpaceProps>`
    ${space}
`;

const SectionDescription = styled(Paragraph)<SpaceProps>`
    ${space}
`;

interface Props extends SpaceProps {
    title?: string;
    description?: string;
    tags?: PageHeaderProps['tags'];
    extra?: ReactNode[];
    children: ReactNode;
}

export const Section = ({
    title,
    description,
    tags,
    extra,
    children,
    ...rest
}: Props) => (
    <SectionWrapper {...rest} mb={16}>
        {title && (
            <SectionHeader
                title={title}
                tags={tags}
                extra={extra}
                footer={
                    description && (
                        <SectionDescription type="secondary" mt={-16}>
                            {description}
                        </SectionDescription>
                    )
                }
                pt={0}
                px={0}
            />
        )}
        {children}
    </SectionWrapper>
);
