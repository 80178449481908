import { IntlShape } from 'react-intl';
import { Form, Input, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
    Limit,
    LimitStatus,
    LimitType,
    UnlimitedOption
} from '@olxeu-monetization/user-product-service-api-client';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { InputDurationFallbackMode } from '../../components/InputDuration';
import { Messages } from '../../intl';
import { theme } from '../../styles/theme';
import { Duration } from '../../types';
import { LimitFormValues } from '../LimitFormLayout/types';

const CapacityInputWrapper = styled.div`
    ${({ theme }) => `
        min-width: 132px;
    `}
`;

export interface LimitTableDataType {
    key: number;
    type: LimitType;
    validity: string;
    capacity: number;
    unlimited: boolean;
    status: string;
    isFromApi?: boolean;
}

export const getHumanTimeLabel = (formattedDuration: string) => {
    return Duration.fromFormatted(formattedDuration).toHumanized();
};

export const buildTableColumns = (intl: IntlShape) => {
    const { formatMessage } = intl;

    const columns: ColumnsType<LimitTableDataType> = [
        {
            title: formatMessage(Messages['common.label.type']),
            dataIndex: 'type',
            key: 'type',
            render: (text: string) => <b>{text}</b>
        },
        {
            title: formatMessage(Messages['common.label.validity']),
            dataIndex: 'validity',
            key: 'validity'
        },
        {
            title: formatMessage(Messages['common.label.capacity']),
            dataIndex: 'capacity',
            key: 'capacity'
        },
        {
            title: formatMessage(Messages['common.label.unlimited']),
            key: 'unlimited',
            dataIndex: 'unlimited',
            render: (isUnlimited) =>
                isUnlimited ? (
                    <Tag color={theme.colors.tagStatusEnabled}>
                        {formatMessage(Messages['common.label.yes'])}
                    </Tag>
                ) : (
                    <Tag>{formatMessage(Messages['common.label.no'])}</Tag>
                )
        },
        {
            title: formatMessage(Messages['common.label.status']),
            key: 'status',
            dataIndex: 'status',
            render: (status) =>
                status === LimitStatus.Active ? (
                    <Tag color={theme.colors.tagStatusEnabled}>
                        {formatMessage(Messages['common.label.enabled'])}
                    </Tag>
                ) : (
                    <Tag>
                        {formatMessage(Messages['common.label.disabled'])}
                    </Tag>
                )
        }
    ];

    return columns;
};

export const buildFormTableColumns = (
    intl: IntlShape,
    isDefaultLimitDefined: boolean,
    removeLimitType: (limitType: LimitType) => void,
    formValues?: LimitFormValues
) => {
    if (!formValues) return;

    const { formatMessage } = intl;
    const columns: ColumnsType<LimitTableDataType> = [
        {
            title: formatMessage(Messages['common.label.type']),
            dataIndex: 'type',
            key: 'type',
            render: (text: string, row) => (
                <>
                    <b>{text}</b>
                    <Form.Item
                        name={[row.type, 'type']}
                        label={false}
                        initialValue={text}
                    >
                        <Input value={text} hidden />
                    </Form.Item>
                </>
            )
        },
        {
            title: formatMessage(Messages['common.label.validity']),
            dataIndex: 'validity',
            key: 'validity',
            render: (_, row, index) => {
                const limitType = row.type;
                const isUnlimited = formValues[limitType]?.unlimited;
                const disableOutsideDefaultLimit =
                    !isDefaultLimitDefined && index > 0;

                return (
                    <FormInput
                        disabled={disableOutsideDefaultLimit || isUnlimited}
                        name={[limitType, 'validity']}
                        label={false}
                        required={!isUnlimited}
                        showExpirationSwitch={false}
                        type={FormFieldType.Duration}
                        fallbackMode={InputDurationFallbackMode.DisplayEmpty}
                    />
                );
            }
        },
        {
            title: formatMessage(Messages['common.label.capacity']),
            dataIndex: 'capacity',
            key: 'capacity',
            render: (_, row, index) => {
                const limitType = row.type;
                const isUnlimited = formValues[limitType]?.unlimited;
                const disableOutsideDefaultLimit =
                    !isDefaultLimitDefined && index > 0;

                return (
                    <CapacityInputWrapper>
                        <FormInput
                            disabled={disableOutsideDefaultLimit || isUnlimited}
                            name={[limitType, 'capacity']}
                            label={false}
                            required={!isUnlimited}
                            type={FormFieldType.Number}
                            min={0}
                        />
                    </CapacityInputWrapper>
                );
            }
        },
        {
            title: formatMessage(Messages['common.label.unlimited']),
            key: 'unlimited',
            dataIndex: 'unlimited',
            render: (_, row, index) => {
                const limitType = row.type;
                const disableOutsideDefaultLimit =
                    !isDefaultLimitDefined && index > 0;

                return (
                    <FormInput
                        disabled={disableOutsideDefaultLimit}
                        name={[limitType, 'unlimited']}
                        label={false}
                        required={false}
                        type={FormFieldType.Switch}
                    />
                );
            }
        },
        {
            title: formatMessage(Messages['common.label.status']),
            key: 'status',
            dataIndex: 'status',
            render: (_, row, index) => {
                const limitType = row.type;
                const disableOutsideDefaultLimit =
                    !isDefaultLimitDefined && index > 0;

                return (
                    <FormInput
                        disabled={disableOutsideDefaultLimit}
                        name={[limitType, 'status']}
                        label={false}
                        required={false}
                        type={FormFieldType.Switch}
                    />
                );
            }
        },
        {
            key: 'action',
            render: (_, row, index) => {
                const limitType = row.type;
                const showRemoveButton = index > 0 && !row.isFromApi;

                return showRemoveButton ? (
                    <MinusCircleOutlined
                        width={32}
                        height={32}
                        title="Remove"
                        color={theme.colors.red}
                        onClick={() => removeLimitType(limitType)}
                    />
                ) : null;
            }
        }
    ];

    return columns;
};

export const getTableData = (limits: Limit[]): LimitTableDataType[] => {
    const data = limits.map((limit, index) => {
        return {
            key: index,
            type: limit.limit_type,
            validity: getHumanTimeLabel(limit.validity_period),
            capacity: limit.capacity,
            unlimited: limit.options.some(
                (option) => option === UnlimitedOption
            ),
            status: limit.status,
            isFromApi: true
        };
    });

    return data;
};
