import { useIntl } from 'react-intl';
import { Button, Tag } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Box } from '../../../components/Box';
import { Messages } from '../../../intl';
import { MarketId } from '../../../routing';
import { MarketSelector } from '../MarketSelector';
import { UserBox } from '../UserBox';
import { HeaderLabel, HeaderRow, HeaderWrapper } from './Header.styled';

interface Props {
    userName?: string;
    userEmail?: string;
    userLoading: boolean;
    currentEnvironment: string;
    selectedMarket?: MarketId;
    markets: MarketId[];
    sidebarCollapsed: boolean;
    onSidebarCollapse?: VoidFunction;
    onLogout?: () => void;
}

export const Header = ({
    currentEnvironment,
    selectedMarket,
    markets,
    sidebarCollapsed,
    onSidebarCollapse,
    ...rest
}: Props) => {
    const { formatMessage } = useIntl();

    return (
        <HeaderWrapper collapsed={sidebarCollapsed} data-testid="app-header">
            <HeaderRow>
                <Button
                    type="text"
                    onClick={() => onSidebarCollapse?.()}
                    icon={
                        sidebarCollapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined />
                        )
                    }
                />
            </HeaderRow>
            <HeaderRow>
                <HeaderLabel type="secondary" fontSize={13}>
                    {formatMessage(Messages['common.label.environment'])}:
                </HeaderLabel>
                <Box ml={8} mr={16}>
                    <Tag color="cyan">{currentEnvironment}</Tag>
                </Box>
                <HeaderLabel type="secondary" fontSize={13}>
                    {formatMessage(Messages['common.label.market'])}:
                </HeaderLabel>
                <MarketSelector
                    selectedMarket={selectedMarket}
                    markets={markets}
                    ml={8}
                    mr={32}
                />
                <UserBox {...rest} />
            </HeaderRow>
        </HeaderWrapper>
    );
};
