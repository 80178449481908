import { DefaultJsonContentType } from './constans';
import { fetchData, RequestContext } from './fetchData';
import {
    Product,
    ProductDetails,
    Feature,
    ProductType,
    Category,
    MarketLanguage,
    OfferChange,
    OfferChangeDetails,
    OfferChangeProduct,
    OfferChangeProductDetails,
    CategorySet,
    Template,
    TemplateListItem
} from './models';
import {
    GetProductInput,
    GetProductsMetadata,
    GetProductsInput,
    GetFeaturesInput,
    ResultEnvelope,
    GetFeaturesMetadata,
    GetOfferChangeInput,
    GetOfferChangeProductsInput,
    GetOfferChangeProductInput,
    GetOfferChangesInput,
    GetOfferChangesMetadata,
    GetOfferChangeProductsMetadata,
    CreateOfferChangeInput,
    UpdateOfferChangeInput,
    CancelOfferChangeInput,
    PublishOfferChangeInput,
    UpdateOfferChangeOutput,
    CreateOfferChangeOutput,
    RemoveOfferChangeProductsInput,
    RemoveOfferChangeProductsOutput,
    UpdateOfferChangeProductInput,
    UpdateOfferChangeProductOutput,
    CreateOfferChangeProductInput,
    CreateOfferChangeProductOutput,
    GetCategorySetsInput,
    GetCategorySetsMetadata,
    GetCategorySetInput,
    CreateCategorySetInput,
    UpdateCategorySetInput,
    UpdateCategorySetOutput,
    CreateCategorySetOutput,
    RemoveCategorySetsInput,
    GetOfferChangeProductMetadata,
    GetProductAtVersionInput,
    MarketLanguagesInput,
    CreateTemplateInput,
    CreateTemplateOutput,
    UpdateTemplateInput,
    UpdateTemplateOutput,
    GetTemplateInput,
    GetTemplatesInput,
    GetTemplatesMetadata,
    RemoveTemplateInput,
    RemoveTemplateOutput,
    UpdateTemplateTranslationInput,
    UpdateTemplateTranslationOutput
} from './types';

export const getProducts = (
    input: GetProductsInput,
    context: RequestContext
): Promise<ResultEnvelope<Product[], GetProductsMetadata>> => {
    return fetchData({
        path: '/products',
        query: { ...input },
        context
    });
};

export const getProduct = (
    input: GetProductInput,
    context: RequestContext
): Promise<ResultEnvelope<ProductDetails>> => {
    return fetchData({
        path: `/products/${input.id}`,
        context
    });
};

export const getProductAtVersion = (
    input: GetProductAtVersionInput,
    context: RequestContext
): Promise<ResultEnvelope<ProductDetails>> => {
    return fetchData({
        path: `/products/${input.id}/versions/${input.version}`,
        context
    });
};

export const getOfferChanges = (
    input: GetOfferChangesInput,
    context: RequestContext
): Promise<ResultEnvelope<OfferChange[], GetOfferChangesMetadata>> => {
    return fetchData({
        path: '/offerchanges',
        query: { ...input },
        context
    });
};

export const getOfferChange = (
    input: GetOfferChangeInput,
    context: RequestContext
): Promise<ResultEnvelope<OfferChangeDetails>> => {
    return fetchData({
        path: `/offerchanges/${input.id}`,
        context
    });
};

export const createOfferChange = (
    input: CreateOfferChangeInput,
    context: RequestContext
): Promise<ResultEnvelope<CreateOfferChangeOutput>> => {
    return fetchData({
        path: '/offerchanges',
        baseInit: {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const updateOfferChange = (
    input: UpdateOfferChangeInput,
    context: RequestContext
): Promise<ResultEnvelope<UpdateOfferChangeOutput>> => {
    const { id, name, ...query } = input;
    return fetchData({
        path: `/offerchanges/${id}`,
        query: { ...query },
        baseInit: {
            method: 'PATCH',
            body: JSON.stringify({
                name
            }),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const publishOfferChange = (
    input: PublishOfferChangeInput,
    context: RequestContext
): Promise<void> => {
    const { id, ...query } = input;
    return fetchData({
        path: `/offerchanges/${id}/publish`,
        query: { ...query },
        baseInit: {
            method: 'POST',
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const cancelOfferChange = (
    input: CancelOfferChangeInput,
    context: RequestContext
): Promise<void> => {
    const { id, ...query } = input;
    return fetchData({
        path: `/offerchanges/${id}/cancel`,
        query: { ...query },
        baseInit: {
            method: 'POST',
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const getOfferChangeProducts = (
    input: GetOfferChangeProductsInput,
    context: RequestContext
): Promise<
    ResultEnvelope<OfferChangeProduct[], GetOfferChangeProductsMetadata>
> => {
    const { id, ...query } = input;
    return fetchData({
        path: `/offerchanges/${id}/products`,
        query: { ...query },
        context
    });
};

export const getOfferChangeProduct = (
    input: GetOfferChangeProductInput,
    context: RequestContext
): Promise<
    ResultEnvelope<OfferChangeProductDetails, GetOfferChangeProductMetadata>
> => {
    return fetchData({
        path: `/offerchanges/${input.id}/products/${input.productId}`,
        context
    });
};

export const createOfferChangeProduct = (
    input: CreateOfferChangeProductInput,
    context: RequestContext
): Promise<ResultEnvelope<CreateOfferChangeProductOutput>> => {
    const { id, expectedVersion, ...body } = input;
    return fetchData({
        path: `/offerchanges/${id}/products`,
        query: { expectedVersion },
        baseInit: {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const updateOfferChangeProduct = (
    input: UpdateOfferChangeProductInput,
    context: RequestContext
): Promise<ResultEnvelope<UpdateOfferChangeProductOutput>> => {
    const { id, productId, expectedVersion, ...body } = input;
    return fetchData({
        path: `/offerchanges/${id}/products/${productId}`,
        query: { expectedVersion },
        baseInit: {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const removeOfferChangeProducts = (
    input: RemoveOfferChangeProductsInput,
    context: RequestContext
): Promise<ResultEnvelope<RemoveOfferChangeProductsOutput>> => {
    const { id, ids, ...query } = input;
    return fetchData({
        path: `/offerchanges/${id}/products`,
        query: { ...query },
        baseInit: {
            method: 'DELETE',
            body: JSON.stringify({ ids }),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const getFeatures = (
    input: GetFeaturesInput,
    context: RequestContext
): Promise<ResultEnvelope<Feature[], GetFeaturesMetadata>> => {
    return fetchData({
        path: '/featuredefinitions',
        query: { ...input },
        context
    });
};

export const getProductTypes = (
    context: RequestContext
): Promise<ResultEnvelope<ProductType[]>> => {
    return fetchData({
        path: '/metadata/producttypes',
        context
    });
};

export const getCategories = (
    context: RequestContext
): Promise<ResultEnvelope<Category[]>> => {
    return fetchData({
        path: '/categories',
        context
    });
};

export const getCategorySets = (
    input: GetCategorySetsInput,
    context: RequestContext
): Promise<ResultEnvelope<CategorySet[], GetCategorySetsMetadata>> => {
    return fetchData({
        path: '/categorysets',
        query: { ...input },
        context
    });
};

export const getCategorySet = (
    input: GetCategorySetInput,
    context: RequestContext
): Promise<ResultEnvelope<CategorySet>> => {
    return fetchData({
        path: `/categorysets/${input.id}`,
        context
    });
};

export const createCategorySet = (
    input: CreateCategorySetInput,
    context: RequestContext
): Promise<ResultEnvelope<CreateCategorySetOutput>> => {
    return fetchData({
        path: '/categorysets',
        baseInit: {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const updateCategorySet = (
    input: UpdateCategorySetInput,
    context: RequestContext
): Promise<ResultEnvelope<UpdateCategorySetOutput>> => {
    const { id, ...body } = input;
    return fetchData({
        path: `/categorysets/${id}`,
        baseInit: {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const removeCategorySets = (
    input: RemoveCategorySetsInput,
    context: RequestContext
): Promise<void> => {
    return fetchData({
        path: '/categorysets',
        baseInit: {
            method: 'DELETE',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const getTemplate = (
    input: GetTemplateInput,
    context: RequestContext
): Promise<ResultEnvelope<Template>> => {
    return fetchData({
        path: `/templates/${input.id}`,
        context
    });
};

export const getTemplates = (
    input: GetTemplatesInput,
    context: RequestContext
): Promise<ResultEnvelope<TemplateListItem[], GetTemplatesMetadata>> => {
    return fetchData({
        path: '/templates',
        query: { ...input },
        context
    });
};

export const createTemplate = (
    input: CreateTemplateInput,
    context: RequestContext
): Promise<ResultEnvelope<CreateTemplateOutput>> => {
    return fetchData({
        path: '/templates',
        baseInit: {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const updateTemplate = (
    input: UpdateTemplateInput,
    context: RequestContext
): Promise<ResultEnvelope<UpdateTemplateOutput>> => {
    const { id, ...body } = input;
    return fetchData({
        path: `/templates/${id}`,
        baseInit: {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const updateTemplateTranslation = (
    input: UpdateTemplateTranslationInput,
    context: RequestContext
): Promise<ResultEnvelope<UpdateTemplateTranslationOutput>> => {
    const { id, ...body } = input;
    return fetchData({
        path: `/templates/${id}/translations`,
        baseInit: {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const removeTemplate = (
    input: RemoveTemplateInput,
    context: RequestContext
): Promise<RemoveTemplateOutput> => {
    return fetchData({
        path: `/templates/${input.id}`,
        baseInit: {
            method: 'DELETE',
            headers: {
                'Content-Type': DefaultJsonContentType
            }
        },
        context
    });
};

export const getAvailableLanguages = (
    context: RequestContext
): Promise<ResultEnvelope<MarketLanguage[]>> => {
    return fetchData({
        path: '/metadata/languages',
        context
    });
};

export const getCurrentMarketLanguages = (
    input: MarketLanguagesInput,
    context: RequestContext
): Promise<ResultEnvelope<MarketLanguage[]>> => {
    return fetchData({
        path: '/metadata/languages',
        query: { ...input },
        context
    });
};
