import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled, { useTheme } from 'styled-components';
import {
    color,
    fontSize,
    FontSizeProps,
    space,
    SpaceProps,
    TextColorProps
} from 'styled-system';
import { Box } from '../../components/Box';
import { Loader } from '../../components/Loader';
import { Messages } from '../../intl';

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const User = styled.div<SpaceProps>`
    display: flex;
    flex-direction: column;
    ${space}
`;

const UserName = styled.strong<TextColorProps & FontSizeProps>`
    display: block;
    ${color}
    ${fontSize}
`;

const UserEmail = styled.span<TextColorProps & FontSizeProps>`
    ${color}
    ${fontSize}
`;

const UserImageWrapper = styled.div`
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => `
        width: ${theme.sizes.userIcon};
        height: ${theme.sizes.userIcon};
        background: ${theme.colors.darkBluish};
    `};
`;

interface Props extends SpaceProps {
    userName?: string;
    userEmail?: string;
    userLoading: boolean;
    onLogout?: VoidFunction;
}

export const UserBox = ({
    userName,
    userEmail,
    userLoading,
    onLogout,
    ...rest
}: Props) => {
    const [userDropdownVisible, setUserDropdownVisible] = useState(false);
    const { formatMessage } = useIntl();
    const theme = useTheme();

    return (
        <Box {...rest} data-testid="app-user-box">
            <Popover
                content={
                    <Button type="link" onClick={() => onLogout?.()}>
                        {formatMessage(Messages['common.button.logout'])}
                    </Button>
                }
                trigger="click"
                open={userDropdownVisible}
                onOpenChange={() =>
                    setUserDropdownVisible(!userDropdownVisible)
                }
                placement="bottomRight"
            >
                <UserWrapper>
                    {userLoading ? (
                        <Loader mr={16} />
                    ) : (
                        <User mr={16}>
                            {userName && (
                                <UserName
                                    color={theme.colors.darkBlue}
                                    fontSize={12}
                                >
                                    {userName}
                                </UserName>
                            )}
                            {userEmail && (
                                <UserEmail
                                    color={theme.colors.lightBlue}
                                    fontSize={12}
                                >
                                    {userEmail}
                                </UserEmail>
                            )}
                        </User>
                    )}
                    <UserImageWrapper>
                        <UserOutlined />
                    </UserImageWrapper>
                </UserWrapper>
            </Popover>
        </Box>
    );
};
