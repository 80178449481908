import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType, Button } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    FeatureId,
    FeatureTarget
} from '@olxeu-monetization/product-catalog-api-client';
import { renderSearchIcon, SearchFilter } from '../../components/Table';
import { Messages } from '../../intl';
import { normalizeText } from '../../utils';

export type TableDataSource = {
    key: string;
    name: FeatureId;
    target: FeatureTarget;
    selected: boolean;
};

export type TableFilters = {
    name: string[] | null;
    target: FeatureTarget[] | null;
    actions: null;
};

interface Options {
    targetOptions: ColumnFilterItem[];
    intl: IntlShape;
    onSelect?: (id: FeatureId) => void;
}

export const buildTableColumns = ({
    targetOptions,
    intl,
    onSelect
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            onFilter: (value, record) =>
                normalizeText(record.name).includes(
                    normalizeText(String(value))
                )
        },
        {
            title: <FormattedMessage {...Messages['common.label.target']} />,
            key: 'target',
            dataIndex: 'target',
            filters: targetOptions,
            filterMultiple: true,
            onFilter: (value, record) => record.target === value
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'name',
            align: 'right',
            render: (name: FeatureId, record) => (
                <Button
                    size="small"
                    type="primary"
                    disabled={record.selected}
                    onClick={() => onSelect?.(name)}
                    data-testid={`features-modal-select-button[${name}]`}
                >
                    <FormattedMessage {...Messages['common.button.select']} />
                </Button>
            )
        }
    ];
};
