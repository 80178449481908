import { Spin } from 'antd';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const centerMixin = css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type LoaderType = 'center';

interface LoaderWrapperProps extends SpaceProps {
    type?: LoaderType;
}

const LoaderWrapper = styled.div<LoaderWrapperProps>`
    ${({ type }) => type === 'center' && centerMixin}
    ${space}
`;

const StyledSpinner = styled(Spin)`
    display: flex;
`;

interface Props extends SpaceProps {
    type?: LoaderType;
}

export const Loader = (props: Props) => (
    <LoaderWrapper {...props} data-testid="loader">
        <StyledSpinner />
    </LoaderWrapper>
);
