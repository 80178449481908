import { useContext, useMemo, useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppLayoutContext, ConfigContext } from '../../context';
import { AuthContext, UserDataContext } from '../../context';
import { getOktaMarketIds } from '../../providers';
import { MarketId } from '../../routing';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

const LayoutWrapper = styled.section`
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
`;

const Main = styled.section`
    padding-top: ${({ theme }) => theme.sizes.headerHeight};
    background: ${({ theme }) => theme.colors.lightBluish};
    flex-grow: 1;
`;

const Content = styled.div`
    height: 100%;
    padding: ${({ theme }) => `${theme.space[40]} ${theme.space[24]}`};
    overflow: auto;
`;

type Params = {
    marketId?: MarketId;
};

export const AppLayout = () => {
    const containerRef = useRef<HTMLElement>(null);

    // TODO: extract below context/state to a container `AppLayoutContainer`
    const userData = useContext(UserDataContext);
    const configContext = useContext(ConfigContext);
    const authContext = useContext(AuthContext);
    const params = useParams<Params>();

    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const markets = useMemo(() => {
        return getOktaMarketIds(
            configContext.services.oktaAuthService.marketConfigs
        );
    }, [configContext.services.oktaAuthService.marketConfigs]);

    return (
        <LayoutWrapper data-testid="app-layout">
            <Header
                userName={userData.data?.name}
                userEmail={userData.data?.email}
                userLoading={userData.loading}
                currentEnvironment={configContext.application.environment}
                selectedMarket={params.marketId}
                markets={markets}
                sidebarCollapsed={sidebarCollapsed}
                onSidebarCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
                onLogout={authContext.logout}
            />
            {params.marketId !== undefined && (
                <Sidebar
                    collapsed={sidebarCollapsed}
                    marketId={params.marketId}
                />
            )}
            <Main ref={containerRef}>
                <AppLayoutContext.Provider value={{ containerRef }}>
                    <Content data-testid="app-content">
                        <Outlet />
                    </Content>
                </AppLayoutContext.Provider>
            </Main>
        </LayoutWrapper>
    );
};
