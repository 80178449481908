import { FormattedMessage } from 'react-intl';
import { List, Popover } from 'antd';
import { Product } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { ShortList } from '../../ShortList';
import { FeaturesBox, ListIcon } from './ProductFeaturesCell.styled';

type ProductFeature = Product['features'][number];

interface Props {
    features: ProductFeature[];
}

export const ProductFeaturesCell = ({ features }: Props) =>
    features.length > 0 ? (
        <Popover
            title={<FormattedMessage {...Messages['common.label.features']} />}
            trigger="click"
            placement="top"
            content={
                <List
                    itemLayout="horizontal"
                    dataSource={features}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.id}
                                description={item.target}
                            />
                        </List.Item>
                    )}
                />
            }
        >
            <FeaturesBox>
                <ListIcon />
                <ShortList ml={8} items={features} property="id" />
            </FeaturesBox>
        </Popover>
    ) : null;
