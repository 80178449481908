import { Overlay } from './ErrorOverlay.styled';

interface Props {
    error: Error;
}

export const ErrorOverlay = ({ error }: Props) => (
    <Overlay
        data-testid="error-overlay"
        status="error"
        title={<span data-testid="error-overlay-title">{error.name}</span>}
        subTitle={
            <span data-testid="error-overlay-subtitle">{error.message}</span>
        }
    />
);
