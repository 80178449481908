import { traverseTree, TreeTraversalMethod } from './treeUtils';

type TreeMapValue<T, P extends keyof T> = Omit<T, 'children'> & {
    parentKey?: string;
    childrenKeys: T[P][];
} & Record<P, string>;

export const buildTreeMap = <T, P extends keyof T>(roots: T[], keyName: P) => {
    const map = new Map<string | undefined, TreeMapValue<T, P>>();

    traverseTree(roots, TreeTraversalMethod.DFSPostOrder, (node, parent) => {
        const { children, ...childlessNode } = node;

        map.set(String(node[keyName]), {
            ...childlessNode,
            ...({
                [keyName]: String(node[keyName])
            } as Record<P, string>),
            parentKey: String(parent?.[keyName]),
            childrenKeys: children?.map((child) => child[keyName]) ?? []
        });
    });

    return map;
};

export const buildSelectionMap = <T>(selectedList: T[]) => {
    return selectedList.reduce((acc, key) => {
        acc.set(String(key), true);
        return acc;
    }, new Map<string, boolean>());
};

export const buildSelectionList = <T>(selectionMap: Map<T, boolean>) => {
    const entries = Array.from(selectionMap);
    const selected = entries.filter(([key, value]) => value);

    return selected.map(([key]) => key);
};

type RequiredTree<T> = T & {
    children: RequiredTree<T>[];
};

export const buildTree = <
    T extends Record<string, unknown>,
    P extends keyof T,
    R extends keyof T
>(
    nodes: T[],
    keyName: P,
    parentKeyName: R,
    rootKeyValue: T[R]
) => {
    const clonedNodes = nodes.map((node) => {
        return { ...node, children: [] } as RequiredTree<T>;
    });

    const map = clonedNodes.reduce((acc, node) => {
        acc.set(String(node[keyName]), node);
        return acc;
    }, new Map<string, RequiredTree<T>>());

    clonedNodes.forEach((node) => {
        map.get(String(node[parentKeyName]))?.children.push(node);
    });

    return clonedNodes.filter((node) => node[parentKeyName] === rootKeyValue);
};

export const omitNodesWithSelectedAncestors = <
    T extends { children?: T[] },
    P extends keyof T,
    K = T[P]
>(
    roots: T[],
    selectedKeys: K[],
    keyName: P
) => {
    const treeMap = buildTreeMap(roots, keyName);
    const selectionMap = buildSelectionMap(selectedKeys);

    return selectedKeys.filter((key) => {
        for (
            let iterator = treeMap.get(treeMap.get(String(key))?.parentKey);
            iterator !== undefined;
            iterator = treeMap.get(iterator.parentKey)
        ) {
            if (selectionMap.get(String(iterator[keyName]))) {
                return false;
            }
        }

        return true;
    });
};

export const omitNodesWithSelectedDescendants = <
    T extends { children?: T[] },
    P extends keyof T,
    K = T[P]
>(
    roots: T[],
    selectedKeys: K[],
    keyName: P
) => {
    const treeMap = buildTreeMap(roots, keyName);
    const selectionMap = buildSelectionMap(selectedKeys);

    traverseTree(roots, TreeTraversalMethod.DFSPreOrder, (node, parent) => {
        if (parent && selectionMap.get(String(parent[keyName]))) {
            selectionMap.set(String(node[keyName]), true);
        }
    });

    const normalizedSelectionKeys = buildSelectionList(selectionMap);

    return normalizedSelectionKeys.filter((key) => {
        const node = treeMap.get(String(key));
        return !node || node.childrenKeys.length === 0;
    });
};
