import { FormattedMessage } from 'react-intl';
import { List, Popover, Typography } from 'antd';
import { Category } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { ErrorTooltip } from '../../ErrorTooltip';
import { ShortList } from '../../ShortList';
import { CategoriesBox, ListIcon } from './CategoriesCell.styled';

const { Text } = Typography;

interface Props {
    categories: Category[];
}

export const CategoriesCell = ({ categories, ...rest }: Props) => {
    const mappedCategories = categories.map((category) => {
        return {
            ...category,
            name:
                category.parentId > -1 ? (
                    category.name
                ) : (
                    <ErrorTooltip
                        title={
                            <FormattedMessage
                                {...Messages[
                                    'common.label.category-no-longer-exists'
                                ]}
                                values={{ id: category.id }}
                            />
                        }
                    >
                        <Text disabled>{category.id}</Text>
                    </ErrorTooltip>
                )
        };
    });

    return categories.length > 0 ? (
        <Popover
            title={
                <FormattedMessage {...Messages['common.label.categories']} />
            }
            trigger="click"
            placement="top"
            content={
                <List
                    itemLayout="horizontal"
                    dataSource={categories}
                    renderItem={(item) => <List.Item>{item.name}</List.Item>}
                />
            }
        >
            <CategoriesBox>
                <ListIcon />
                <ShortList
                    ml={8}
                    items={mappedCategories}
                    property="name"
                    {...rest}
                />
            </CategoriesBox>
        </Popover>
    ) : null;
};
