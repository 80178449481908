import { FormattedMessage } from 'react-intl';
import { AugmentedColumnsType, Tag } from 'antd';
import {
    TranslationValue,
    TranslationValueType
} from '@olxeu-monetization/product-catalog-api-client';
import {
    FormFieldType,
    FormInput
} from '../../../../components/FormInput/FormInput';
import { Messages } from '../../../../intl';

export type TableDataSource = {
    key: string;
    type: TranslationValueType;
    name: number;
    value: TranslationValue;
    typeDisabled: boolean;
    inputDisabled: boolean;
};

export type TableFilters = {
    key: string[] | null;
    type: TranslationValueType[] | null;
    value: TranslationValue[] | null;
};

export const buildTableColumns = (): AugmentedColumnsType<
    TableDataSource,
    TableFilters
> => {
    const typeOptions = [
        {
            label: 'Reference',
            value: TranslationValueType.Reference
        },
        {
            label: 'Static',
            value: TranslationValueType.Static
        }
    ];

    return [
        {
            title: <FormattedMessage {...Messages['common.label.key']} />,
            key: 'key',
            dataIndex: 'key',
            render: (value) => {
                return <Tag color="blue">{value}</Tag>;
            }
        },
        {
            title: <FormattedMessage {...Messages['common.label.type']} />,
            key: 'type',
            dataIndex: 'type',
            render: (value, record) => {
                return (
                    <FormInput
                        name={[record.name, 'type']}
                        label={record.key}
                        required={false}
                        type={FormFieldType.Select}
                        value={value}
                        options={typeOptions}
                        disabled={record.typeDisabled}
                        noStyle={true}
                    />
                );
            }
        },
        {
            title: <FormattedMessage {...Messages['common.label.value']} />,
            key: 'value',
            dataIndex: 'value',
            render: (value, record) => {
                return (
                    <FormInput
                        name={[record.name, 'value']}
                        label={<em />}
                        required={true}
                        type={FormFieldType.Input}
                        disabled={record.inputDisabled}
                        className="form-input-empty-label-value"
                    />
                );
            }
        }
    ];
};
