const formats = {
    date: {
        long: {
            dateStyle: 'long',
            timeStyle: 'long'
        },
        medium: {
            dateStyle: 'medium'
        }
    }
} as const;

export default formats;
