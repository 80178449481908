import { IntlShape } from 'react-intl';
import {
    MarketLanguage,
    ProductLocalization
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';

export const buildDataSource = (
    localizations: ProductLocalization[] | undefined,
    previousLocalizations: ProductLocalization[] | undefined,
    intl: IntlShape,
    marketLanguages: MarketLanguage[]
) => {
    const localizationsMap = new Map<string, ProductLocalization>();

    localizations?.forEach((localization) => {
        localizationsMap.set(localization.languageCode, localization);
    });

    const previousLocalizationsMap = new Map<string, ProductLocalization>();

    previousLocalizations?.forEach((localization) => {
        previousLocalizationsMap.set(localization.languageCode, localization);
    });

    const dataSource = marketLanguages.map((language, index) => {
        const localization = localizationsMap.get(language.code);
        const previousLocalization = previousLocalizationsMap.get(
            language.code
        );

        const productNameChanged =
            previousLocalization !== undefined &&
            localization?.productName !== previousLocalization.productName;
        const productDescriptionChanged =
            previousLocalization !== undefined &&
            localization?.productDescription !==
                previousLocalization.productDescription;

        const entries = [
            {
                id: `${language.code}-product-name`,
                label: intl.formatMessage(
                    Messages['common.label.product-name']
                ),
                value: localization?.productName,
                previousValue: productNameChanged
                    ? previousLocalization?.productName
                    : undefined
            },
            {
                id: `${language.code}-product-description`,
                label: intl.formatMessage(
                    Messages['common.label.product-description']
                ),
                value: localization?.productDescription,
                previousValue: productDescriptionChanged
                    ? previousLocalization?.productDescription
                    : undefined
            }
        ];

        const changesCount =
            Number(productNameChanged) + Number(productDescriptionChanged);

        return {
            languageCode: language.code,
            entries,
            changesCount
        };
    });

    const changesCount = dataSource.reduce((acc, root) => {
        return acc + root.changesCount;
    }, 0);

    return {
        dataSource,
        changesCount
    };
};
