import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { TranslationOutlined } from '@ant-design/icons';
import {
    FeatureId,
    getTemplate,
    MarketLanguage,
    ProductFeature,
    ProductLabel,
    ProductLabelValue,
    ResultEnvelope,
    Template,
    TemplateId,
    TranslationValueType
} from '@olxeu-monetization/product-catalog-api-client';
import { Box } from '../../../components/Box';
import { Loader } from '../../../components/Loader';
import { AuthContext, ConfigContext } from '../../../context';
import { Messages } from '../../../intl';
import { CompletionParams, usePromise } from '../../../utils';
import { FormList, FormListField } from '../FormList';
import { LabelAttributesTable } from './LabelAttributesTable';
import { LabelValuesTable } from './LabelValuesTable';
import { ReferencedFeaturePicker } from './ReferencedFeaturePicker';
import { TranslationCard } from './TranslationCard';
import { getLabelValuesFromTranslation, getNormalizedValue } from './utils';

interface Props {
    templateId: TemplateId;
    labelField: FormListField;
    labelListName: NamePath;
    marketLanguages: MarketLanguage[];
    onRemove?: (templateId: TemplateId) => void;
    onAdd?: (template: Template) => void;
}

export const LabelTabContent = ({
    templateId,
    labelField,
    labelListName,
    marketLanguages,
    onRemove,
    onAdd
}: Props) => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const { formatMessage } = useIntl();

    const form = Form.useFormInstance();

    const productFeatures = Form.useWatch('features') as ProductFeature[];
    const productLabels = Form.useWatch('labels') as ProductLabel[];

    const labelValues = form.getFieldValue([
        'labels',
        labelField.name,
        'values'
    ]) as ProductLabelValue[];

    const referencedFeature = form.getFieldValue([
        'labels',
        labelField.name,
        'featureId'
    ]) as FeatureId;

    const templateResult = usePromise({
        variables: {
            id: templateId
        },
        promiseBuilder: async (variables) => {
            return getTemplate(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        onComplete: (
            completionParams: CompletionParams<
                unknown,
                ResultEnvelope<Template>
            >
        ) => {
            const template = completionParams.data?.data;

            if (template) {
                onAdd?.(template);
            }
        }
    });

    if (templateResult.loading) return <Loader type="center" />;

    const template = templateResult.data?.data;

    if (!template) return null;

    const defaultTranslation =
        template.translations.find(
            (translation) => translation.language === 'en'
        )?.value ?? '';

    const valuesFromTemplate =
        getLabelValuesFromTranslation(defaultTranslation);

    if (productLabels && productFeatures) {
        const referencedFeatureValues = productFeatures.find(
            (feature) => feature.id === referencedFeature
        )?.properties;

        const enhancedValues = valuesFromTemplate.reduce<ProductLabelValue[]>(
            (acc, value) => {
                const labelValue = labelValues.find(
                    (labelValue) => labelValue.name === value
                );
                if (labelValue) {
                    acc.push(labelValue);
                } else {
                    const referencedValue =
                        referencedFeatureValues?.find((v) => v.name === value)
                            ?.value ?? '';

                    acc.push({
                        name: value,
                        value: getNormalizedValue(referencedValue),
                        type: TranslationValueType.Reference
                    });
                }
                return acc;
            },
            []
        );

        productLabels[labelField.name].values = enhancedValues;
    }

    const labelTranslations = template.translations;

    return (
        <>
            <Box maxWidth={600} mb={24}>
                <TranslationCard
                    title={formatMessage(
                        Messages['common.label.translation-pattern']
                    )}
                    icon={<TranslationOutlined style={{ marginRight: 8 }} />}
                    translations={labelTranslations}
                    marketLanguages={marketLanguages}
                />
            </Box>
            <Box width={200}>
                <ReferencedFeaturePicker
                    labelField={labelField}
                    productFeatures={productFeatures}
                />
            </Box>
            <Box maxWidth={600} mb={24}>
                <FormList
                    name={[labelField.name, 'values']}
                    parentName={labelListName}
                >
                    {(valuesFields) => {
                        return (
                            <LabelValuesTable
                                productLabels={productLabels}
                                productFeatures={productFeatures}
                                labelField={labelField}
                                valuesFields={valuesFields}
                                translations={labelTranslations}
                                marketLanguages={marketLanguages}
                            />
                        );
                    }}
                </FormList>
            </Box>
            <Box maxWidth={600} mb={24}>
                <FormList
                    name={[labelField.name, 'metadata']}
                    parentName={labelListName}
                >
                    {(metadataFields) => {
                        return (
                            <LabelAttributesTable
                                metadataFields={metadataFields}
                            />
                        );
                    }}
                </FormList>
            </Box>
            <Form.Item noStyle={true}>
                <Button
                    type="primary"
                    danger={true}
                    onClick={() => onRemove?.(templateId)}
                >
                    {formatMessage(Messages['common.button.remove'])}
                </Button>
            </Form.Item>
        </>
    );
};
