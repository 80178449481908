export enum MarketId {
    OLXPoland = 'olxpl',
    OLXUkraine = 'olxua',
    OLXKazakhstan = 'olxkz',
    OLXUzbekistan = 'olxuz',
    OLXPortugal = 'olxpt',
    OLXBulgaria = 'olxbg',
    OLXRomania = 'olxro'
}

export enum ProductStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export enum ProductChangeType {
    NewProduct = 'NEW_PRODUCT',
    Updated = 'UPDATE'
}

export enum FeaturePropertyType {
    Number = 'INTEGER',
    TimeInterval = 'DURATION'
}

export type FeaturePropertyValue = number | string;

export enum FeatureTarget {
    Ad = 'AD',
    Account = 'ACCOUNT'
}

export enum CategorySetType {
    Limit = 'LIMIT',
    Package = 'PACKAGE',
    Megapackage = 'MEGAPACKAGE'
}

export enum TranslationValueType {
    Static = 'STATIC',
    Reference = 'REFERENCE'
}

export enum TemplateStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export type ProductId = string;

export type ProductExternalId = number;

export type ProductVersion = number;

export type ProductTypeName = string;

export type OfferChangeId = string;

export type FeatureId = string;

export type FeaturePropertyName = string;

export type TemplateId = string;

export type TemplateName = string;

export type TranslationValue = string;

export type CategoryId = number; // NOTE: 0 = root category

export type CategorySetId = string;

export type UserId = string;

export type InvoicingId = string;

export type LanguageCode = string;

export interface Product {
    id: ProductId;
    lockedBy?: OfferChangeId;
    name: string;
    type: ProductTypeName;
    status: ProductStatus;
    validity?: string; // NOTE: iso-8601
    slots: number;
    target: {
        categorySetIds: CategorySetId[];
        userIds: UserId[];
    };
    features: {
        id: FeatureId;
        target: FeatureTarget;
    }[];
}

export interface ProductDetails extends Product {
    version: ProductVersion;
    invoicingId?: InvoicingId;
    externalId?: ProductExternalId;
    features: ProductFeature[];
    localizations: ProductLocalization[];
    labels: ProductLabel[];
}

export interface FeatureNumberPropertyValidation {
    type: FeaturePropertyType.Number;
    isRequired: boolean;
    min: number;
    max: number;
}

export interface FeatureTimeIntervalPropertyValidation {
    type: FeaturePropertyType.TimeInterval;
    isRequired: boolean;
    min: string;
    max: string;
}

export type FeaturePropertyValidation =
    | FeatureNumberPropertyValidation
    | FeatureTimeIntervalPropertyValidation;

export interface FeatureProperty {
    name: FeaturePropertyName;
    validation: FeaturePropertyValidation;
}

export interface Feature {
    id: FeatureId;
    target: FeatureTarget;
    properties: FeatureProperty[];
}

export interface TemplateTranslation {
    language: LanguageCode;
    value: string;
}

export interface Template {
    id: TemplateId;
    name: string;
    status: TemplateStatus;
    description?: string;
    translations: TemplateTranslation[];
}

export interface TemplateListItem {
    id: TemplateId;
    name: TemplateName;
    description?: string;
    defaultTranslation: TemplateTranslation;
}

export interface ProductType {
    name: ProductTypeName;
    allowedCategorySetTypes: CategorySetType[];
    parentName?: ProductTypeName;
}

export interface Category {
    id: CategoryId;
    name: string;
    parentId: CategoryId;
}

export interface CategorySetLocalization {
    languageCode: LanguageCode;
    name?: string;
}

export interface CategorySet {
    id: CategorySetId;
    name: string;
    categoryIds: CategoryId[];
    type: CategorySetType;
    localizations?: CategorySetLocalization[]; // TODO: Remove optional when EUPP-2493 is done
}

export interface MarketLanguage {
    code: LanguageCode;
}

export interface ProductLocalization {
    languageCode: LanguageCode;
    productName: string;
    productDescription: string;
}

export enum OfferChangeStatus {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Cancelled = 'CANCELLED'
}
export interface OfferChange {
    id: OfferChangeId;
    name: string;
    status: OfferChangeStatus;
    lastAuthor: string;
    updatedAt: string;
}

export interface OfferChangeDetails extends OfferChange {
    version: number;
}

export interface OfferChangeProduct extends Product {
    changeType: ProductChangeType;
}

export interface OfferChangeProductDetails extends Product {
    changeType: ProductChangeType;
    invoicingId?: InvoicingId;
    externalId?: ProductExternalId;
    features: ProductFeature[];
    localizations: ProductLocalization[];
    labels: ProductLabel[];
}

export interface ProductFeatureProperty {
    name: string;
    value: FeaturePropertyValue;
}

export interface ProductFeature {
    id: FeatureId;
    target: FeatureTarget;
    properties: ProductFeatureProperty[];
}

export type LabelValue = string | number;

export interface ProductLabelValue {
    name: string;
    value: LabelValue;
    type?: TranslationValueType;
}

export type LabelMetadataValue = string | number | boolean;

export interface ProductLabelMetadata {
    name: string;
    value: LabelMetadataValue;
}

export interface ProductLabel {
    templateId: TemplateId;
    values: ProductLabelValue[];
    metadata: ProductLabelMetadata[];
    featureId?: FeatureId;
}
