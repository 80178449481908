export type Tree<T> = T & {
    children?: Tree<T>[];
};

interface Signal {
    triggered: boolean;
}

export enum TreeTraversalMethod {
    BFS = 'bfs',
    DFSPreOrder = 'dfs-pre-order',
    DFSPostOrder = 'dfs-post-order'
}

const traverseTreeBreadthFirst = <T>(
    root: Tree<T>,
    action: (node: Tree<T>, parent: Tree<T> | undefined, stop: Signal) => void,
    parent?: Tree<T>
) => {
    const stopSignal = {
        triggered: false
    };

    const parentMap = new Map<Tree<T>, Tree<T> | undefined>();
    const buffer: Tree<T>[] = [root];

    parentMap.set(root, parent);

    while (buffer.length > 0 && !stopSignal.triggered) {
        const tail = buffer.shift();

        if (!tail) {
            continue;
        }

        const iteratorParent = parentMap.get(tail);
        action(tail, iteratorParent, stopSignal);

        if (!tail.children) {
            continue;
        }

        for (let i = 0; i < tail.children.length; i++) {
            const child = tail.children[i];
            parentMap.set(child, tail);
            buffer.push(child);
        }
    }
};

const traverseTreeDepthFirstPreOrder = <T>(
    root: Tree<T>,
    action: (node: Tree<T>, parent: Tree<T> | undefined, stop: Signal) => void,
    parent?: Tree<T>
) => {
    const stopSignal = {
        triggered: false
    };

    const parentMap = new Map<Tree<T>, Tree<T> | undefined>();
    const buffer: Tree<T>[] = [root];

    parentMap.set(root, parent);

    while (buffer.length > 0 && !stopSignal.triggered) {
        const tail = buffer.pop();

        if (!tail) {
            continue;
        }

        const iteratorParent = parentMap.get(tail);
        action(tail, iteratorParent, stopSignal);

        if (!tail.children) {
            continue;
        }

        for (let i = tail.children.length - 1; i >= 0; i--) {
            const child = tail.children[i];
            parentMap.set(child, tail);
            buffer.push(child);
        }
    }
};

const traverseTreeDepthFirstPostOrder = <T>(
    root: Tree<T>,
    action: (node: Tree<T>, parent: Tree<T> | undefined, stop: Signal) => void,
    parent?: Tree<T>
) => {
    const stopSignal = {
        triggered: false
    };

    const nextChildMap = new Map<Tree<T>, number>();
    const parentMap = new Map<Tree<T>, Tree<T> | undefined>();
    const buffer: Tree<T>[] = [];

    let iterator: Tree<T> | undefined = root;
    parentMap.set(root, parent);

    while ((buffer.length > 0 || iterator) && !stopSignal.triggered) {
        if (iterator) {
            buffer.push(iterator);

            const nextIndex: number = nextChildMap.get(iterator) ?? 0;
            nextChildMap.set(iterator, nextIndex + 1);
            const child: Tree<T> | undefined = iterator.children?.[nextIndex];

            if (child) {
                parentMap.set(child, iterator);
            }

            iterator = child;
            continue;
        }

        const tail = buffer.pop();

        if (!tail) {
            continue;
        }

        const nextIndex = nextChildMap.get(tail);
        const nextChild = nextIndex ? tail.children?.[nextIndex] : undefined;

        if (nextChild) {
            iterator = tail;
            continue;
        }

        const iteratorParent = parentMap.get(tail);
        action(tail, iteratorParent, stopSignal);
    }
};

export const traverseTree = <T>(
    roots: Tree<T>[],
    method: TreeTraversalMethod,
    action: (node: Tree<T>, parent: Tree<T> | undefined, stop: Signal) => void,
    parent?: Tree<T>
) => {
    for (const root of roots) {
        switch (method) {
            case TreeTraversalMethod.BFS:
                traverseTreeBreadthFirst(root, action, parent);
                break;
            case TreeTraversalMethod.DFSPreOrder:
                traverseTreeDepthFirstPreOrder(root, action, parent);
                break;
            case TreeTraversalMethod.DFSPostOrder:
                traverseTreeDepthFirstPostOrder(root, action, parent);
        }
    }
};

export const searchTree = <T>(
    roots: Tree<T>[],
    method: TreeTraversalMethod,
    predicate: (node: Tree<T>, parent?: Tree<T>) => boolean
): Tree<T> | undefined => {
    let result: Tree<T> | undefined = undefined;

    traverseTree(roots, method, (node, parent, stop) => {
        if (predicate(node, parent)) {
            result = node;
            stop.triggered = true;
        }
    });

    return result;
};

export const mapTree = <T, U>(
    roots: Tree<T>[],
    map: (
        node: Tree<T>,
        nextChildren: Tree<U>[],
        parent?: Tree<T>
    ) => Tree<U> | undefined,
    parent?: Tree<T>
): Tree<U>[] => {
    const mappedChildrenMap = new Map<Tree<T> | undefined, Tree<U>[]>();

    traverseTree(
        roots,
        TreeTraversalMethod.DFSPostOrder,
        (node, parent) => {
            if (!mappedChildrenMap.has(parent)) {
                mappedChildrenMap.set(parent, []);
            }

            const nodeNextChildren = mappedChildrenMap.get(node) ?? [];
            const mappedNode = map(node, nodeNextChildren, parent);

            if (mappedNode) {
                mappedChildrenMap.get(parent)?.push(mappedNode);
            }
        },
        parent
    );

    return mappedChildrenMap.get(undefined) ?? [];
};
