import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import { useTheme } from 'styled-components';
import { ProductStatus } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';

interface Props {
    status: ProductStatus;
}

export const ProductStatusTag = ({ status }: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    switch (status) {
        case ProductStatus.Enabled:
            return (
                <Tag color={theme.colors.tagStatusEnabled}>
                    {formatMessage(Messages['common.label.enabled'])}
                </Tag>
            );
        case ProductStatus.Disabled:
            return (
                <Tag>{formatMessage(Messages['common.label.disabled'])}</Tag>
            );
    }
};
