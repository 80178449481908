import { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Tag } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {
    getTemplates,
    MarketLanguage,
    ProductLabel,
    Template,
    TemplateId,
    TemplateListItem
} from '@olxeu-monetization/product-catalog-api-client';
import { Section } from '../../../components/Section';
import { AuthContext, ConfigContext, ModalContext } from '../../../context';
import { Messages } from '../../../intl';
import { TemplateSelectionModal } from '../../../modals/TemplateSelectionModal';
import { usePromise } from '../../../utils';
import { FormList } from '../FormList';
import { getSectionErrors, mapTemplateToLabel } from '../helpers';
import { DraggableTabs } from './DraggableTabs';
import { LabelTabContent } from './LabelTabContent';

const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${({ theme }) => theme.colors.validationError};
    margin-left: ${({ theme }) => theme.space[8]};
`;

interface Props {
    marketLanguages: MarketLanguage[];
    onTemplateSelect?: (template: Template) => void;
    onTemplateRemove?: (templateId: TemplateId) => void;
}

export const LabelsFormSection = ({
    marketLanguages,
    onTemplateSelect,
    onTemplateRemove
}: Props) => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const modal = useContext(ModalContext);
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();

    const [activeKey, setActiveKey] = useState(() => {
        const labelsInitialValue = form.getFieldValue(
            'labels'
        ) as ProductLabel[];
        return labelsInitialValue?.[0]?.templateId ?? '';
    });

    const templatesResult = usePromise({
        variables: {
            limit: 999,
            offset: 0
        },
        promiseBuilder: async (variables) => {
            return getTemplates(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const labelsErrors = getSectionErrors(form, 'labels');

    const shouldDisplayErrorForLabel = useCallback(
        (labelIndex) =>
            labelsErrors.some((error) => error.name[1] === labelIndex),
        [labelsErrors]
    );

    const handleModalShow = (
        templates: TemplateListItem[],
        templatesCount: number,
        add: (value?: unknown) => void,
        selectedIds: TemplateId[]
    ) => {
        modal.show(
            <TemplateSelectionModal
                templates={templates}
                templatesCount={templatesCount}
                selectedIds={selectedIds}
                onSelect={(template) => {
                    const mappedLabel = mapTemplateToLabel(template);

                    add(mappedLabel);
                    setActiveKey(template.id);
                }}
            />,
            {
                footer: null,
                centered: true,
                width: 700
            }
        );
    };

    const templatesLoading = templatesResult.loading;
    const templates = templatesResult.data?.data ?? [];
    const templatesCount = templatesResult.data?.metadata.totalCount ?? 0;

    return (
        <FormList name="labels">
            {(labelFields, labelListOperation, labelListName) => {
                const selectedIds = labelFields.map((field) =>
                    field.getValue('templateId')
                );

                const getPreviousAvailableKey = (removedId: TemplateId) => {
                    const removedIndex = selectedIds.indexOf(removedId);
                    const previousIndex = Math.max(removedIndex - 1, 0);

                    const availableKeys = selectedIds.filter(
                        (key) => key !== removedId
                    );

                    return availableKeys.length
                        ? availableKeys[previousIndex]
                        : '';
                };

                const labelTabs = labelFields.map((labelField, labelIndex) => {
                    const templateId = labelField.getValue('templateId');

                    return {
                        label: (
                            <span>
                                {labelField.getValue('templateId')}
                                {shouldDisplayErrorForLabel(
                                    labelField.name
                                ) && <ErrorIcon />}
                            </span>
                        ),
                        key: labelField.getValue('templateId'),
                        children: (
                            <LabelTabContent
                                templateId={templateId}
                                labelField={labelField}
                                labelListName={labelListName}
                                marketLanguages={marketLanguages}
                                onRemove={async (templateId) => {
                                    onTemplateRemove?.(templateId);

                                    const lastAvailableKey =
                                        getPreviousAvailableKey(
                                            labelField.getValue('templateId')
                                        );

                                    setActiveKey(lastAvailableKey);

                                    labelListOperation.remove(labelIndex);

                                    await form.validateFields();
                                }}
                                onAdd={(template) => {
                                    onTemplateSelect?.(template);
                                }}
                            />
                        )
                    };
                });

                return (
                    <Section
                        title={formatMessage(Messages['common.label.labels'])}
                        tags={
                            <Tag color={theme.colors.tagCounter}>
                                {formatMessage(
                                    Messages['common.tag.selected-of'],
                                    {
                                        selected: labelFields.length,
                                        available: templatesCount
                                    }
                                )}
                            </Tag>
                        }
                        extra={[
                            <Button
                                key="add-label"
                                icon={<PlusOutlined />}
                                loading={templatesLoading}
                                disabled={templatesLoading}
                                onClick={() =>
                                    handleModalShow(
                                        templates,
                                        templatesCount,
                                        labelListOperation.add,
                                        selectedIds
                                    )
                                }
                            >
                                {formatMessage(
                                    Messages['common.button.add-label']
                                )}
                            </Button>
                        ]}
                        data-testid="product-labels"
                    >
                        <DraggableTabs
                            form={form}
                            tabPosition="left"
                            type="card"
                            items={labelTabs}
                            activeKey={activeKey}
                            onChange={(key) => setActiveKey(key)}
                            className="tabs-with-validation-icon"
                        />
                    </Section>
                );
            }}
        </FormList>
    );
};
