import { notification } from 'antd';
import { HTTPError } from '@olxeu-monetization/product-catalog-api-client';

interface ErrorNotification {
    message: string;
    error: HTTPError;
}

export const errorNotification = ({ error, message }: ErrorNotification) => {
    return notification['error']({
        message,
        placement: 'top',
        description: error.details
    });
};
