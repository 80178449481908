import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    AugmentedRoute,
    AugmentedTableInterface,
    Button,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { CategorySetId } from '@olxeu-monetization/product-catalog-api-client';
import { Limit } from '@olxeu-monetization/user-product-service-api-client';
import { PageHeader } from '../../components/PageHeader';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';
import { Filters } from './columns';
import { buildLimitsData, mapTableFiltersToFilters } from './utils';

interface Props {
    marketId: MarketId;
    limits: Limit[];
    categorySetNamesMap: Map<CategorySetId, string>;
    breadcrumbRoutes: AugmentedRoute[];
    filters: Filters;
    pageIndex: number;
    pageSize: number;
    isLoading: boolean;
    totalLimitsCount: number;
    onFiltersChange?: (filters: Filters) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
}

const LimitTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

export const LimitListLayout = ({
    marketId,
    limits,
    categorySetNamesMap,
    breadcrumbRoutes,
    filters,
    pageIndex,
    pageSize,
    isLoading,
    totalLimitsCount,
    onFiltersChange,
    onPageIndexChange,
    onPageSizeChange
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const limitsCount = limits.length;

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(filters);
                onFiltersChange?.(nextFilters);
                break;
            }
            default:
                return;
        }
    };

    const dataSource = useMemo(() => {
        return buildLimitsData(limits, categorySetNamesMap);
    }, [limits, categorySetNamesMap]);

    const columns = useMemo(() => {
        return buildTableColumns({
            filters,
            marketId,
            intl
        });
    }, [filters, marketId, intl]);

    return (
        <div data-testid="limit-list-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.limits'])}
                tags={
                    limitsCount ? (
                        <Tag color={theme.colors.tagCounter}>
                            {formatMessage(Messages['common.tag.total-count'], {
                                count: totalLimitsCount
                            })}
                        </Tag>
                    ) : undefined
                }
                actions={[
                    <Link
                        key="create-limit"
                        to={buildLink(Path.LimitCreate, {
                            marketId
                        })}
                    >
                        <Button icon={<PlusOutlined />}>
                            {formatMessage(
                                Messages['common.button.create-new-limit']
                            )}
                        </Button>
                    </Link>
                ]}
                breadcrumbRoutes={breadcrumbRoutes}
                hideBackButton={true}
            />
            <LimitTable
                data-testid="limits-table"
                columns={columns}
                dataSource={dataSource}
                onChange={handleChange}
                pagination={{
                    total: totalLimitsCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                loading={isLoading}
            />
        </div>
    );
};
