import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, message, Tooltip } from 'antd';
import {
    CategorySetId,
    createOfferChangeProduct,
    CreateOfferChangeProductInput,
    CreateOfferChangeProductOutput,
    getCategorySets,
    getOfferChange,
    OfferChangeId,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext,
    FeaturesDataContext,
    MarketLanguagesDataContext,
    ProductTypesDataContext
} from '../../context';
import { Messages } from '../../intl';
import {
    ProductFormLayout,
    ProductFormPayload
} from '../../layouts/ProductFormLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../OfferChangeDetailsPage';

type Params = PathParams[Path.OfferChangeProductCreate];

const buildBreadcrumbsRoutes = (
    offerChangeId: OfferChangeId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(offerChangeId, marketId, intl),
        {
            path: buildLink(Path.OfferChangeProductCreate, {
                id: offerChangeId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.create-product']
            )
        }
    ];
};

export const OfferChangeProductCreatePage = () => {
    const intl = useIntl();
    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const params = useParams<Params>();
    const categoriesData = useContext(CategoriesDataContext);
    const navigate = useNavigate();

    const handleProductCreateComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<CreateOfferChangeProductOutput, unknown>
        >
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.product-create']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(
                Messages['common.success-message.product-create']
            )
        );

        const detailsLink = buildLink(Path.OfferChangeDetails, {
            id: params.id,
            marketId: params.marketId
        });

        navigate(detailsLink);
    };

    const offerChangeResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getOfferChange(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetsResult = usePromise({
        variables: {
            offset: 0,
            limit: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const productCreate = usePromise({
        variables: undefined as unknown as CreateOfferChangeProductInput,
        promiseBuilder: async (variables) => {
            return createOfferChangeProduct(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true,
        onComplete: handleProductCreateComplete
    });

    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const offerChangeLoading =
        offerChangeResult.loading && !offerChangeResult.data;
    const categorySetsLoading = categorySetsResult.loading;
    const categoriesLoading = categoriesData.loading && !categoriesData.data;

    const loading =
        marketLanguagesLoading ||
        productTypesLoading ||
        featuresLoading ||
        offerChangeLoading ||
        categorySetsLoading ||
        categoriesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const marketLanguages = marketLanguagesData.data?.current;
    const productTypes = productTypesData.data;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const offerChange = offerChangeResult?.data?.data;
    const categorySetsTotalCount = categorySetsResult.data?.metadata.totalCount;
    const categories = categoriesData.data;

    if (
        !marketLanguages ||
        !productTypes ||
        !features ||
        !featurePropertyValidationMap ||
        !offerChange ||
        categorySetsTotalCount === undefined ||
        !categories
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    const handleSubmit = (product: ProductFormPayload) => {
        const input = {
            ...product,
            id: params.id,
            expectedVersion: offerChange.version
        };

        void productCreate.execute(input);
    };

    const categorySetNamesMap = new Map<CategorySetId, string>();

    return (
        <ProductFormLayout
            title={intl.formatMessage(Messages['common.label.create-product'])}
            features={features}
            featurePropertyValidationMap={featurePropertyValidationMap}
            breadcrumbRoutes={breadcrumbRoutes}
            productTypes={productTypes}
            marketLanguages={marketLanguages}
            categories={categories}
            categorySetNamesMap={categorySetNamesMap}
            categorySetsTotalCount={categorySetsTotalCount}
            renderSubmitButton={(hasErrors, hasUndefinedTranslation) => (
                <Tooltip
                    title={intl.formatMessage(
                        Messages[
                            'common.error-message.label-translation-undefined'
                        ]
                    )}
                    placement="left"
                    open={hasUndefinedTranslation}
                >
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={
                            productCreate.loading ||
                            hasErrors ||
                            hasUndefinedTranslation
                        }
                    >
                        {intl.formatMessage(Messages['common.button.save'])}
                    </Button>
                </Tooltip>
            )}
            onSubmit={handleSubmit}
        />
    );
};
