import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Typography } from 'antd';
import { Messages } from '../../../../intl';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const { Title } = Typography;

const AttributesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    dataSource: TableDataSource[];
}

export const LabelAttributesTable = ({ dataSource }: Props) => {
    const { formatMessage } = useIntl();

    const columns = buildTableColumns();

    return (
        <>
            <Title level={5}>
                {formatMessage(Messages['common.label.attributes'])}
            </Title>
            <AttributesTable
                data-testid="label-attributes-table"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={false}
            />
        </>
    );
};
