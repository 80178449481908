import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import { useTheme } from 'styled-components';
import { OfferChangeStatus } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';

interface Props {
    status: OfferChangeStatus;
}

export const OfferChangeStatusTag = ({ status }: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    switch (status) {
        case OfferChangeStatus.Cancelled:
            return (
                <Tag color={theme.colors.tagStatusCancelled}>
                    {formatMessage(Messages['common.label.cancelled'])}
                </Tag>
            );
        case OfferChangeStatus.Draft:
            return (
                <Tag color={theme.colors.tagStatusDraft}>
                    {formatMessage(Messages['common.label.draft'])}
                </Tag>
            );
        case OfferChangeStatus.Published:
            return (
                <Tag color={theme.colors.tagStatusPublished}>
                    {formatMessage(Messages['common.label.published'])}
                </Tag>
            );
    }
};
