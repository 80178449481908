import { useIntl } from 'react-intl';
import { AugmentedRoute, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    CategorySetId,
    Feature,
    OfferChangeDetails,
    OfferChangeProduct,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import {
    Filters,
    FinishedOfferChangeProducts,
    FinishedOfferChangeProperties
} from '../../components/OfferChangeSections';
import { PageHeader } from '../../components/PageHeader';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';

interface Props {
    marketId: MarketId;
    offerChange: OfferChangeDetails;
    offerChangeProducts: OfferChangeProduct[];
    productsTotalCount: number;
    breadcrumbRoutes: AugmentedRoute[];
    categorySetNamesMap: Map<CategorySetId, string>;
    features: Feature[];
    productTypes: ProductType[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onFiltersChange?: (filters: Filters) => void;
}

export const FinishedOfferChangeDetailsLayout = ({
    marketId,
    offerChange,
    offerChangeProducts,
    productsTotalCount,
    breadcrumbRoutes,
    categorySetNamesMap,
    features,
    productTypes,
    pageIndex,
    pageSize,
    filters,
    onFiltersChange,
    onPageIndexChange,
    onPageSizeChange
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    return (
        <div data-testid="offer-change-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.offer-change'])}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: offerChange.id
                        })}
                    </Tag>
                }
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <FinishedOfferChangeProperties offerChange={offerChange} />
            <FinishedOfferChangeProducts
                marketId={marketId}
                offerChangeId={offerChange.id}
                offerChangeProducts={offerChangeProducts}
                productsTotalCount={productsTotalCount}
                categorySetNamesMap={categorySetNamesMap}
                features={features}
                productTypes={productTypes}
                pageIndex={pageIndex}
                pageSize={pageSize}
                filters={filters}
                onPageIndexChange={onPageIndexChange}
                onPageSizeChange={onPageSizeChange}
                onFiltersChange={onFiltersChange}
            />
        </div>
    );
};
