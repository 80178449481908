import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedRoute, Button, List, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySet
} from '@olxeu-monetization/product-catalog-api-client';
import { CategoriesSection } from '../../components/CategorySetSections/CategoriesSection';
import { CategorySetLocales } from '../../components/CategorySetSections/CategorySetLocales';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';

interface Props {
    categorySet: CategorySet;
    categories: Category[];
    marketId: MarketId;
    breadcrumbRoutes: AugmentedRoute[];
}

export const CategorySetDetailsLayout = ({
    categorySet,
    categories,
    marketId,
    breadcrumbRoutes
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    return (
        <div data-testid="category-set-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.category-set'])}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: categorySet.id
                        })}
                    </Tag>
                }
                actions={[
                    <Link
                        key="edit-product"
                        to={buildLink(Path.CategorySetEdit, {
                            id: categorySet.id,
                            marketId
                        })}
                    >
                        <Button icon={<EditOutlined />}>
                            {formatMessage(Messages['common.button.edit'])}
                        </Button>
                    </Link>
                ]}
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <Section
                title={formatMessage(Messages['common.label.properties'])}
                data-testid="category-set-properties"
            >
                <List>
                    <List.Item>
                        <List.Item.Meta
                            title={formatMessage(Messages['common.label.name'])}
                            description={
                                <span data-cy="category-set-properties-name">
                                    {categorySet.name}
                                </span>
                            }
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            title={formatMessage(Messages['common.label.type'])}
                            description={
                                <span data-cy="category-set-properties-type">
                                    {categorySet.type}
                                </span>
                            }
                        />
                    </List.Item>
                </List>
            </Section>
            <CategorySetLocales
                localizations={categorySet.localizations ?? []}
            />
            <CategoriesSection
                categories={categories}
                categoryIds={categorySet.categoryIds}
            />
        </div>
    );
};
