import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Form, Table, Typography } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import {
    MarketLanguage,
    ProductFeature,
    ProductLabel,
    ProductLabelValue,
    TemplateTranslation,
    TranslationValueType
} from '@olxeu-monetization/product-catalog-api-client';
import { Box } from '../../../../components/Box';
import { Messages } from '../../../../intl';
import { useChangeObserver } from '../../../../utils';
import { FormListField } from '../../FormList';
import { TranslationCard } from '../TranslationCard';
import {
    buildCombinedLabelValues,
    buildLabelValuesDataSource,
    getNormalizedValue,
    getResolvedTranslations
} from '../utils';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const { Title } = Typography;

const ValuesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    productLabels: ProductLabel[];
    productFeatures: ProductFeature[];
    labelField: FormListField;
    valuesFields: FormListField[];
    translations?: TemplateTranslation[];
    marketLanguages: MarketLanguage[];
}

export const LabelValuesTable = ({
    productLabels,
    productFeatures,
    labelField,
    valuesFields,
    translations,
    marketLanguages
}: Props) => {
    const [labelTranslations, setLabelTranslations] = useState<
        TemplateTranslation[] | undefined
    >([]);
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();

    const featuresValue = form.getFieldValue(['features']) as ProductFeature[];

    const referencedFeatureValue = form.getFieldValue([
        'labels',
        labelField.name,
        'featureId'
    ]) as ProductFeature['id'];

    const labelValues = form.getFieldValue([
        'labels',
        labelField.name,
        'values'
    ]) as ProductLabelValue[];

    const referencedFeature = Form.useWatch([
        'labels',
        labelField.name,
        'featureId'
    ]) as ProductFeature['id'];

    const values = Form.useWatch([
        'labels',
        labelField.name,
        'values'
    ]) as ProductLabelValue[];

    const types = useMemo(() => {
        return labelValues.map((labelValue) => labelValue.type);
    }, [labelValues]);

    const referencedFeatureValues = useMemo(() => {
        return (
            featuresValue.find((feature) => feature.id === referencedFeature)
                ?.properties ?? []
        );
    }, [featuresValue, referencedFeature]);

    const combinedLabelValues = buildCombinedLabelValues(
        referencedFeatureValues,
        labelValues
    );

    useChangeObserver(() => {
        const defaultValues = productLabels[labelField.name].values.map(
            (value) => {
                return {
                    name: value.name,
                    value: '',
                    type: TranslationValueType.Static
                };
            }
        );

        productLabels[labelField.name].values = defaultValues;
        form.setFieldValue('labels', productLabels);
    }, [referencedFeatureValue]);

    useChangeObserver(() => {
        const resolvedTranslations = getResolvedTranslations(
            combinedLabelValues,
            translations
        );
        setLabelTranslations(resolvedTranslations);
    }, [values]);

    useChangeObserver(() => {
        const isReferencedFeatureRemoved = productFeatures.every(
            (feature) => feature.id !== referencedFeature
        );

        if (isReferencedFeatureRemoved) {
            form.setFieldValue(
                ['labels', labelField.name, 'featureId'],
                undefined
            );
        }

        const isReferencedFeature = productLabels.some(
            (label) => label.featureId === referencedFeature
        );

        if (!isReferencedFeature) {
            return;
        }

        const productLabelsReferencedValues = productLabels.filter((label) =>
            label.values.some(
                (item) => item.type === TranslationValueType.Reference
            )
        );

        if (productLabelsReferencedValues.length < 1) {
            return;
        }

        productLabelsReferencedValues.forEach((label) => {
            label.values
                .filter(
                    (value) => value.type === TranslationValueType.Reference
                )
                .forEach((item) => {
                    const feature = productFeatures.find(
                        (feature) => feature.id === referencedFeature
                    );
                    const property = feature?.properties.find(
                        (property) => property.name === item.name
                    );

                    item.value = getNormalizedValue(property?.value ?? '');
                });
        });

        form.setFieldValue('labels', productLabels);
    }, [productFeatures]);

    useChangeObserver(
        ([previousTypes]) => {
            const changedIndex = previousTypes.findIndex(
                (previousType, index) => previousType !== types[index]
            );

            const valueName = valuesFields[changedIndex].getValue('name');

            const referencedValue = productFeatures
                .find((feature) => feature.id === referencedFeatureValue)
                ?.properties.find(
                    (property) => property.name === valueName
                )?.value;

            if (types[changedIndex] === TranslationValueType.Reference) {
                productLabels[labelField.name].values[changedIndex].value =
                    getNormalizedValue(referencedValue);
                form.setFieldValue('labels', productLabels);
            }

            if (types[changedIndex] === TranslationValueType.Static) {
                productLabels[labelField.name].values[changedIndex].value = '';
                form.setFieldValue('labels', productLabels);
            }

            const resolvedTranslations = getResolvedTranslations(
                labelValues,
                translations
            );
            setLabelTranslations(resolvedTranslations);

            void form.validateFields();
        },
        [types]
    );

    const columns = buildTableColumns();

    const dataSource = useMemo(() => {
        const referencedFeatureProperties = productFeatures.find(
            (features) => features.id === referencedFeatureValue
        )?.properties;

        return buildLabelValuesDataSource(
            valuesFields,
            referencedFeatureProperties
        );
    }, [productFeatures, referencedFeatureValue, valuesFields]);

    if (!dataSource.length) {
        return null;
    }

    return (
        <>
            <Title level={5}>
                {formatMessage(Messages['common.label.values'])}
            </Title>
            <ValuesTable
                data-testid="label-values-table"
                className="label-values-table"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={false}
            />
            <Box maxWidth={600} mt={12} mb={24}>
                <TranslationCard
                    title={formatMessage(
                        Messages['common.label.resolved-translation']
                    )}
                    icon={<TagOutlined style={{ marginRight: 8 }} />}
                    translations={labelTranslations}
                    marketLanguages={marketLanguages}
                />
            </Box>
        </>
    );
};
