import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { List, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    OfferChangeProductDetails,
    ProductDetails
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { ProductTypeTreeNode } from '../../../layouts/ProductFormLayout/types';
import { PropertyLabel } from '../../PropertyLabel';
import { Section } from '../../Section';
import { buildProductTypePath } from '../utils';
import { buildDataSource } from './buildDataSource';
import { ProductPropertyDiff } from './ProductPropertyDiff';

interface ReadonlyProductProps {
    product: ProductDetails | OfferChangeProductDetails;
    productTypeTree: ProductTypeTreeNode[];
    previousProduct?: undefined;
    diffVisible?: undefined;
}

interface DiffProductProps {
    product: ProductDetails | OfferChangeProductDetails;
    productTypeTree: ProductTypeTreeNode[];
    previousProduct: ProductDetails;
    diffVisible?: boolean;
}

type Props = ReadonlyProductProps | DiffProductProps;

export const ProductProperties = ({
    product,
    productTypeTree,
    previousProduct,
    diffVisible
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const { dataSource, changesCount } = useMemo(() => {
        return buildDataSource(product, previousProduct, intl);
    }, [intl, previousProduct, product]);

    const getProductPropertyValue = (
        id: string,
        value: string | number | ReactNode
    ) => {
        if (id === 'type') {
            return buildProductTypePath(productTypeTree, String(value));
        }

        return value;
    };

    return (
        <Section
            title={formatMessage(Messages['common.label.properties'])}
            tags={
                diffVisible ? (
                    <Tag
                        color={theme.colors.tagChanges}
                        data-testid="product-diff-properties-changes-tag"
                    >
                        {formatMessage(Messages['common.tag.changes'], {
                            count: changesCount
                        })}
                    </Tag>
                ) : undefined
            }
            data-testid="product-properties"
        >
            <List
                dataSource={dataSource}
                renderItem={(item) => {
                    return (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <PropertyLabel
                                        title={item.label}
                                        description={item.description}
                                    />
                                }
                                description={
                                    diffVisible &&
                                    item.value !== undefined &&
                                    item.previousValue !== undefined ? (
                                        <ProductPropertyDiff
                                            id={item.id}
                                            value={item.value}
                                            previousValue={item.previousValue}
                                        />
                                    ) : (
                                        <span
                                            data-testid={`product-diff-properties-current-value[${item.id}]`}
                                        >
                                            {getProductPropertyValue(
                                                item.id,
                                                item.value
                                            )}
                                        </span>
                                    )
                                }
                            />
                        </List.Item>
                    );
                }}
            />
        </Section>
    );
};
