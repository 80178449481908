import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    Category,
    CategoryId,
    CategorySet,
    CategorySetType
} from '@olxeu-monetization/product-catalog-api-client';
import { buildTree, omitNodesWithSelectedAncestors } from '../../utils';
import { TableFilters } from './columns';

const DefaultRootCategoryId = 0;

export const buildCategoryOptions = (
    categories: Category[],
    rootCategoryId = DefaultRootCategoryId
) => {
    const nodes = categories.map((category) => ({
        value: String(category.id),
        text: category.name,
        parentId: category.parentId
    }));

    return buildTree(nodes, 'value', 'parentId', rootCategoryId);
};

export const buildTypeOptions = () => {
    const categorySetTypes = Object.values(CategorySetType);

    return categorySetTypes.map((type) => ({
        value: type,
        text: type
    }));
};

export const buildCategorySetsDataSource = (
    categorySets: CategorySet[],
    categories: Category[]
) => {
    const categoryMap = categories.reduce((acc, category) => {
        acc.set(category.id, category);
        return acc;
    }, new Map<CategoryId, Category>());

    return categorySets.map((categorySet) => {
        return {
            key: categorySet.id,
            id: categorySet.id,
            name: categorySet.name,
            type: categorySet.type,
            categories: categorySet.categoryIds.reduce<Category[]>(
                (acc, categoryId) => {
                    const category = categoryMap.get(categoryId);

                    if (category === undefined) {
                        return [
                            ...acc,
                            {
                                id: categoryId,
                                name: '',
                                parentId: -1
                            }
                        ];
                    }

                    return [...acc, category];
                },
                []
            )
        };
    });
};

export const mapTableFiltersToFilters = (
    filters: TableFilters,
    categoryOptions: ColumnFilterItem[]
) => {
    return {
        name: filters.name?.[0],
        types: filters.types ?? [],
        categoryIds: omitNodesWithSelectedAncestors(
            categoryOptions,
            filters.categories ?? [],
            'value'
        )
    };
};
