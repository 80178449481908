import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { OfferChangeId } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';

interface Props {
    marketId: MarketId;
    offerChangeId: OfferChangeId;
    children: React.ReactNode;
}

export const ProductLockedWarning = ({
    marketId,
    offerChangeId,
    children
}: Props) => {
    const { formatMessage } = useIntl();

    const offerChangeLink = buildLink(Path.OfferChangeDetails, {
        marketId,
        id: offerChangeId
    });

    const tooltipContent = formatMessage(
        Messages['common.tooltip-message.product-locked'],
        {
            offerChangeWithLink: (
                <Link to={offerChangeLink}>
                    {formatMessage(Messages['common.label.offer-change'])}
                </Link>
            )
        }
    );

    return (
        <Tooltip title={tooltipContent} placement="left">
            {children}
        </Tooltip>
    );
};
