import { IntlShape } from 'react-intl';
import { Messages } from '../../intl';
import { ProductTypeTreeNode } from '../../layouts/ProductFormLayout/types';
import { Duration } from '../../types';

export const getHumanTimeLabel = (
    formattedDuration: string | undefined,
    intl: IntlShape
) => {
    if (formattedDuration === undefined) {
        return intl.formatMessage(Messages['common.label.never-expire']);
    }

    return Duration.fromFormatted(formattedDuration).toHumanized();
};

export const buildProductTypePath = (
    tree: ProductTypeTreeNode[],
    value: string
): string => {
    const path = tree.reduce<string[]>((acc, node) => {
        if (node.label === value) {
            return [...acc, node.label];
        }

        if (node.children) {
            const childPath = buildProductTypePath(node.children, value);

            if (childPath) {
                return [...acc, node.label, childPath];
            }
        }

        return acc;
    }, []);

    return path.join(' / ');
};
