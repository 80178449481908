import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    Category,
    CategoryId,
    CategorySet,
    CategorySetId
} from '@olxeu-monetization/product-catalog-api-client';
import { omitNodesWithSelectedAncestors } from '../../utils';
import { TableFilters } from './columns';

export const buildCategorySetsDataSource = (
    categorySets: CategorySet[],
    categories: Category[],
    selectedIds: CategorySetId[]
) => {
    const selectedIdsMap = selectedIds.reduce((acc, id) => {
        acc.set(id, true);
        return acc;
    }, new Map<CategorySetId, boolean>());

    const categoryMap = categories.reduce((acc, category) => {
        acc.set(category.id, category);
        return acc;
    }, new Map<CategoryId, Category>());

    return categorySets.map((categorySet) => {
        return {
            key: categorySet.id,
            id: categorySet.id,
            name: categorySet.name,
            selected: selectedIdsMap.get(categorySet.id) ?? false,
            categories: categorySet.categoryIds.map((categoryId) => {
                const category = categoryMap.get(categoryId);

                if (category === undefined) {
                    throw new Error(
                        `Unsupported category with id ${categoryId}`
                    );
                }

                return category;
            })
        };
    });
};

export const mapTableFiltersToFilters = (
    filters: TableFilters,
    categoryOptions: ColumnFilterItem[]
) => {
    return {
        name: filters.name?.[0],
        categoryIds: omitNodesWithSelectedAncestors(
            categoryOptions,
            filters.categories ?? [],
            'value'
        )
    };
};
