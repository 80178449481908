import { Key, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    AugmentedTableInterface,
    CheckboxProps,
    Table,
    TablePaginationConfig
} from 'antd';
import {
    RowSelectMethod,
    SorterResult,
    TableCurrentDataSource
} from 'antd/lib/table/interface';
import { CategoryId } from '@olxeu-monetization/product-catalog-api-client';
import { buildCheckedState, changeTreeNodeChecked } from '../../../helpers';
import { getHighlightedData, updateElementAttribute } from '../../../utils';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import { CategoryTreeNode } from './types';

const CategoriesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    categoryTree: CategoryTreeNode[];
    value?: CategoryId[];
    onChange?: (value?: CategoryId[]) => void;
}

export const CategoriesFormInput = ({
    categoryTree,
    value,
    onChange
}: Props) => {
    const intl = useIntl();
    const [filters, setFilters] = useState<Filters>({
        title: ''
    });

    const treeData = useMemo(() => {
        return getHighlightedData({
            roots: categoryTree,
            query: filters.title ?? ''
        });
    }, [categoryTree, filters.title]);

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'filter': {
                const nextFilters = {
                    title: filters.title?.[0]
                };

                setFilters(nextFilters);

                break;
            }
            default:
                return;
        }
    };

    const columns = useMemo(() => {
        return buildTableColumns({
            filters,
            intl
        });
    }, [filters, intl]);

    const rowSelection = useMemo(() => {
        return {
            type: 'checkbox',
            checkStrictly: false,
            selectedRowKeys: value,
            getCheckboxProps: (record: TableDataSource) => {
                return {
                    'data-cy': `category-checkbox[${record.key}]`
                } as CheckboxProps;
            },
            onSelect: (
                record: TableDataSource,
                selected: boolean,
                selectedRows: TableDataSource[],
                nativeEvent: Event
            ) => {
                const checkedList = buildCheckedState(
                    categoryTree,
                    value ?? []
                );

                const nextSelectedIds = changeTreeNodeChecked({
                    roots: categoryTree,
                    changedNodeKey: Number(record.key),
                    changedNodeChecked: selected,
                    selectedKeys: checkedList.checked,
                    keyName: 'value'
                });

                const mappedIds = nextSelectedIds.map((key) => Number(key));

                onChange?.(mappedIds);

                // WARN: manually syncing `checked` attribute with the property; sync is not performed by antd library automatically
                if (nativeEvent.target instanceof HTMLInputElement) {
                    updateElementAttribute(nativeEvent.target, 'checked');
                }
            },
            onChange: (
                selectedRowKeys: Key[],
                selectedRows: TableDataSource[],
                info: {
                    type: RowSelectMethod;
                }
            ) => {
                if (info.type !== 'all') {
                    return;
                }

                const rootKeys = treeData.map((node) => Number(node.key));

                const allSelected = selectedRows.every((selectedRow) => {
                    return rootKeys.includes(Number(selectedRow.key));
                });

                if (allSelected) {
                    onChange?.([]);
                } else {
                    onChange?.(rootKeys);
                }
            }
        } as const;
    }, [categoryTree, onChange, treeData, value]);

    return (
        <CategoriesTable
            data-testid="categories-table"
            columns={columns}
            dataSource={treeData}
            pagination={false}
            rowSelection={rowSelection}
            onRow={(record: TableDataSource) =>
                ({
                    'data-cy': `categories-table-row-${
                        record.children ? 'parent' : 'leaf'
                    }`
                } as React.HTMLAttributes<HTMLElement>)
            }
            onChange={handleChange}
        />
    );
};
