import { FormattedMessage } from 'react-intl';
import { List, Popover } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { Messages } from '../../../intl';
import { ShortList } from '../../ShortList';
import { CategorySetsBox, ListIcon } from './ProductCategorySetsCell.styled';

interface Props {
    categorySets: DataNode[];
}

export const ProductCategorySetsCell = ({ categorySets }: Props) =>
    categorySets.length > 0 ? (
        <Popover
            title={
                <FormattedMessage {...Messages['common.label.category-sets']} />
            }
            trigger="click"
            placement="top"
            content={
                <List
                    size="small"
                    dataSource={categorySets}
                    renderItem={(item) => <div>{item.title}</div>}
                />
            }
        >
            <CategorySetsBox>
                <ListIcon />
                <ShortList ml={8} items={categorySets} property="title" />
            </CategorySetsBox>
        </Popover>
    ) : null;
