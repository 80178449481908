import { FormListField } from '../../layouts/ProductFormLayout/FormList';

export const buildDataSource = (categorySetFields: FormListField[]) => {
    return categorySetFields.map((categorySetField, index) => {
        return {
            index,
            key: categorySetField.getValue('id'),
            id: categorySetField.getValue('id'),
            name: categorySetField.getValue('name')
        };
    });
};
