import { Buffer } from 'buffer';
import { MarketId } from '../../routing';
import {
    OktaAccessScope,
    OktaAccessScopeGrant,
    OktaMarketClientId,
    OktaMarketConfig
} from './types';

export const parseJwt = <T = Record<string, unknown>>(encodedJwt: string) => {
    const segments = encodedJwt.split('.');
    const dataSegment = segments[1];
    const decodedData = Buffer.from(dataSegment, 'base64').toString('utf8');

    return JSON.parse(decodedData) as T;
};

export const getOktaMarketConfigByMarketId = (
    marketId: MarketId,
    marketConfigs: OktaMarketConfig[]
) => {
    return marketConfigs.find((marketConfig) => {
        return marketConfig.marketId === marketId;
    });
};

export const getOktaMarketConfigByClientId = (
    clientId: OktaMarketClientId,
    marketConfigs: OktaMarketConfig[]
) => {
    return marketConfigs.find((marketConfig) => {
        return marketConfig.clientId === clientId;
    });
};

export const getOktaMarketIds = (marketConfigs: OktaMarketConfig[]) => {
    return marketConfigs.map((marketConfig) => {
        return marketConfig.marketId;
    });
};

export const buildOktaMarketConfigs = (envSeed: string): OktaMarketConfig[] => {
    const markets = envSeed.split(';');

    const clientIds = markets.map((market) => {
        const [marketIdCandidate, clientId] = market.split('=');
        const marketId = parseMarketId(marketIdCandidate);

        if (marketId === undefined) {
            throw new Error('Market in config is unsupported');
        }

        return { marketId, clientId };
    });

    return clientIds;
};

export const parseAccessScopeGrant = (accessScope: OktaAccessScope) => {
    const grants = Object.values(OktaAccessScopeGrant);
    const grantsUnion = grants.join('|');

    const parser = new RegExp(
        `(?:app_monetization_backoffice|app_mh)_[^_]+_[^_]+_(${grantsUnion})`
    );
    const regexpResults = parser.exec(accessScope) as
        | [unknown, OktaAccessScopeGrant | undefined]
        | null;
    const grant = regexpResults?.[1];

    return grant;
};

export const parseMarketId = (marketIdCandidate: string) => {
    const markets = Object.values(MarketId);
    return markets.find((marketId) => {
        return marketId === marketIdCandidate;
    });
};
