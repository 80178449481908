import { ReactNode } from 'react';
import { traverseTree, TreeTraversalMethod } from '../../../utils';

export type CategoryTreeItem = {
    key: string | number;
    title?: ReactNode;
    children?: CategoryTreeItem[];
};

export type MappedCategoryTreeItem = {
    value: string | number;
    text: ReactNode;
    children?: MappedCategoryTreeItem[];
};

export const getExpandableCategoryKeys = (
    categories: MappedCategoryTreeItem[]
) => {
    const buffer: (string | number)[] = [];

    traverseTree(categories, TreeTraversalMethod.BFS, (node) => {
        if (node.children) {
            buffer.push(node.value);
        }
    });

    return buffer;
};
