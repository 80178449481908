import { createContext, RefObject } from 'react';

export interface AppLayoutContextType {
    containerRef: RefObject<HTMLElement>;
}

export const DefaultAppLayoutContext: AppLayoutContextType = {
    containerRef: { current: null }
};

export const AppLayoutContext = createContext(DefaultAppLayoutContext);
