import { CategorySetId } from '@olxeu-monetization/product-catalog-api-client';
import {
    Limit,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { buildCategorySets } from '../../helpers';
import { TableFilters } from './columns';

export const buildLimitsData = (
    limits: Limit[],
    categorySetNamesMap: Map<CategorySetId, string>
) =>
    limits.map((limit) => ({
        key: limit.id,
        id: limit.id,
        name: Object.values(limit.labels.name)[0], //TODO: add language support
        status: limit.status,
        type: limit.limit_type,
        categorySet: buildCategorySets(
            [limit.product_category_set_id],
            categorySetNamesMap
        )
    }));

export const mapTableFiltersToFilters = (filters: TableFilters) => {
    return {
        status: filters.status?.[0],
        categorySetIds: filters.categorySet ?? []
    };
};

export const buildTypeOptions = (
    limitsTypeNameMapping: Record<LimitType, string>
) => {
    const limitsTypes = Object.values(LimitType);

    return limitsTypes.map((type) => ({
        value: type,
        text: limitsTypeNameMapping[type]
    }));
};
