import { ReactNode, useContext } from 'react';
import { Affix, Space } from 'antd';
import { AppLayoutContext } from '../../context';
import { BottomBarWrapper } from './BottomBar.styled';

interface Props {
    children?: ReactNode;
}

export const BottomBar = ({ children }: Props) => {
    const { containerRef } = useContext(AppLayoutContext);

    return (
        <Affix offsetBottom={0} target={() => containerRef.current}>
            <BottomBarWrapper px={24} py={16} mx={-24}>
                <Space>{children}</Space>
            </BottomBarWrapper>
        </Affix>
    );
};
