import { Tag } from 'antd';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const ProductEntryChange = styled.div`
    border: 1px;
    border-left: 4px;
    border-style: solid;
    border-radius: ${({ theme }) => theme.radii[4]};
    padding: 8px 8px 8px 16px;
    display: flex;
    justify-content: space-between;
`;

export const ProductEntryRemoved = styled(ProductEntryChange)`
    background: ${({ theme }) => theme.colors.lightPink};
    border-color: ${({ theme }) => theme.colors.pink};
`;

export const ProductEntryAdded = styled(ProductEntryChange)`
    background: ${({ theme }) => theme.colors.lightGreen};
    border-color: ${({ theme }) => theme.colors.green};
`;

export const ProductEntryPrevious = styled(ProductEntryRemoved)`
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-width: 0;
`;

export const ProductEntryNew = styled(ProductEntryAdded)`
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: ${({ theme }) => theme.borders.dashedGray1};
`;

export const ChangeTag = styled(Tag)<SpaceProps>`
    text-transform: uppercase;
    ${space}
`;
