import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import { Formats } from '../../intl';
import { subtractDates } from './utils';

interface Props {
    date: string;
    threshold: number;
}

export const RelativeDateLabel = ({ date, threshold }: Props) => {
    const targetDate = new Date(date);
    const currentDate = new Date();
    const timeSpan = subtractDates(targetDate, currentDate);

    if (Math.abs(timeSpan) < threshold) {
        return (
            <FormattedRelativeTime
                value={timeSpan}
                numeric="auto"
                updateIntervalInSeconds={1}
            />
        );
    }

    return <FormattedDate value={targetDate} format={Formats.date.medium} />;
};
