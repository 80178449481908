import { useIntl } from 'react-intl';
import { Formats } from '../../intl';
import { TimeInterval } from '../../types';
import { RelativeDateLabel } from './RelativeDateLabel';

interface Props {
    date: string;
    threshold?: number;
}

export const DateLabel = ({ date, threshold = TimeInterval.Week }: Props) => {
    const { formatDate } = useIntl();

    return (
        <span
            data-testid="date-label"
            title={formatDate(date, { format: Formats.date.long })}
        >
            <RelativeDateLabel date={date} threshold={threshold} />
        </span>
    );
};
