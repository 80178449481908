import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useStableValue = <T>(value: T) => {
    const valueRef = useRef<T>(value);

    if (!isEqual(value, valueRef.current)) {
        valueRef.current = value;
    }

    return valueRef.current;
};
