import {
    Feature,
    FeatureId
} from '@olxeu-monetization/product-catalog-api-client';

export const buildTargetOptions = (features: Feature[]) => {
    const targets = features.map((feature) => feature.target);
    const targetOptions = new Set(targets);
    return Array.from(targetOptions).map((target) => ({
        text: target,
        value: target
    }));
};

export const buildFeaturesDataSource = (
    features: Feature[],
    selectedIds: FeatureId[]
) => {
    const selectedIdsMap = selectedIds.reduce((acc, id) => {
        acc.set(id, true);
        return acc;
    }, new Map<FeatureId, boolean>());

    return features.map((feature) => ({
        key: feature.id,
        name: feature.id,
        target: feature.target,
        selected: selectedIdsMap.get(feature.id) ?? false
    }));
};
