import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface SearchIconProps {
    $active: boolean;
}

const SearchIcon = styled(SearchOutlined)<SearchIconProps>`
    color: ${({ theme, $active }) =>
        $active ? theme.colors.blue : theme.colors.gray};
`;

export const renderSearchIcon = (filtered: boolean) => (
    <SearchIcon size={32} $active={filtered} data-cy="table-search-icon" />
);
