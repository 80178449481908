import { useIntl } from 'react-intl';
import { Button, Form, Input, List } from 'antd';
import styled from 'styled-components';
import { OfferChangeDetails } from '@olxeu-monetization/product-catalog-api-client';
import { DateLabel } from '../../components/DateLabel';
import { Section } from '../../components/Section';
import { Messages } from '../../intl';
import { OfferChangeStatusTag } from '../StatusTag';

const ChangeButton = styled(Button)`
    margin-left: 16px;
`;

const FormWrapper = styled.div`
    display: inline-flex;
    align-items: baseline;

    form {
        min-width: 400px;
        display: flex;
    }
`;

type EditOfferChangeNameFormData = {
    name: string;
};
interface Props {
    offerChange: OfferChangeDetails;
    nameEditLoading: boolean;
    nameEditEnabled: boolean;
    onNameEditToggle?: (enabled: boolean) => void;
    onNameEditSubmit?: (name: string) => void;
}

export const DraftOfferChangeProperties = ({
    offerChange,
    nameEditLoading,
    nameEditEnabled,
    onNameEditToggle,
    onNameEditSubmit
}: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [form] = Form.useForm();

    const handleNameButtonClick = () => {
        onNameEditToggle?.(true);
    };

    const handleFormSubmit = (values: EditOfferChangeNameFormData) => {
        onNameEditSubmit?.(values.name);
    };

    const handleSubmit = () => {
        form.submit();
    };

    const handleCancel = () => {
        onNameEditToggle?.(false);
        form.resetFields();
    };

    const nameInput = nameEditEnabled ? (
        <FormWrapper>
            <Form
                form={form}
                onFinish={handleFormSubmit}
                layout="vertical"
                data-testid="offer-change-name-edit-form"
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: formatMessage(
                                Messages['common.error-message.required']
                            )
                        }
                    ]}
                    initialValue={offerChange.name}
                    style={{
                        marginBottom: 0
                    }}
                >
                    <Input
                        data-testid="offer-change-name-input"
                        autoFocus={true}
                        disabled={nameEditLoading}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="link"
                        htmlType="button"
                        onClick={handleCancel}
                        disabled={nameEditLoading}
                    >
                        {formatMessage(Messages['common.button.cancel'])}
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit}
                        disabled={nameEditLoading}
                    >
                        {formatMessage(Messages['common.button.save'])}
                    </Button>
                </Form.Item>
            </Form>
        </FormWrapper>
    ) : (
        <>
            <span data-cy="offer-change-name">{offerChange.name}</span>
            <ChangeButton onClick={handleNameButtonClick}>
                {formatMessage(Messages['common.button.change'])}
            </ChangeButton>
        </>
    );

    const dataSource = [
        {
            label: formatMessage(Messages['common.label.name']),
            value: nameInput
        },
        {
            label: formatMessage(Messages['common.label.last-author']),
            value: offerChange.lastAuthor
        },
        {
            label: formatMessage(Messages['common.label.last-edited']),
            value: <DateLabel date={offerChange.updatedAt} />
        },
        {
            label: formatMessage(Messages['common.label.status']),
            value: <OfferChangeStatusTag status={offerChange.status} />
        }
    ];

    return (
        <Section
            title={formatMessage(Messages['common.label.properties'])}
            data-testid="offer-change-properties"
        >
            <List
                dataSource={dataSource}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.label}
                            description={item.value}
                        />
                    </List.Item>
                )}
            />
        </Section>
    );
};
