import mapValues from 'lodash/mapValues';

export const transformMessages = <T extends Record<string, string>>(
    messages: T
) => {
    return mapValues(messages, (value, id) => {
        return {
            id,
            defaultMessage: value
        };
    });
};

export const transformFormats = <
    T extends Record<string, Record<string, unknown>>
>(
    formats: T
) => {
    return mapValues(formats, (value) => {
        return mapValues(value, (innerValue, key) => {
            return key;
        });
    });
};
