import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { getCategorySets } from '@olxeu-monetization/product-catalog-api-client';
import {
    CreateLimitInput,
    CreateLimitOutput
} from '@olxeu-monetization/user-product-service-api-client';
import { Loader } from '../../components/Loader';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext
} from '../../context';
import { useUpsService } from '../../helpers/useUpsClient';
import { Messages } from '../../intl';
import {
    LimitFormLayout,
    LimitFormPayload
} from '../../layouts/LimitFormLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../LimitListPage';

type Params = PathParams[Path.LimitCreate];

const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.LimitCreate, {
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.create-limit']
            )
        }
    ];
};

export const LimitCreatePage = () => {
    const intl = useIntl();
    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);
    const { createLimit } = useUpsService();
    const navigate = useNavigate();
    const params = useParams<Params>();
    const categoriesData = useContext(CategoriesDataContext);

    const handleLimitCreateComplete = (
        completionParams: CompletionParams<unknown, CreateLimitOutput[]>
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.limit-create']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(Messages['common.success-message.limit-create'])
        );

        const detailsLink = buildLink(Path.LimitDetails, {
            id: completionParams.data?.[0].id as string,
            marketId: params.marketId
        });

        navigate(detailsLink);
    };

    const categorySetsResult = usePromise({
        variables: {
            offset: 0,
            limit: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const limitCreate = usePromise({
        variables: undefined as unknown as CreateLimitInput[],
        promiseBuilder: async (variables) => {
            return createLimit(variables);
        },
        disableAutostart: true,
        onComplete: handleLimitCreateComplete
    });

    const categorySetsLoading = categorySetsResult.loading;
    const categories = categoriesData.data;
    const categorySetsTotalCount = categorySetsResult.data?.metadata.totalCount;

    const loading = categorySetsLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    if (categorySetsTotalCount === undefined || !categories) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handleSubmit = (limit: LimitFormPayload[]) => {
        void limitCreate.execute(limit);
    };

    return (
        <LimitFormLayout
            title={intl.formatMessage(Messages['common.label.create-limit'])}
            breadcrumbRoutes={breadcrumbRoutes}
            categories={categories}
            categorySetsTotalCount={categorySetsTotalCount}
            submitting={limitCreate.loading}
            onSubmit={handleSubmit}
            marketId={params.marketId}
        />
    );
};
