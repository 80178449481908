import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedRoute, Button, Table, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled, { useTheme } from 'styled-components';
import { CategorySet } from '@olxeu-monetization/product-catalog-api-client';
import {
    Limit,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { CategoriesSection } from '../../components/CategorySetSections/CategoriesSection';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { CategoriesDataContext } from '../../context';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { buildTableColumns, getTableData } from './utils';

interface Props {
    limits: Limit[];
    marketId: MarketId;
    breadcrumbRoutes: AugmentedRoute[];
    categorySet: CategorySet;
}

const EditButton = styled(Button)`
    ${({ theme }) => `
            margin-left: ${theme.space[12]};
    `}
`;

export const LimitDetailsLayout = ({
    limits,
    marketId,
    breadcrumbRoutes,
    categorySet
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const tableData = getTableData(limits);
    const columns = buildTableColumns(intl);
    const defaultLimit = limits.find(
        (limit) => limit.limit_type === LimitType.Default
    );
    const categoriesData = useContext(CategoriesDataContext);
    const categories = categoriesData.data ?? [];

    if (!defaultLimit) return null;

    return (
        <div data-testid="limit-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.limit'])}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: defaultLimit.id
                        })}
                    </Tag>
                }
                actions={[
                    <Link
                        data-testid="edit-limit-button"
                        key="edit-limit"
                        to={buildLink(Path.LimitEdit, {
                            id: defaultLimit.id,
                            marketId
                        })}
                    >
                        <Button icon={<EditOutlined />}>
                            {formatMessage(Messages['common.button.edit'])}
                        </Button>
                    </Link>
                ]}
                breadcrumbRoutes={breadcrumbRoutes}
            />

            <Section>
                <Table
                    data-testid="limit-types-table"
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                />
            </Section>

            <Section
                title={formatMessage(Messages['common.label.category-set'])}
            >
                {categorySet.name}
                <Link
                    to={buildLink(Path.CategorySetEdit, {
                        id: categorySet.id,
                        marketId
                    })}
                    data-testid="edit-category-set-button"
                >
                    <EditButton size="small">
                        {formatMessage(Messages['common.button.edit'])}
                    </EditButton>
                </Link>
            </Section>

            <CategoriesSection
                categories={categories}
                categoryIds={categorySet.categoryIds}
            />
        </div>
    );
};
