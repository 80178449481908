import { Typography } from 'antd';
import styled from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';

const { Text } = Typography;

interface HeaderProps {
    collapsed: boolean;
}

export const HeaderWrapper = styled.header<HeaderProps>`
    ${({ theme, collapsed }) => `
    background: ${theme.colors.white};
    height: ${theme.sizes.headerHeight};
    padding: ${`0 ${theme.space[24]} 0 ${
        collapsed
            ? theme.sizes.collapsedSidebarHeaderPadding
            : theme.sizes.sidebarHeaderPadding
    }`};
    transition: padding ${theme.transitions.sidebar};
    border-bottom: ${theme.borders.black10p1};
    z-index: ${theme.zIndices.fixed};
`};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderLabel = styled(Text)<FontSizeProps>`
    ${fontSize}
`;
