export enum KnownProductType {
    BuyerTakeRate = 'BUYER_TAKE_RATE',
    ListingFeeStandard = 'LISTING_FEE_STANDARD',
    ListingFeeMiddle = 'LISTING_FEE_MIDDLE',
    ListingFeePremium = 'LISTING_FEE_PREMIUM',
    ListingFeeMega = 'LISTING_FEE_MEGA',
    SellerTakeRate = 'SELLER_TAKE_RATE',
    Vas = 'VAS',
    VasBundle = 'VAS_BUNDLE'
}

export const ListingFeeProductTypes = [
    KnownProductType.ListingFeeStandard,
    KnownProductType.ListingFeeMiddle,
    KnownProductType.ListingFeePremium,
    KnownProductType.ListingFeeMega
];
