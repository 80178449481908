import { useMemo } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { useCurrentFunction } from '../useCurrentFunction';
import {
    buildQueryParamsWithoutDefaults,
    buildQueryParamsWithoutEmpties
} from './queryParamsHelpers';
import { parseSearchParams } from './searchParamsParser';
import { serializeSearchParams } from './searchParamsSerializer';
import {
    ParsedQueryParamsSchema,
    QueryParamsDefaults,
    QueryParamsSchema
} from './types';

type InferDefaultsType<
    S extends QueryParamsSchema,
    D extends QueryParamsDefaults,
    P = ParsedQueryParamsSchema<S>
> = P extends D ? D : Partial<P>;

interface Options<S extends QueryParamsSchema, D extends QueryParamsDefaults> {
    defaults: InferDefaultsType<S, D>;
    schema: S;
}

export const useQueryParams = <
    S extends QueryParamsSchema,
    D extends QueryParamsDefaults
>({
    defaults,
    schema
}: Options<S, D>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const queryParams = useMemo(() => {
        const parsedParams = parseSearchParams(searchParams, schema);
        const normalizedParams = buildQueryParamsWithoutEmpties(parsedParams);

        return {
            ...(defaults as D),
            ...normalizedParams
        };
    }, [defaults, searchParams, schema]);

    const handleQueryParamsChange = useCurrentFunction(
        (
            paramsChange: Partial<ParsedQueryParamsSchema<S>>,
            options?: NavigateOptions
        ) => {
            const mergedParams = {
                ...queryParams,
                ...paramsChange
            };

            const normalizedParams =
                buildQueryParamsWithoutEmpties(mergedParams);

            const nextParams = buildQueryParamsWithoutDefaults(
                normalizedParams,
                defaults as D
            );

            const nextSearchParams = serializeSearchParams(nextParams, schema);
            setSearchParams(nextSearchParams, options);
        }
    );

    return [queryParams, handleQueryParamsChange] as const;
};
