import { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${({ theme }) => theme.colors.validationError};
    margin-left: ${({ theme }) => theme.space[4]};
`;

interface Props {
    title: ReactNode;
    description?: ReactNode;
    children?: ReactNode;
}

export const ErrorTooltip = ({ title, children }: Props) => (
    <Tooltip title={title}>
        {children}
        <ErrorIcon />
    </Tooltip>
);
