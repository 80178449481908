import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Alert,
    AugmentedTableInterface,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import {
    CategorySetId,
    getCategorySets
} from '@olxeu-monetization/product-catalog-api-client';
import { AuthContext, ConfigContext } from '../../../context';
import { getCategorySetNames } from '../../../helpers';
import { Messages } from '../../../intl';
import { usePromise } from '../../../utils';
import { Section } from '../../Section';
import { buildDataSource } from './buildDataSource';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const DEFAULT_PAGE_SIZE = 10;

const ProductCategorySetsTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    categorySetIds: CategorySetId[];
    previousCategorySetIds?: CategorySetId[];
    diffVisible?: boolean;
}

export const ProductCategorySets = ({
    categorySetIds,
    previousCategorySetIds,
    diffVisible
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);

    const categorySetsMetadataResult = usePromise({
        variables: {
            limit: 0,
            offset: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetsResult = usePromise({
        variables: {
            ids: [
                ...categorySetIds,
                ...(previousCategorySetIds ? previousCategorySetIds : [])
            ],
            offset: pageNumber * pageSize
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetsLoading =
        categorySetsResult.loading && !categorySetsResult.data;
    const categorySetsMetadata = categorySetsMetadataResult.data?.metadata;
    const categorySets = categorySetsResult.data?.data;

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: categorySets?.flatMap((categorySet) =>
                categorySet.id ? [categorySet.id] : []
            )
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables, {
                        baseUrl: config.services.productCatalogService.baseUrl,
                        accessToken: await auth.getAccessToken()
                    });
                }
            });
        },
        disableAutostart: !categorySetsResult.data
    });

    const handleOnChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageNumber) {
                    setPageNumber(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    setPageSize(nextPageSize);
                }

                break;
            }
            default:
                return;
        }
    };

    const { dataSource, changesCount } = useMemo(() => {
        const categorySetNamesMap =
            categorySetNamesResult.data ?? new Map<CategorySetId, string>();

        return buildDataSource(
            categorySetIds,
            categorySetNamesMap,
            previousCategorySetIds
        );
    }, [categorySetNamesResult.data, categorySetIds, previousCategorySetIds]);

    const columns = buildTableColumns(intl);

    return (
        <Section
            title={formatMessage(Messages['common.label.category-sets'])}
            description={formatMessage(
                Messages['common.description.category-sets']
            )}
            tags={
                <>
                    {categorySetsMetadata && categorySetIds.length > 0 && (
                        <Tag color={theme.colors.tagCounter}>
                            {formatMessage(Messages['common.tag.selected-of'], {
                                selected: categorySetIds.length,
                                available: categorySetsMetadata.totalCount
                            })}
                        </Tag>
                    )}

                    {diffVisible && (
                        <Tag
                            color={theme.colors.tagChanges}
                            data-testid="product-diff-category-sets-changes-tag"
                        >
                            {formatMessage(Messages['common.tag.changes'], {
                                count: changesCount
                            })}
                        </Tag>
                    )}
                </>
            }
            data-testid="product-category-sets"
        >
            {dataSource.length > 0 ? (
                <ProductCategorySetsTable
                    columns={columns}
                    dataSource={dataSource}
                    loading={categorySetsLoading}
                    scroll={{ y: theme.sizesRaw.categoriesTableMaxHeight }}
                    onChange={handleOnChange}
                />
            ) : (
                <Alert
                    message={formatMessage(
                        Messages[
                            'common.info.all-categories-product-availability'
                        ]
                    )}
                    showIcon
                />
            )}
        </Section>
    );
};
