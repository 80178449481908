import { IntlShape } from 'react-intl';
import { ProductLabel } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { LABEL_BOOLEAN_ATTRIBUTE } from '../../../layouts/ProductFormLayout/LabelsFormSection/utils';
import { LabelMetadataValueType } from './LabelAttributesTable/columns';
import { LabelChangeType } from './ProductLabelDiffTab';

export const buildDataSource = (
    labels: ProductLabel[],
    previousLabels: ProductLabel[] | undefined,
    intl: IntlShape
) => {
    const labelsMap = new Map<string, ProductLabel>();

    labels?.forEach((label) => {
        labelsMap.set(label.templateId, label);
    });

    const previousLabelsMap = new Map<string, ProductLabel>();

    previousLabels?.forEach((label) => {
        previousLabelsMap.set(label.templateId, label);
    });

    const aggregatedLabels = [...labels];

    previousLabels?.forEach((label) => {
        if (!labelsMap.get(label.templateId)) {
            aggregatedLabels.push(label);
        }
    });

    const dataSource = aggregatedLabels.map((label) => {
        const currentLabel = labelsMap.get(label.templateId);
        const previousLabel = previousLabelsMap.get(label.templateId);

        const currentContains = currentLabel !== undefined;
        const previousContains = previousLabel !== undefined;

        const labelAdded = !previousContains && currentContains;
        const labelRemoved = previousContains && !currentContains;

        const changeType =
            previousLabels !== undefined && labelAdded
                ? LabelChangeType.Added
                : previousLabels !== undefined && labelRemoved
                ? LabelChangeType.Removed
                : LabelChangeType.None;

        const featureIdChanged =
            previousLabel !== undefined &&
            label.featureId !== previousLabel.featureId;

        const staticProperties = [
            {
                id: 'featureId',
                label: intl.formatMessage(
                    Messages['common.label.referenced-feature']
                ),
                value: label.featureId,
                previousValue: featureIdChanged
                    ? previousLabel?.featureId
                    : undefined
            }
        ];

        const values = label.values.map((item) => {
            const previousValue = previousLabel?.values.find(
                (previousItem) => previousItem.name === item.name
            )?.value;

            const valueChanged =
                previousLabel !== undefined &&
                previousLabel.values.some((previousItem) => {
                    return (
                        previousItem.name === item.name &&
                        previousItem.value !== item.value
                    );
                });

            return {
                key: item.name,
                value: item.value,
                previousValue: valueChanged ? previousValue : undefined,
                hasChanged: valueChanged
            };
        });

        const metadata = label.metadata.map((attribute) => {
            const type =
                attribute.name === LABEL_BOOLEAN_ATTRIBUTE
                    ? LabelMetadataValueType.Boolean
                    : LabelMetadataValueType.String;

            const attributeValueChanged =
                previousLabel !== undefined &&
                previousLabel.metadata.some((previousAttribute) => {
                    return (
                        previousAttribute.name === attribute.name &&
                        previousAttribute.value !== attribute.value
                    );
                });

            return {
                key: attribute.name,
                type,
                value: attribute.value,
                previousValue: attributeValueChanged
                    ? previousLabel?.metadata.find(
                          (previousAttribute) =>
                              previousAttribute.name === attribute.name
                      )?.value
                    : undefined,
                hasChanged: attributeValueChanged
            };
        });

        const changesCount =
            Number(featureIdChanged) +
            values.reduce((acc, item) => {
                return acc + Number(item.hasChanged);
            }, 0) +
            metadata.reduce((acc, attribute) => {
                return acc + Number(attribute.hasChanged);
            }, 0);

        return {
            templateId: label.templateId,
            staticProperties,
            values,
            metadata,
            changeType,
            changesCount
        };
    });

    const changesCount = dataSource.reduce((acc, root) => {
        return (
            acc +
            root.changesCount +
            Number(root.changeType !== LabelChangeType.None)
        );
    }, 0);

    return {
        dataSource,
        changesCount
    };
};
