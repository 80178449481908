import formatMessage from 'format-message';
import parse from 'format-message-parse';
import {
    ProductFeatureProperty,
    ProductLabelValue,
    TemplateTranslation,
    TranslationValue,
    TranslationValueType
} from '@olxeu-monetization/product-catalog-api-client';
import { Duration } from '../../../types';
import { FormListField } from '../FormList';
import { LabelMetadataValueType } from './LabelAttributesTable/columns';

formatMessage.setup({
    missingTranslation: 'ignore'
});

export const LABEL_BOOLEAN_ATTRIBUTE = 'disabled';

export const buildLabelValuesDataSource = (
    valuesFields: FormListField[],
    referencedFeatureProperties?: ProductFeatureProperty[]
) => {
    const hasSameKeyInReferencedFeature = (valueKey: string) => {
        if (!referencedFeatureProperties) {
            return false;
        }

        return referencedFeatureProperties.some(
            (featureProperty) => featureProperty.name === valueKey
        );
    };

    return valuesFields.map((valuesField) => {
        const name = valuesField.getValue('name');
        const value = valuesField.getValue('value');
        const type = valuesField.getValue('type') as TranslationValueType;

        const existsInReferencedFeature = hasSameKeyInReferencedFeature(name);

        return {
            key: name,
            name: valuesField.name,
            type,
            value,
            typeDisabled: !existsInReferencedFeature,
            inputDisabled: type === TranslationValueType.Reference
        };
    });
};

export const buildTranslationAttributesDataSource = (
    metadataFields: FormListField[]
) => {
    return metadataFields.map((metadataField) => {
        const type =
            metadataField.getValue('name') === LABEL_BOOLEAN_ATTRIBUTE
                ? LabelMetadataValueType.Boolean
                : LabelMetadataValueType.String;

        return {
            key: metadataField.getValue('name'),
            name: metadataField.name,
            value: metadataField.getValue('value'),
            type
        };
    });
};

export const getResolvedTranslations = (
    labelValues: ProductLabelValue[],
    translations?: TemplateTranslation[]
) => {
    return translations?.map((translation) => ({
        language: translation.language,
        value: formatMessage(
            { default: translation.value },
            {
                ...labelValues.reduce((acc, labelValue) => {
                    const durationFromStringValue = Duration.fromFormatted(
                        String(labelValue.value)
                    ).getDefined();

                    const value = isDuration(durationFromStringValue)
                        ? getNormalizedValue(durationFromStringValue)
                        : labelValue.value;

                    return {
                        ...acc,
                        [labelValue.name]: value ?? ''
                    };
                }, {})
            }
        )
    }));
};

export const getLabelValuesFromTranslation = (translation: string) => {
    const tokens: parse.Token[] = [];
    let ast: parse.AST | undefined = undefined;

    try {
        ast = parse(translation ?? '', { tokens });
    } catch (error) {
        throw new Error(`Could not parse translation: ${translation}`);
    }

    const variables = ast?.reduce<string[]>((acc, item) => {
        if (!Array.isArray(item)) {
            return acc;
        }

        const id = item[0];

        if (acc.some((element) => element === id)) {
            return acc;
        }

        acc.push(id);

        return acc;
    }, []);

    return variables;
};

export const resolveTranslation = (translation: TranslationValue) => {
    return translation;
};

const isDuration = (value: unknown) => value instanceof Duration;

const getDurationNumericValue = (duration: Duration) => {
    const durationString = duration.toFormatted();

    const regex = /(\d+)/g;
    const match = durationString.match(regex);

    if (!match) {
        return '';
    }

    if (match?.length > 1) {
        throw new Error(`Unsupported duration value: ${durationString}`);
    }

    return match[0];
};

export const getNormalizedValue = (value: unknown) =>
    isDuration(value)
        ? getDurationNumericValue(value as Duration)
        : String(value);

export const buildCombinedLabelValues = (
    referencedFeatureValues: ProductFeatureProperty[],
    labelValues: ProductLabelValue[]
) => {
    return referencedFeatureValues
        .map((item) => {
            const labelItem = labelValues.find(
                (label) => label.name === item.name
            );
            if (labelItem) {
                return {
                    name: labelItem.name,
                    value: getNormalizedValue(labelItem.value)
                };
            }
            return item;
        })
        .concat(
            labelValues
                .filter(
                    (label) =>
                        !referencedFeatureValues.some(
                            (item) => item.name === label.name
                        )
                )
                .map(({ type, ...rest }) => rest)
        );
};
