import { OfferChange } from '@olxeu-monetization/product-catalog-api-client';
import { buildLink, MarketId, Path } from '../../routing';
import { TableFilters } from './columns';

export const buildOfferChangesData = (
    offerChanges: OfferChange[],
    marketId: MarketId
) =>
    offerChanges.map((offerChange) => ({
        key: offerChange.id,
        name: offerChange.name,
        status: offerChange.status,
        lastAuthor: offerChange.lastAuthor,
        updatedAt: offerChange.updatedAt,
        detailsLink: buildLink(Path.OfferChangeDetails, {
            id: offerChange.id,
            marketId
        })
    }));

export const OfferChangeFiltersDefaultValues = {
    search: undefined,
    status: undefined
};

export const mapTableFiltersToFilters = (filters: TableFilters) => {
    return {
        search: filters.name?.[0]
    };
};
