import { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    Feature,
    FeatureId
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalTitle } from '../../components/ModalTitle';
import { ModalContext } from '../../context';
import { Messages } from '../../intl';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';
import { buildFeaturesDataSource, buildTargetOptions } from './utils';

const FeaturesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    features: Feature[];
    selectedIds: FeatureId[];
    onSelect?: (feature: Feature) => void;
}

export const FeatureSelectionModal = ({
    features,
    selectedIds,
    onSelect
}: Props) => {
    const { onClose } = useContext(ModalContext);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const handleSelect = useCallback(
        (id: FeatureId) => {
            const feature = features.find((feature) => feature.id === id);

            if (!feature) {
                throw new Error('Could not resolve feature by given id');
            }

            onSelect?.(feature);
            onClose();
        },
        [features, onSelect, onClose]
    );

    const columns = useMemo(() => {
        const targetOptions = buildTargetOptions(features);

        return buildTableColumns({
            targetOptions,
            intl,
            onSelect: handleSelect
        });
    }, [features, intl, handleSelect]);

    const dataSource = useMemo(() => {
        return buildFeaturesDataSource(features, selectedIds);
    }, [features, selectedIds]);

    return (
        <>
            <ModalTitle
                title={formatMessage(Messages['common.label.select-feature'])}
                tags={
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.available'], {
                            count: features.length
                        })}
                    </Tag>
                }
            />
            <FeaturesTable
                data-testid="select-feature-table"
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    pageSize: 10
                }}
            />
        </>
    );
};
