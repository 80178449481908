import {
    Category,
    CategorySetType
} from '@olxeu-monetization/product-catalog-api-client';
import { CategorySetsFormLayout } from '../../../sections/CategorySetsFormLayout';
import { FormList } from '../../ProductFormLayout/FormList';

interface Props {
    categories: Category[];
    categorySetsTotalCount: number;
}

export const CategorySetsFormSection = ({
    categories,
    categorySetsTotalCount
}: Props) => {
    return (
        <FormList name={['target', 'categorySets']}>
            {(categorySetFields, categorySetListOperation) => (
                <CategorySetsFormLayout
                    categorySetFields={categorySetFields}
                    categorySetListOperation={categorySetListOperation}
                    categorySetsTypes={[CategorySetType.Limit]}
                    categories={categories}
                    categorySetsTotalCount={categorySetsTotalCount}
                    categorySetsLimit={1}
                />
            )}
        </FormList>
    );
};
