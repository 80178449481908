import { RouteObject } from 'react-router-dom';

export enum RouteUserAccess {
    Any,
    Guest,
    Authenticated
}

export interface ApplicationRouteObject extends RouteObject {
    children?: ApplicationRouteObject[];
    userAccess: RouteUserAccess;
}
