import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { Messages } from '../../../intl';
import {
    ChangeTag,
    ProductEntryAdded,
    ProductEntryRemoved
} from '../../ProductEntryDiff';

export enum LabelChangeType {
    Added = 'ADDED',
    Removed = 'REMOVED',
    None = 'NONE'
}

interface Props {
    changeType: LabelChangeType;
    changesCount: number;
    children: ReactNode;
}

export const ProductLabelDiffTab = ({
    changeType,
    changesCount,
    children
}: Props) => {
    const theme = useTheme();

    switch (changeType) {
        case LabelChangeType.None:
            return (
                <>
                    <span data-testid="product-diff-labels-current-item">
                        {children}
                    </span>
                    {changesCount > 0 && (
                        <ChangeTag color={theme.colors.tagChanges} ml={8}>
                            {changesCount}
                        </ChangeTag>
                    )}
                </>
            );
        case LabelChangeType.Added:
            return (
                <ProductEntryAdded>
                    <span data-testid="product-diff-labels-added-item">
                        {children}
                    </span>
                    <ChangeTag color={theme.colors.green} m={0} ml={8}>
                        <FormattedMessage {...Messages['common.tag.added']} />
                    </ChangeTag>
                </ProductEntryAdded>
            );
        case LabelChangeType.Removed:
            return (
                <ProductEntryRemoved>
                    <span data-testid="product-diff-labels-removed-item">
                        {children}
                    </span>
                    <ChangeTag color={theme.colors.pink} m={0} ml={8}>
                        <FormattedMessage {...Messages['common.tag.removed']} />
                    </ChangeTag>
                </ProductEntryRemoved>
            );
    }
};
