import { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    AugmentedTableInterface,
    Button,
    CheckboxProps,
    Popconfirm,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import {
    CategorySetId,
    Feature,
    OfferChangeId,
    OfferChangeProduct,
    ProductId,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { ActionsBar } from '../../components/ActionsBar';
import { Section } from '../../components/Section';
import { ModalContext } from '../../context';
import { Messages } from '../../intl';
import { ActionType } from '../../layouts/ProductListLayout/columns';
import {
    buildFeatureOptions,
    buildTypeOptions
} from '../../layouts/ProductListLayout/utils';
import { ProductSelectionModal } from '../../modals/ProductSelectionModal';
import { buildLink, MarketId, Path } from '../../routing';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import {
    buildOfferChangeProductsDataSource,
    mapTableFiltersToFilters
} from './utils';

const OfferChangeProductsTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    marketId: MarketId;
    offerChangeId: OfferChangeId;
    offerChangeProducts: OfferChangeProduct[];
    productsTotalCount: number;
    categorySetNamesMap: Map<CategorySetId, string>;
    features: Feature[];
    productTypes: ProductType[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onFiltersChange?: (filters: Filters) => void;
    onProductsRemove?: (ids: ProductId[]) => void;
}

export const DraftOfferChangeProducts = ({
    marketId,
    offerChangeId,
    offerChangeProducts,
    productsTotalCount,
    categorySetNamesMap,
    features,
    productTypes,
    pageIndex,
    pageSize,
    filters,
    onPageIndexChange,
    onPageSizeChange,
    onFiltersChange,
    onProductsRemove
}: Props) => {
    const modal = useContext(ModalContext);
    const [selectedProducts, setSelectedProducts] = useState<ProductId[]>([]);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const featureOptions = useMemo(() => {
        return buildFeatureOptions(features);
    }, [features]);

    const typeOptions = useMemo(() => {
        return buildTypeOptions(productTypes);
    }, [productTypes]);

    const dataSource = useMemo(() => {
        return buildOfferChangeProductsDataSource(
            marketId,
            offerChangeId,
            offerChangeProducts,
            categorySetNamesMap
        );
    }, [offerChangeId, offerChangeProducts, categorySetNamesMap, marketId]);

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    filters,
                    featureOptions
                );

                onFiltersChange?.(nextFilters);
                break;
            }
            default:
                return;
        }
    };

    const handleEditExistingProduct = () => {
        modal.show(
            <ProductSelectionModal
                marketId={marketId}
                features={features}
                productTypes={productTypes}
                actionOptions={{
                    type: ActionType.Edit,
                    offerChangeId
                }}
            />,
            {
                footer: null,
                width: 'auto',
                centered: true
            }
        );
    };

    const columns = useMemo(() => {
        const renderExtraActions = (productId: ProductId) => (
            <>
                <Link
                    to={buildLink(Path.OfferChangeProductEdit, {
                        id: offerChangeId,
                        productId,
                        marketId
                    })}
                >
                    <Button size="small">
                        <FormattedMessage {...Messages['common.button.edit']} />
                    </Button>
                </Link>
                <Popconfirm
                    title={
                        <FormattedMessage
                            {...Messages[
                                'common.prompt-message.remove-selected-product-confirm'
                            ]}
                        />
                    }
                    okText={
                        <FormattedMessage {...Messages['common.label.yes']} />
                    }
                    cancelText={
                        <FormattedMessage {...Messages['common.label.no']} />
                    }
                    onConfirm={() => onProductsRemove?.([productId])}
                >
                    <Button size="small" danger>
                        <FormattedMessage
                            {...Messages['common.button.remove']}
                        />
                    </Button>
                </Popconfirm>
            </>
        );

        return buildTableColumns({
            featureOptions,
            typeOptions,
            filters,
            intl,
            renderExtraActions
        });
    }, [
        featureOptions,
        typeOptions,
        filters,
        intl,
        offerChangeId,
        onProductsRemove,
        marketId
    ]);

    return (
        <Section
            title={formatMessage(Messages['common.label.changed-products'])}
            tags={
                <Tag color={theme.colors.tagCounter}>
                    {formatMessage(Messages['common.tag.changed-of'], {
                        changed: offerChangeProducts.length,
                        available: productsTotalCount
                    })}
                </Tag>
            }
            extra={[
                <Link
                    key="create-product"
                    to={buildLink(Path.OfferChangeProductCreate, {
                        id: offerChangeId,
                        marketId
                    })}
                >
                    <Button icon={<PlusOutlined />}>
                        {formatMessage(
                            Messages['common.button.create-new-product']
                        )}
                    </Button>
                </Link>,
                <Button
                    key="edit-product"
                    icon={<EditOutlined />}
                    onClick={handleEditExistingProduct}
                >
                    {formatMessage(
                        Messages['common.button.edit-existing-product']
                    )}
                </Button>
            ]}
            data-testid="offer-change-products"
        >
            <OfferChangeProductsTable
                data-testid="draft-offer-change-products-table"
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedProducts,
                    getCheckboxProps: (record: TableDataSource) => {
                        return {
                            'data-cy': `product-checkbox[${record.key}]`
                        } as CheckboxProps;
                    },
                    onChange: (selectedProducts) =>
                        setSelectedProducts(selectedProducts as ProductId[])
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    total: productsTotalCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
            <ActionsBar mt={16}>
                <Popconfirm
                    title={formatMessage(
                        Messages[
                            'common.prompt-message.remove-selected-products-confirm'
                        ],
                        {
                            count: selectedProducts.length
                        }
                    )}
                    okText={formatMessage(Messages['common.label.yes'])}
                    cancelText={formatMessage(Messages['common.label.no'])}
                    onConfirm={() => {
                        onProductsRemove?.(selectedProducts);
                        setSelectedProducts([]);
                    }}
                    disabled={selectedProducts.length === 0}
                >
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        disabled={selectedProducts.length === 0}
                    >
                        {formatMessage(
                            Messages['common.button.remove-selected'],
                            {
                                count: selectedProducts.length
                            }
                        )}
                    </Button>
                </Popconfirm>
            </ActionsBar>
        </Section>
    );
};
