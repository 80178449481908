import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const InfoIcon = styled(InfoCircleOutlined)<SpaceProps>`
    color: ${({ theme }) => theme.colors.black30p};
    ${space}
`;

interface Props {
    title: string;
    description?: string;
}

export const PropertyLabel = ({ title, description }: Props) => (
    <>
        {title}
        <Tooltip title={description}>
            <InfoIcon ml={8} />
        </Tooltip>
    </>
);
