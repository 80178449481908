import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { List, Tabs, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    MarketLanguage,
    ProductLocalization
} from '@olxeu-monetization/product-catalog-api-client';
import { ConfigContext } from '../../../context';
import { Messages } from '../../../intl';
import { getLanguageName } from '../../../utils';
import { ChangeTag } from '../../ProductEntryDiff';
import { Section } from '../../Section';
import { buildDataSource } from './buildDataSource';
import { ProductLocaleDiff } from './ProductLocaleDiff';

interface ReadonlyProductProps {
    localizations: ProductLocalization[];
    previousLocalizations?: undefined;
    diffVisible?: undefined;
    marketLanguages: MarketLanguage[];
}

interface DiffProductProps {
    localizations: ProductLocalization[];
    previousLocalizations: ProductLocalization[];
    diffVisible?: boolean;
    marketLanguages: MarketLanguage[];
}

type Props = ReadonlyProductProps | DiffProductProps;

export const ProductLocales = ({
    localizations,
    previousLocalizations,
    diffVisible,
    marketLanguages
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const config = useContext(ConfigContext);

    const { dataSource, changesCount } = useMemo(() => {
        return buildDataSource(
            localizations,
            diffVisible ? previousLocalizations : undefined,
            intl,
            marketLanguages
        );
    }, [
        localizations,
        previousLocalizations,
        diffVisible,
        intl,
        marketLanguages
    ]);

    const localizationTabs = dataSource.map((localization) => ({
        label: (
            <span data-cy={`product-locales-tab[${localization.languageCode}]`}>
                {getLanguageName(localization.languageCode, config.intl.locale)}
                {localization.changesCount > 0 && (
                    <ChangeTag color={theme.colors.tagChanges} ml={8}>
                        {localization.changesCount}
                    </ChangeTag>
                )}
            </span>
        ),
        key: localization.languageCode,
        children: (
            <List
                itemLayout="horizontal"
                dataSource={localization.entries}
                renderItem={(entry) => (
                    <List.Item>
                        <List.Item.Meta
                            title={entry.label}
                            description={
                                entry.previousValue ? (
                                    <ProductLocaleDiff
                                        id={entry.id}
                                        value={entry.value}
                                        previousValue={entry.previousValue}
                                    />
                                ) : (
                                    <span
                                        data-testid={`product-diff-locales-current-value[${entry.id}]`}
                                    >
                                        {entry.value}
                                    </span>
                                )
                            }
                        />
                    </List.Item>
                )}
            />
        )
    }));

    return (
        <Section
            title={formatMessage(Messages['common.label.locales'])}
            description={formatMessage(Messages['common.description.locales'])}
            tags={
                diffVisible ? (
                    <Tag
                        color={theme.colors.tagChanges}
                        data-testid="product-diff-locales-changes-tag"
                    >
                        {formatMessage(Messages['common.tag.changes'], {
                            count: changesCount
                        })}
                    </Tag>
                ) : undefined
            }
            data-testid="product-locales"
        >
            <Tabs type="card" items={localizationTabs} />
        </Section>
    );
};
