import { Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => `
        width: ${theme.sizes.filterDropdown};
        padding: ${theme.space[8]};
    `};
`;

export const Buttons = styled.div`
    margin-top: ${({ theme }) => theme.space[8]};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TreeSearch = styled(Input.Search)`
    margin-bottom: ${({ theme }) => theme.space[8]};
`;

export const TreeRowCounter = styled.span`
    ${({ theme }) => `
        background: ${theme.colors.black10p};
        padding: ${theme.space[2]} ${theme.space[8]};
        border-radius: ${theme.radii[8]};
        font-size: ${theme.fontSizes[12]};
        margin-left: ${theme.space[8]};
    `}
`;
