import {
    Category,
    CategorySet,
    MarketLanguage
} from '@olxeu-monetization/product-catalog-api-client';
import {
    buildTree,
    mapTree,
    omitNodesWithSelectedAncestors
} from '../../utils';
import { CategoryTreeNode } from './CategoriesFormInput';
import { CategorySetFormPayload, CategorySetFormValues } from './types';

export const mapCategorySetToFormValues = (
    marketLanguages: MarketLanguage[],
    categorySet?: CategorySet
): CategorySetFormValues => {
    return {
        name: categorySet?.name,
        type: categorySet?.type,
        categoryIds: categorySet?.categoryIds ?? [],
        localizations: marketLanguages.map((language) => {
            return {
                languageCode: language.code,
                name: categorySet?.localizations?.find(
                    (localization) =>
                        localization.languageCode === language.code
                )?.name
            };
        })
    };
};

export const mapFormValuesToFormPayload = (
    values: CategorySetFormValues,
    categoryTree: CategoryTreeNode[]
): CategorySetFormPayload => {
    if (values.name === undefined) {
        throw new Error('Invalid input');
    }

    return {
        name: values.name,
        type: values.type,
        categoryIds: omitNodesWithSelectedAncestors(
            categoryTree,
            values.categoryIds ?? [],
            'value'
        ),
        localizations: values.localizations ?? []
    };
};

const DefaultRootCategoryId = 0;

export const buildCategoryTree = (
    categories: Category[],
    rootCategoryId = DefaultRootCategoryId
): CategoryTreeNode[] => {
    const mappedCategories = categories.map((category) => ({
        children: [],
        text: category.name,
        value: category.id,
        parentId: category.parentId
    }));

    const tree = buildTree(
        mappedCategories,
        'value',
        'parentId',
        rootCategoryId
    );

    return mapTree(tree, (node, nextChildren, parent) => {
        return {
            ...node,
            children: nextChildren.length > 0 ? nextChildren : undefined
        };
    });
};
