import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import {
    createTemplate,
    CreateTemplateInput,
    CreateTemplateOutput,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    AuthContext,
    ConfigContext,
    MarketLanguagesDataContext
} from '../../context';
import { Messages } from '../../intl';
import { TemplateFormLayout } from '../../layouts/TemplateFormLayout';
import { TemplateFormPayload } from '../../layouts/TemplateFormLayout/types';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../TemplateListPage';

type Params = PathParams[Path.TemplateCreate];

const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.TemplateCreate, {
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.create-template']
            )
        }
    ];
};

export const TemplateCreatePage = () => {
    const intl = useIntl();
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const navigate = useNavigate();
    const params = useParams<Params>();

    const handleTemplateCreateComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<CreateTemplateOutput, unknown>
        >
    ) => {
        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: intl.formatMessage(
                    Messages['common.error-message.template-create']
                )
            });

            return;
        }

        void message.success(
            intl.formatMessage(
                Messages['common.success-message.template-create']
            )
        );

        const detailsLink = buildLink(Path.TemplateDetails, {
            id: completionParams.data?.data?.id as string,
            marketId: params.marketId
        });

        navigate(detailsLink);
    };

    const templateCreate = usePromise({
        variables: undefined as unknown as CreateTemplateInput,
        promiseBuilder: async (variables) => {
            return createTemplate(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true,
        onComplete: handleTemplateCreateComplete
    });

    if (marketLanguagesData.loading) {
        return <Loader type="center" />;
    }

    const languages = marketLanguagesData.data;

    if (!languages) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handleSubmit = (template: TemplateFormPayload) => {
        void templateCreate.execute(template);
    };

    return (
        <TemplateFormLayout
            title={intl.formatMessage(Messages['common.label.create-template'])}
            breadcrumbRoutes={breadcrumbRoutes}
            languages={languages.available}
            defaultLanguage={languages.default}
            onSubmit={handleSubmit}
            submitDisabled={templateCreate.loading}
        />
    );
};
