import { ReactNode } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType } from 'antd';
import { CategorySetId } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { normalizeText } from '../../../utils';
import {
    CategorySetChangeType,
    ProductCategorySetDiffCell,
    renderSearchIcon,
    SearchFilter
} from '../../Table';

export interface Filters {
    search?: string;
}

export type TableDataSource = {
    id: CategorySetId;
    name: ReactNode;
    changeType: CategorySetChangeType;
    changesCount: number;
};

export type TableFilters = {
    name: string[] | null;
};

export const buildTableColumns = (
    intl: IntlShape
): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            render: (data, record) => (
                <ProductCategorySetDiffCell
                    changeType={record.changeType}
                    changesCount={record.changesCount}
                >
                    {data}
                </ProductCategorySetDiffCell>
            ),
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            onFilter: (value, record) => {
                if (typeof record.name !== 'string') {
                    return false;
                }

                return normalizeText(record.name).includes(
                    normalizeText(String(value))
                );
            },

            filterIcon: renderSearchIcon
        }
    ];
};
