import { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Form, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { MarketLanguage } from '@olxeu-monetization/product-catalog-api-client';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { Section } from '../../components/Section';
import { ConfigContext } from '../../context';
import { Messages } from '../../intl';
import { getLanguageName, useChangeObserver } from '../../utils';
import { FormList } from '../ProductFormLayout/FormList';
import {
    getNextErrorTabIndex,
    getSectionErrors
} from '../ProductFormLayout/helpers';

const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${({ theme }) => theme.colors.validationError};
    margin-left: ${({ theme }) => theme.space[8]};
`;

interface Props {
    marketLanguages: MarketLanguage[];
}

export const LocalizationsFormSection = ({ marketLanguages }: Props) => {
    const [activeKey, setActiveKey] = useState(0);
    const { formatMessage } = useIntl();
    const config = useContext(ConfigContext);
    const form = Form.useFormInstance();

    const localizationErrors = getSectionErrors(form, 'localizations');

    const shouldDisplayErrorForLanguage = useCallback(
        (langIndex) =>
            localizationErrors.some((error) => error.name[1] === langIndex),
        [localizationErrors]
    );

    useChangeObserver(
        ([previousLocalizationErrors]) => {
            setActiveKey((currentTabKey) => {
                return getNextErrorTabIndex(
                    previousLocalizationErrors,
                    localizationErrors,
                    currentTabKey
                );
            });
        },
        [localizationErrors]
    );

    return (
        <Section
            title={formatMessage(Messages['common.label.locales'])}
            data-testid="category-set-locales"
        >
            <FormList name="localizations">
                {(fields) => {
                    const localizationTabs = fields.map((field) => {
                        return {
                            label: (
                                <span
                                    data-cy={`category-set-locales-tab[${field.getValue(
                                        'languageCode'
                                    )}]`}
                                >
                                    {getLanguageName(
                                        field.getValue('languageCode'),
                                        config.intl.locale
                                    )}
                                    {shouldDisplayErrorForLanguage(
                                        field.getValue('languageCode')
                                    ) && <ErrorIcon />}
                                </span>
                            ),
                            key: String(field.key),
                            forceRender: true,
                            children: (
                                <Col
                                    span={8}
                                    data-cy={`category-set-locales-tab-content[${field.getValue(
                                        'languageCode'
                                    )}]`}
                                >
                                    <FormInput
                                        name={[field.name, 'name']}
                                        label={formatMessage(
                                            Messages['common.label.name']
                                        )}
                                        required={true}
                                        type={FormFieldType.Input}
                                    />
                                </Col>
                            )
                        };
                    });

                    return (
                        <Tabs
                            type="card"
                            activeKey={String(activeKey)}
                            onChange={(key) => {
                                setActiveKey(Number(key));
                            }}
                            items={localizationTabs}
                            className="tabs-with-validation-icon"
                        />
                    );
                }}
            </FormList>
        </Section>
    );
};
