import { ConfigContextType } from '../context';
import { getFeatureFlags } from '../featureFlags';
import { buildOktaMarketConfigs } from '../providers';
import { Path } from '../routing';

export const Config: ConfigContextType = {
    services: {
        productCatalogService: {
            baseUrl: process.env.PRODUCT_CATALOG_SERVICE_BASE_URL
        },
        upsService: {
            baseUrl: process.env.UPS_SERVICE_BASE_URL
        },
        oktaAuthService: {
            marketConfigs: buildOktaMarketConfigs(
                process.env.OKTA_AUTH_SERVICE_CLIENT_IDS
            ),
            issuer: process.env.OKTA_AUTH_SERVICE_ISSUER,
            redirectUri: `${window.location.origin}${Path.SignInCallback}`,
            scopes: ['openid', 'profile', 'email', 'groups'],
            pkce: true
        }
    },
    application: {
        environment: process.env.APP_ENV,
        featureFlags: getFeatureFlags(process.env.APP_ENV)
    },
    intl: {
        locale: window.navigator.language
    }
};
