import { createContext, ReactNode } from 'react';
import { ModalProps } from 'antd';

export interface ModalContextType {
    show: (content: ReactNode, props?: Partial<ModalProps>) => void;
    onClose: () => void;
}

const DefaultModalContext: ModalContextType = {
    show: () => {
        throw new Error('Unimplemented');
    },
    onClose: () => {
        throw new Error('Unimplemented');
    }
};

export const ModalContext =
    createContext<ModalContextType>(DefaultModalContext);
