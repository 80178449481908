import { MarketLanguage } from '@olxeu-monetization/product-catalog-api-client';
import {
    CategorySetId,
    Limit,
    LimitStatus,
    LimitType,
    UnlimitedOption
} from '@olxeu-monetization/user-product-service-api-client';
import { Duration } from '../../types';
import {
    LimitFormPayload,
    LimitFormValues,
    LimitTypesFormValues,
    limitTypesSet,
    PartialCategorySet
} from './types';

export const mapFormValuesToFormPayload = (
    formValues: LimitFormValues,
    categorySetOverride?: PartialCategorySet
): LimitFormPayload[] => {
    const limitCategorySet =
        categorySetOverride || formValues.target.categorySets[0];

    const labelsMap =
        limitCategorySet.localizations?.reduce(
            (acc, localization) => ({
                ...acc,
                [localization.languageCode.slice(0, 2)]: localization.name
            }),
            {}
        ) ?? {};

    const payload = limitTypesSet
        .map((limitType) => {
            return (
                formValues[limitType] && {
                    limit_type: formValues[limitType]?.type,
                    categories_configuration: limitCategorySet.categoryIds.map(
                        (categoryId) => categoryId.toString()
                    ),
                    capacity: formValues[limitType]?.capacity,
                    validity_period:
                        formValues[limitType]?.validity?.toFormatted(),
                    labels: { name: labelsMap },
                    status: formValues[limitType]?.status
                        ? LimitStatus.Active
                        : LimitStatus.Disabled,
                    product_category_set_id: limitCategorySet.id,
                    options: formValues[limitType]?.unlimited
                        ? [UnlimitedOption]
                        : []
                }
            );
        })
        .filter((item) => Boolean(item));

    return payload;
};

export const mapFormDataToFormValues = (
    limitData: Limit[],
    categorySetNamesMap: Map<CategorySetId, string> | undefined,
    marketLanguages: MarketLanguage[]
): LimitFormValues => {
    const categorySetName = String(
        categorySetNamesMap?.get(limitData[0].product_category_set_id)
    );

    const defaultLimit = limitData.find(
        (limit) => limit.limit_type === LimitType.Default
    ) as Limit;

    const getLimitTypesFormValues = () => {
        const limits = {};

        limitData.forEach((limit) => {
            Object.assign(limits, {
                [limit.limit_type]: {
                    id: limit.id,
                    type: limit.limit_type,
                    capacity: limit.capacity,
                    validity:
                        limit.validity_period === 'PT0S'
                            ? undefined
                            : Duration.fromFormatted(limit.validity_period),
                    status: limit.status === LimitStatus.Active,
                    unlimited: limit.options.some(
                        (option) => option === UnlimitedOption
                    )
                }
            });
        });

        return limits;
    };
    const limitTypesFormValues =
        getLimitTypesFormValues() as LimitTypesFormValues;

    const localizationsMap = marketLanguages.map((language) => {
        const shortLangCode = language.code.slice(0, 2);

        return {
            languageCode: language.code,
            name: defaultLimit.labels.name[
                shortLangCode as keyof typeof defaultLimit.labels.name
            ] as string
        };
    });

    return {
        target: {
            categorySets: [
                {
                    id: limitData[0].product_category_set_id,
                    name: categorySetName,
                    categoryIds: limitData[0].categories_configuration.map(
                        (categoryId) => Number(categoryId)
                    ),
                    localizations: localizationsMap
                }
            ]
        },
        ...limitTypesFormValues
    };
};
