import { useContext } from 'react';
import {
    createLimit,
    CreateLimitInput,
    getLimit,
    GetLimitInput,
    getLimits,
    GetLimitsInput,
    updateLimit,
    UpdateLimitInput
} from '@olxeu-monetization/user-product-service-api-client';
import { AuthContext, ConfigContext } from '../context';

export const useUpsService = () => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);

    const buildContext = async () => {
        return {
            baseUrl: config.services.upsService.baseUrl,
            accessToken: await auth.getAccessToken()
        };
    };

    return {
        getLimits: async (input: GetLimitsInput) => {
            const context = await buildContext();
            return getLimits(input, context);
        },
        getLimit: async (input: GetLimitInput) => {
            const context = await buildContext();
            return getLimit(input, context);
        },
        updateLimit: async (input: UpdateLimitInput[]) => {
            const context = await buildContext();
            return updateLimit(input, context);
        },
        createLimit: async (input: CreateLimitInput[]) => {
            const context = await buildContext();
            return createLimit(input, context);
        }
    };
};
