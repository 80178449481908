import { useCallback, useContext, useMemo } from 'react';
import { ConfigContext } from '../../context';
import { FeatureFlag } from '../../featureFlags/types';

export const useFeatureFlag = <T = FeatureFlag>() => {
    const config = useContext(ConfigContext);
    const featureFlags = config.application.featureFlags as unknown as T[];

    const appFeatureFlags = useMemo(() => {
        return new Set(featureFlags);
    }, [featureFlags]);

    const isFeatureFlag = useCallback(
        (flag: T) => {
            return appFeatureFlags.has(flag);
        },
        [appFeatureFlags]
    );

    return { isFeatureFlag };
};
