import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const BottomBarWrapper = styled.nav<SpaceProps>`
    ${({ theme }) => `
        border-top: ${theme.borders.black10p1};
        background: ${theme.colors.white90p};
    `};
    display: flex;
    justify-content: flex-end;
    ${space}
`;
