import { RouteObject } from 'react-router-dom';
import { ApplicationRouteObject, RouteUserAccess } from './types';

const isRouteAvailable = (
    authenticated: boolean,
    userAccess: RouteUserAccess
) => {
    switch (userAccess) {
        case RouteUserAccess.Any:
            return true;
        case RouteUserAccess.Authenticated:
            return authenticated;
        case RouteUserAccess.Guest:
            return !authenticated;
    }
};

export const buildRoutes = (
    routes: ApplicationRouteObject[],
    authenticated: boolean
) => {
    return routes.reduce<RouteObject[]>((acc, route) => {
        const { userAccess, ...routeBase } = route;
        const available = isRouteAvailable(authenticated, userAccess);

        if (available) {
            const routeChildren = routeBase.children ?? [];
            const children = buildRoutes(routeChildren, authenticated);

            const resolvedRoute = {
                ...routeBase,
                children
            };

            acc.push(resolvedRoute);
        }

        return acc;
    }, []);
};
