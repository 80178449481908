import { ReactNode } from 'react';
import { Typography } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { DataNode } from 'antd/lib/tree';
import { TreeRowCounter } from '../../components/Table/TreeSelectFilter/TreeSelectFilter.styled';
import { normalizeText } from '../normalizeText';
import { mapTree, Tree } from './treeUtils';

const mapValueToKey = (value: string | number | boolean) => {
    if (typeof value === 'boolean') {
        return 0;
    }

    return value;
};

export type MappedColumnFilterItem<T> = Omit<DataNode, 'children'> &
    T & {
        subtreeMatchesCount: number;
        children?: MappedColumnFilterItem<T>[];
    };

interface RenderProps {
    title: ReactNode;
    selection?: {
        startIndex: number;
        endIndex: number;
    };
    subtreeMatchesCount: number;
}

const defaultRenderTitle = ({
    title,
    selection,
    subtreeMatchesCount
}: RenderProps) => {
    const count = subtreeMatchesCount - (selection ? 1 : 0);

    return selection && typeof title === 'string' ? (
        <span>
            {title.substring(0, selection.startIndex)}
            <Typography.Text mark>
                {title.substring(selection.startIndex, selection.endIndex)}
            </Typography.Text>
            {title.substring(selection.endIndex)}
            {count > 0 && (
                <TreeRowCounter data-testid="tree-row-counter">
                    {count}
                </TreeRowCounter>
            )}
        </span>
    ) : (
        <>
            <span>{title}</span>
            {count > 0 && (
                <TreeRowCounter data-testid="tree-row-counter">
                    {count}
                </TreeRowCounter>
            )}
        </>
    );
};

interface GetHighlightedDataOptions<T extends ColumnFilterItem> {
    roots: Tree<T>[];
    query: string;
    renderTitle?: (props: RenderProps) => JSX.Element | string;
}

export const getHighlightedData = <T extends ColumnFilterItem>({
    roots,
    query,
    renderTitle = defaultRenderTitle
}: GetHighlightedDataOptions<T>) => {
    const normalizedQuery = normalizeText(query);

    return mapTree<
        T,
        MappedColumnFilterItem<Omit<T, 'children' | 'text' | 'value'>>
    >(roots, (node, nextChildren) => {
        const { text, value, children, ...rest } = node;

        if (normalizedQuery.length === 0 || typeof text !== 'string') {
            const title = renderTitle({
                title: text,
                selection: undefined,
                subtreeMatchesCount: 0
            });

            return {
                ...rest,
                title,
                key: mapValueToKey(value),
                children: nextChildren.length > 0 ? nextChildren : undefined,
                subtreeMatchesCount: 0
            };
        }

        const normalizedTitle = normalizeText(text);
        const index = normalizedTitle.indexOf(normalizedQuery);
        const matches = index >= 0;

        if (!matches && nextChildren.length === 0) {
            return undefined;
        }

        const matchingDescendantsCount = nextChildren.reduce((acc, child) => {
            return acc + child.subtreeMatchesCount;
        }, 0);

        const subtreeMatchesCount =
            matchingDescendantsCount + (matches ? 1 : 0);

        const title = renderTitle({
            title: text,
            selection: matches
                ? {
                      startIndex: index,
                      endIndex: index + normalizedQuery.length
                  }
                : undefined,
            subtreeMatchesCount
        });

        return {
            ...rest,
            title,
            key: mapValueToKey(value),
            children: nextChildren.length > 0 ? nextChildren : undefined,
            subtreeMatchesCount
        };
    });
};
