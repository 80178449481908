export enum TimeInterval {
    Second = 1,
    Minute = TimeInterval.Second * 60,
    Hour = TimeInterval.Minute * 60,
    Day = TimeInterval.Hour * 24,
    Week = TimeInterval.Day * 7,
    Month = TimeInterval.Day * 30,
    Year = TimeInterval.Day * 365
}

export interface DurationJson {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
}

export class Duration {
    static zero = Duration.fromFormatted('PT0S');

    private static buildParser() {
        const mapSegments = (segments: string[]) => {
            const mapped = segments.map((symbol) => {
                return `(?:((?:\\d*(?:,|\\.))?\\d+)${symbol})?`;
            });

            return mapped.join('');
        };

        const dateSegments = mapSegments(['y', 'm', 'd']);
        const timeSegments = mapSegments(['h', 'm', 's']);

        const regexpSource = `P${dateSegments}(?:T${timeSegments})?`;

        return new RegExp(regexpSource, 'i');
    }

    private constructor(private _data: DurationJson) {}

    static fromJson(json: DurationJson) {
        return new Duration(json);
    }

    static fromFormatted(formatted: string) {
        const parser = Duration.buildParser();
        const regexpResults = parser.exec(formatted) ?? [];
        const numericResults = regexpResults.map((segment) => {
            return segment !== undefined ? Number(segment) : undefined;
        });

        const [, years, months, days, hours, minutes, seconds] = numericResults;

        return new Duration({
            years,
            months,
            days,
            hours,
            minutes,
            seconds
        });
    }

    toJson(): DurationJson {
        return this._data;
    }

    toFormatted() {
        const segments: string[] = [];

        const push = (
            segment: number | string | undefined,
            unit: string,
            force?: boolean
        ) => {
            if (segment || force) {
                segments.push(`${segment ?? 0}${unit}`);
            }
        };

        const hasDate = Boolean(
            this._data.years || this._data.months || this._data.days
        );
        const hasTime = Boolean(
            this._data.hours || this._data.minutes || this._data.seconds
        );
        const hasAny = hasDate || hasTime;

        push('', 'P', true);
        push(this._data.years, 'Y');
        push(this._data.months, 'M');
        push(this._data.days, 'D');
        push('', 'T', hasTime || !hasAny);
        push(this._data.hours, 'H');
        push(this._data.minutes, 'M');
        push(this._data.seconds, 'S', !hasAny);

        return segments.join('');
    }

    // TODO: support localization and plurals
    // Coming soon in `Temporal` proposal:
    // https://tc39.es/proposal-temporal/docs/duration.html
    toHumanized() {
        const segments: string[] = [];

        const push = (
            segment: number | undefined,
            unit: string,
            force?: boolean
        ) => {
            if (segment || force) {
                segments.push(`${segment ?? 0} ${unit}`);
            }
        };

        push(this._data.years, 'year(s)');
        push(this._data.months, 'month(s)');
        push(this._data.days, 'day(s)');
        push(this._data.hours, 'hour(s)');
        push(this._data.minutes, 'minute(s)');
        push(
            this._data.seconds,
            'second(s)',
            this.isUndefined() || this.isZero()
        );

        return segments.join(', ');
    }

    private isUndefined() {
        return Object.values(this._data).every((value) => value === undefined);
    }

    getDefined() {
        if (this.isUndefined()) {
            return undefined;
        }

        return this;
    }

    valueOf() {
        let seconds = 0;

        seconds += (this._data.years ?? 0) * TimeInterval.Year;
        seconds += (this._data.months ?? 0) * TimeInterval.Month;
        seconds += (this._data.days ?? 0) * TimeInterval.Day;
        seconds += (this._data.hours ?? 0) * TimeInterval.Hour;
        seconds += (this._data.minutes ?? 0) * TimeInterval.Minute;
        seconds += (this._data.seconds ?? 0) * TimeInterval.Second;

        return seconds;
    }

    isZero() {
        return this.getDefined()?.valueOf() === 0;
    }
}
