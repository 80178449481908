import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: ${({ theme }) => theme.space[8]};
`;

export const Buttons = styled.div`
    margin-top: ${({ theme }) => theme.space[8]};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
