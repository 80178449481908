import { useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Drawer,
    Form,
    Input,
    InputRef,
    message,
    notification,
    Space
} from 'antd';
import {
    cancelOfferChange,
    CancelOfferChangeInput,
    createOfferChange,
    CreateOfferChangeInput,
    CreateOfferChangeOutput,
    ProductId,
    ResultEnvelope,
    updateOfferChangeProduct,
    UpdateOfferChangeProductInput,
    UpdateOfferChangeProductOutput
} from '@olxeu-monetization/product-catalog-api-client';
import { AuthContext, ConfigContext } from '../../context';
import { Messages } from '../../intl';
import { ProductFormPayload } from '../../layouts/ProductFormLayout';
import { buildLink, MarketId, Path } from '../../routing';
import { CompletionParams, errorNotification, usePromise } from '../../utils';

type PostOfferChangeFormData = {
    name: string;
};

interface Props {
    marketId: MarketId;
    editedProductId: ProductId;
    editedProduct: ProductFormPayload;
    visible: boolean;
    onClose?: () => void;
}

export const OfferChangeCreationDrawer = ({
    marketId,
    editedProductId,
    editedProduct,
    visible,
    onClose
}: Props) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const inputRef = useRef<InputRef>(null);
    const [updatedOfferChangeId, setUpdatedOfferChangeId] = useState<string>();

    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);

    const offerChangeCancel = usePromise({
        variables: undefined as unknown as CancelOfferChangeInput,
        promiseBuilder: async (variables) => {
            return cancelOfferChange(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true
    });

    const removeCreatedOfferChange = async () => {
        if (!updatedOfferChangeId) {
            return;
        }

        await offerChangeCancel.execute({
            id: updatedOfferChangeId,
            expectedVersion: 1
        });

        onClose?.();
    };

    const handleInputAutofocus = (isOpen: boolean) => {
        isOpen && inputRef.current?.focus();
    };

    const handleProductEditComplete = async (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<UpdateOfferChangeProductOutput, unknown>
        >
    ) => {
        const offerChangeId = completionParams.data?.data.offerChangeId;

        if (completionParams.error) {
            await removeCreatedOfferChange();

            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.product-edit']
                )
            });
            return;
        }

        if (offerChangeId === undefined) {
            await removeCreatedOfferChange();

            void notification['error']({
                message: formatMessage(
                    Messages['common.error-message.product-edit']
                ),
                placement: 'top'
            });
            return;
        }

        const detailsLink = buildLink(Path.OfferChangeDetails, {
            id: offerChangeId,
            marketId
        });

        navigate(detailsLink);

        void message.success(
            formatMessage(
                Messages['common.success-message.offer-change-submit']
            )
        );
    };

    const productEdit = usePromise({
        variables: undefined as unknown as UpdateOfferChangeProductInput,
        promiseBuilder: async (variables) => {
            return updateOfferChangeProduct(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true,
        onComplete: handleProductEditComplete
    });

    const handleOfferChangeCreateComplete = async (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<CreateOfferChangeOutput, unknown>
        >
    ) => {
        const offerChangeId = completionParams.data?.data.offerChangeId;

        if (offerChangeId === undefined || completionParams.error) {
            void message.error(
                formatMessage(
                    Messages['common.error-message.offer-change-submit']
                )
            );
            onClose?.();
            return;
        }

        const input = {
            ...editedProduct,
            id: offerChangeId,
            productId: editedProductId,
            expectedVersion: 1 // WARNING: We should consider getting the expected version from the offer change creation response, wchich is not yet implemented on the backend.
        };

        setUpdatedOfferChangeId(offerChangeId);
        await productEdit.execute(input);
    };

    const offerChangeCreate = usePromise({
        variables: undefined as unknown as CreateOfferChangeInput,
        promiseBuilder: async (variables) => {
            return createOfferChange(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true,
        onComplete: handleOfferChangeCreateComplete
    });

    const handleFormSubmit = async (values: PostOfferChangeFormData) => {
        const submitData = {
            name: values.name
        };

        await offerChangeCreate.execute(submitData);
    };

    return (
        <Drawer
            title={formatMessage(
                Messages['common.button.create-new-offer-change']
            )}
            placement="right"
            open={visible}
            closable={true}
            onClose={onClose}
            afterOpenChange={handleInputAutofocus}
        >
            <Form
                form={form}
                onFinish={handleFormSubmit}
                layout="vertical"
                data-testid="offer-change-creation-form"
                className="form-with-enhanced-labels"
            >
                <Form.Item
                    label={formatMessage(Messages['common.label.name'])}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: formatMessage(
                                Messages['common.error-message.required']
                            )
                        }
                    ]}
                >
                    <Input
                        placeholder={formatMessage(
                            Messages['common.placeholder.offer-change-name']
                        )}
                        data-testid="new-offer-change-name-input"
                        ref={inputRef}
                    />
                </Form.Item>
                <Space>
                    <Form.Item noStyle={true}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={offerChangeCreate.loading}
                            data-testid="submit-offer-change-button"
                        >
                            {formatMessage(Messages['common.button.create'])}
                        </Button>
                    </Form.Item>
                    <Button onClick={onClose}>
                        {formatMessage(Messages['common.button.cancel'])}
                    </Button>
                </Space>
            </Form>
        </Drawer>
    );
};
