import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Tag } from 'antd';
import { TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import {
    TemplateListItem,
    TemplateName
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalTitle } from '../../components/ModalTitle';
import { ModalContext } from '../../context';
import { Messages } from '../../intl';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';
import { buildTemplatesDataSource } from './utils';

const TemplatesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    templates: TemplateListItem[];
    templatesCount: number;
    selectedIds: TemplateName[];
    onSelect?: (template: TemplateListItem) => void;
}

export const TemplateSelectionModal = ({
    templates,
    templatesCount,
    selectedIds,
    onSelect
}: Props) => {
    const [count, setCount] = useState(templatesCount);
    const { onClose } = useContext(ModalContext);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const handleSelect = useCallback(
        (id: string) => {
            const template = templates.find((template) => template.id === id);

            if (!template) {
                throw new Error('Could not resolve translation by given id');
            }

            onSelect?.(template);
            onClose();
        },
        [templates, onSelect, onClose]
    );

    const columns = useMemo(() => {
        return buildTableColumns({
            intl,
            onSelect: handleSelect
        });
    }, [intl, handleSelect]);

    const dataSource = useMemo(() => {
        return buildTemplatesDataSource(templates, selectedIds);
    }, [templates, selectedIds]);

    const handleOnChange = (
        pagination: unknown,
        filters: unknown,
        sorter: unknown,
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        setCount(extra.currentDataSource.length);
    };

    return (
        <>
            <ModalTitle
                title={formatMessage(Messages['common.label.select-template'])}
                tags={
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.available'], {
                            count
                        })}
                    </Tag>
                }
            />
            <TemplatesTable
                data-testid="select-template-table"
                columns={columns}
                dataSource={dataSource}
                onChange={handleOnChange}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false
                }}
            />
        </>
    );
};
