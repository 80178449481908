import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Space } from 'antd';
import { TemplateId } from '@olxeu-monetization/product-catalog-api-client';
import { renderSearchIcon, SearchFilter } from '../../components/Table';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';

export interface Filters {
    search?: string;
}

export type TableDataSource = {
    name: string;
    id: TemplateId;
};

export type TableFilters = {
    name: string[] | null;
    actions: null;
};

interface Options {
    filters: Filters;
    marketId: MarketId;
    intl: IntlShape;
}

export const buildTableColumns = ({
    filters,
    marketId,
    intl
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-id-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.search ? [filters.search] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'id',
            align: 'right',
            render: (id) => (
                <Space>
                    <Link
                        to={buildLink(Path.TemplateDetails, {
                            id: id,
                            marketId
                        })}
                    >
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.open']}
                            />
                        </Button>
                    </Link>
                    <Link
                        to={buildLink(Path.TemplateEdit, {
                            id: id,
                            marketId
                        })}
                    >
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.edit']}
                            />
                        </Button>
                    </Link>
                </Space>
            )
        }
    ];
};
