import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { List, Tabs } from 'antd';
import { CategorySetLocalization } from '@olxeu-monetization/product-catalog-api-client';
import { ConfigContext } from '../../context';
import { Messages } from '../../intl';
import { getLanguageName } from '../../utils';
import { Section } from '../Section';

interface Props {
    localizations: CategorySetLocalization[];
}

export const CategorySetLocales = ({ localizations }: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const config = useContext(ConfigContext);

    const localizationTabs = localizations.map((localization) => {
        const dataSource = localizations.filter(
            (item) => item.languageCode === localization.languageCode
        );

        return {
            label: (
                <span
                    data-cy={`product-locales-tab[${localization.languageCode}]`}
                >
                    {getLanguageName(
                        localization.languageCode,
                        config.intl.locale
                    )}
                </span>
            ),
            key: localization.languageCode,
            children: (
                <List
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={(entry) => (
                        <List.Item>
                            <List.Item.Meta
                                title={formatMessage(
                                    Messages['common.label.name']
                                )}
                                description={entry.name}
                            />
                        </List.Item>
                    )}
                />
            )
        };
    });

    return (
        <Section
            title={formatMessage(Messages['common.label.locales'])}
            data-testid="category-set-locales"
        >
            {localizationTabs.length === 0 ? (
                <p>{formatMessage(Messages['common.info.no-locales'])}</p>
            ) : (
                <Tabs type="card" items={localizationTabs} />
            )}
        </Section>
    );
};
