import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedRoute, Button, Divider, List, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import {
    MarketLanguage,
    Template,
    TemplateId,
    TemplateStatus,
    TemplateTranslation
} from '@olxeu-monetization/product-catalog-api-client';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { getLanguageLabel } from '../TemplateFormLayout/helpers';

const { Text } = Typography;

const getLanguageValue = (
    code: string,
    translations: TemplateTranslation[]
) => {
    const translation = translations.find(
        (translation) => translation.language === code
    );

    return translation?.value ? (
        <Text>{translation?.value}</Text>
    ) : (
        <Text disabled>N/A</Text>
    );
};

interface Props {
    template: Template;
    languages: MarketLanguage[];
    defaultLanguage?: MarketLanguage;
    marketId: MarketId;
    breadcrumbRoutes: AugmentedRoute[];
    onRemove?: (id: TemplateId) => void;
}

export const TemplateDetailsLayout = ({
    template,
    languages,
    defaultLanguage,
    marketId,
    breadcrumbRoutes,
    onRemove
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const isDisabled = template.status === TemplateStatus.Disabled;

    const actionButtons = [
        <Link
            key="edit-template"
            to={buildLink(Path.TemplateEdit, {
                id: template.id,
                marketId
            })}
        >
            <Button icon={<EditOutlined />}>
                {formatMessage(Messages['common.button.edit'])}
            </Button>
        </Link>,
        <Button
            danger
            icon={<DeleteOutlined />}
            key="remove-template"
            onClick={() => onRemove?.(template.id)}
        >
            {formatMessage(Messages['common.button.remove'])}
        </Button>
    ];

    return (
        <div data-testid="template-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.template'])}
                tags={[
                    <Tag key="id" color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: template.id
                        })}
                    </Tag>,
                    ...(isDisabled
                        ? [
                              <Tag
                                  key="removed"
                                  color={theme.colors.tagStatusRemoved}
                              >
                                  {formatMessage(
                                      Messages['common.tag.removed']
                                  )}
                              </Tag>
                          ]
                        : [])
                ]}
                actions={!isDisabled ? actionButtons : undefined}
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <Section>
                <List>
                    <List.Item>
                        <List.Item.Meta
                            title={formatMessage(Messages['common.label.name'])}
                            description={template.name}
                        />
                    </List.Item>
                    {template.description && (
                        <List.Item>
                            <List.Item.Meta
                                title={formatMessage(
                                    Messages['common.label.description']
                                )}
                                description={template.description}
                            />
                        </List.Item>
                    )}
                </List>
                {defaultLanguage && (
                    <>
                        <Divider orientation="left">
                            {formatMessage(
                                Messages['common.label.default-translation']
                            )}
                        </Divider>
                        <List
                            dataSource={[defaultLanguage]}
                            renderItem={(language) => (
                                <List.Item>
                                    <div>
                                        <Tag>
                                            {getLanguageLabel(language?.code)}
                                        </Tag>
                                        {getLanguageValue(
                                            language.code,
                                            template.translations
                                        )}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </>
                )}
                <>
                    <Divider orientation="left">
                        {formatMessage(Messages['common.label.translations'])}
                    </Divider>
                    <List
                        dataSource={languages}
                        renderItem={(language) => (
                            <List.Item>
                                <div>
                                    <Tag>{getLanguageLabel(language.code)}</Tag>
                                    {getLanguageValue(
                                        language.code,
                                        template.translations
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />
                </>
            </Section>
        </div>
    );
};
