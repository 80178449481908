import { useContext, useMemo } from 'react';
import { AuthContext, ConfigContext } from '../../context';
import { SignInLayout } from '../../layouts/SignInLayout';
import { getOktaMarketIds } from '../../providers';
import { MarketId } from '../../routing';

export const SignInPage = () => {
    const configContext = useContext(ConfigContext);
    const authContext = useContext(AuthContext);

    const markets = useMemo(() => {
        return getOktaMarketIds(
            configContext.services.oktaAuthService.marketConfigs
        );
    }, [configContext.services.oktaAuthService.marketConfigs]);

    const handleMarketSelect = (marketId: MarketId) => {
        authContext.openAuthenticationPage(marketId);
    };

    return (
        <SignInLayout markets={markets} onMarketSelect={handleMarketSelect} />
    );
};
