import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { List, Tabs, Tag, Typography } from 'antd';
import styled, { useTheme } from 'styled-components';
import { ProductFeature } from '@olxeu-monetization/product-catalog-api-client';
import { FeaturePropertyValidationMap } from '../../../context';
import { Messages } from '../../../intl';
import {
    ChangeTag,
    ProductEntryNew,
    ProductEntryPrevious
} from '../../ProductEntryDiff';
import { Section } from '../../Section';
import { buildDataSource } from './buildDataSource';
import { ProductFeatureDiffTab } from './ProductFeatureDiffTab';

const { Text } = Typography;

const FeatureTarget = styled.div`
    margin: ${({ theme }) => `${theme.space[16]} 0`};
`;

const FeatureTabs = styled(Tabs)`
    [role='tab'] {
        flex-grow: 1;
        text-align: left;
    }
`;

interface Props {
    features: ProductFeature[];
    previousFeatures?: ProductFeature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
    featuresTotalCount: number;
    diffVisible?: boolean;
}

export const ProductFeatures = ({
    features,
    previousFeatures,
    featurePropertyValidationMap,
    featuresTotalCount,
    diffVisible
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const { dataSource, changesCount } = useMemo(() => {
        return buildDataSource(
            features,
            diffVisible ? previousFeatures : undefined,
            featurePropertyValidationMap,
            intl
        );
    }, [
        diffVisible,
        featurePropertyValidationMap,
        features,
        intl,
        previousFeatures
    ]);

    const featureTabs = dataSource.map((feature) => ({
        label: (
            <ProductFeatureDiffTab
                changeType={feature.changeType}
                changesCount={feature.changesCount}
            >
                {feature.id}
            </ProductFeatureDiffTab>
        ),
        key: feature.id,
        children: (
            <>
                <FeatureTarget>
                    <Text strong>
                        {formatMessage(Messages['common.label.target'])}:{' '}
                    </Text>
                    <Text code>{feature.target}</Text>
                </FeatureTarget>
                {feature.properties.length > 0 && (
                    <List
                        itemLayout="horizontal"
                        dataSource={feature.properties}
                        renderItem={(property) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={property.name}
                                    description={
                                        property.previousDescription ? (
                                            <div>
                                                <ProductEntryPrevious>
                                                    {
                                                        <span
                                                            data-testid={`product-diff-features-previous-value[${feature.id}][${property.name}]`}
                                                        >
                                                            {
                                                                property.previousDescription
                                                            }
                                                        </span>
                                                    }
                                                    <ChangeTag
                                                        color={
                                                            theme.colors.pink
                                                        }
                                                        m={0}
                                                        ml={8}
                                                    >
                                                        {formatMessage(
                                                            Messages[
                                                                'common.tag.previous'
                                                            ]
                                                        )}
                                                    </ChangeTag>
                                                </ProductEntryPrevious>
                                                <ProductEntryNew>
                                                    {
                                                        <span
                                                            data-testid={`product-diff-features-new-value[${feature.id}][${property.name}]`}
                                                        >
                                                            {
                                                                property.description
                                                            }
                                                        </span>
                                                    }
                                                    <ChangeTag
                                                        color={
                                                            theme.colors.green
                                                        }
                                                        m={0}
                                                        ml={8}
                                                    >
                                                        {formatMessage(
                                                            Messages[
                                                                'common.tag.new'
                                                            ]
                                                        )}
                                                    </ChangeTag>
                                                </ProductEntryNew>
                                            </div>
                                        ) : (
                                            <span
                                                data-testid={`product-diff-features-current-value[${feature.id}][${property.name}]`}
                                            >
                                                {property.description}
                                            </span>
                                        )
                                    }
                                />
                            </List.Item>
                        )}
                    />
                )}
            </>
        )
    }));

    return (
        <Section
            title={formatMessage(Messages['common.label.features'])}
            description={formatMessage(Messages['common.description.features'])}
            tags={
                <>
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.selected-of'], {
                            selected: features.length,
                            available: featuresTotalCount
                        })}
                    </Tag>
                    {diffVisible && (
                        <Tag
                            color={theme.colors.tagChanges}
                            data-testid="product-diff-features-changes-tag"
                        >
                            {formatMessage(Messages['common.tag.changes'], {
                                count: changesCount
                            })}
                        </Tag>
                    )}
                </>
            }
            data-testid="product-features"
        >
            <FeatureTabs tabPosition="left" type="card" items={featureTabs} />
        </Section>
    );
};
