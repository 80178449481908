import { FormattedMessage } from 'react-intl';
import { AugmentedColumnsType, Tag } from 'antd';
import { LabelMetadataValue } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../../intl';
import { ProductPropertyDiff } from '../../ProductProperties/ProductPropertyDiff';

export type TableDataSource = {
    key: string;
    value: LabelMetadataValue;
    previousValue?: LabelMetadataValue;
    diffVisible?: boolean;
};

export type TableFilters = {
    key: string[] | null;
    value: string[] | null;
};

export const buildTableColumns = (): AugmentedColumnsType<
    TableDataSource,
    TableFilters
> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.key']} />,
            key: 'key',
            dataIndex: 'key',
            render: (value) => {
                return <Tag color="blue">{value}</Tag>;
            }
        },
        {
            title: <FormattedMessage {...Messages['common.label.value']} />,
            key: 'value',
            dataIndex: 'value',
            align: 'right',
            render: (value, record) => {
                if (record.diffVisible && record.previousValue !== undefined) {
                    return (
                        <ProductPropertyDiff
                            id={record.key}
                            value={value}
                            previousValue={record.previousValue}
                        />
                    );
                }

                return (
                    <span
                        data-testid={`product-diff-properties-current-value[${record.key}]`}
                    >
                        {value.toString()}
                    </span>
                );
            }
        }
    ];
};
