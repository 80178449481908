import { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AugmentedPageHeaderInterface,
    AugmentedRoute,
    PageHeader as AntdPageHeader,
    PageHeaderProps,
    Typography
} from 'antd';
import styled from 'styled-components';

const AgumentedPageHeader = AntdPageHeader as AugmentedPageHeaderInterface;

const { Title } = Typography;

const TitleStyled = styled(Title)`
    ${({ theme }) => `
        margin: 0 ${theme.space[24]} 0 0 !important;
    `}
`;

const renderBreadcrumb = (
    route: AugmentedRoute,
    params: unknown,
    routes: Array<AugmentedRoute>,
    paths: Array<string>
) => {
    const isLast = route === routes[routes.length - 1];

    if (isLast) {
        return <>{route.breadcrumbName}</>;
    }

    return <Link to={route.path}>{route.breadcrumbName}</Link>;
};

interface Props {
    title: string;
    description?: string;
    tags?: PageHeaderProps['tags'];
    breadcrumbRoutes?: AugmentedRoute[];
    onBack?: VoidFunction;
    hideBackButton?: boolean;
    actions?: ReactNode[];
}

export const PageHeader = ({
    title,
    description,
    tags,
    breadcrumbRoutes,
    onBack,
    hideBackButton,
    actions
}: Props) => {
    const navigate = useNavigate();

    const buildDefaultOnBack = () => {
        const path = breadcrumbRoutes?.[breadcrumbRoutes?.length - 2]?.path;

        if (path) {
            return () => navigate(path);
        }

        return undefined;
    };

    return (
        <AgumentedPageHeader
            onBack={hideBackButton ? undefined : onBack ?? buildDefaultOnBack()}
            title={<TitleStyled level={2}>{title}</TitleStyled>}
            tags={tags}
            breadcrumb={{
                routes: breadcrumbRoutes,
                itemRender: renderBreadcrumb
            }}
            extra={actions}
        >
            {description && (
                <Typography.Text type="secondary">
                    {description}
                </Typography.Text>
            )}
        </AgumentedPageHeader>
    );
};
