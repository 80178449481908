import { useEffect, useMemo, useRef, useState } from 'react';
import { HTTPError } from '@olxeu-monetization/product-catalog-api-client';
import { useCurrentFunction } from '../useCurrentFunction';
import { useStableValue } from '../useStableValue';

export interface ProcessingState<T> {
    loading: boolean;
    data?: T;
    error?: Error;
}

export interface ErrorResponse extends Error {
    details?: string;
}

export interface CompletionParams<V = unknown, R = unknown> {
    variables: V;
    data?: R;
    error?: ErrorResponse;
    aborted: boolean;
}

export interface UsePromiseResult<V, R> {
    execute: (variablesParam: V) => Promise<R | undefined>;
    loading: boolean;
    data?: R | undefined;
    error?: HTTPError | undefined;
}

export interface Options<V, R> {
    variables: V;
    promiseBuilder: (variables: V) => Promise<R>;
    disableAutostart?: boolean;
    onComplete?: (params: CompletionParams<V, R>) => Promise<void> | void;
}

export const usePromise = <V, R>({
    variables,
    promiseBuilder,
    disableAutostart,
    onComplete
}: Options<V, R>): UsePromiseResult<V, R> => {
    const abortedRef = useRef(false);

    const stableVariables = useStableValue(variables);

    const [state, setState] = useState<ProcessingState<R>>({
        loading: !disableAutostart,
        data: undefined,
        error: undefined
    });

    const executePromise = useCurrentFunction(async (variablesParam: V) => {
        setState((previousState) => ({
            ...previousState,
            loading: true
        }));

        let data;
        let error;

        try {
            data = await promiseBuilder(variablesParam);
        } catch (promiseError) {
            if (!(promiseError instanceof Error)) {
                throw promiseError;
            }

            error = promiseError;
        }

        const completionResult = onComplete?.({
            variables: variablesParam,
            data,
            error,
            aborted: abortedRef.current
        });

        if (completionResult instanceof Promise) {
            await completionResult;
        }

        if (abortedRef.current) {
            return undefined;
        }

        setState({
            loading: false,
            data,
            error
        });

        if (error) {
            throw error;
        }

        return data;
    });

    useEffect(() => {
        if (disableAutostart) {
            return;
        }

        executePromise(stableVariables).catch(() => {
            // NOTE: ignoring error as it's being exposed via `onComplete` and `state`
        });
    }, [disableAutostart, stableVariables, executePromise]);

    useEffect(() => {
        return () => {
            abortedRef.current = true;
        };
    }, []);

    return useMemo(() => {
        return {
            ...state,
            execute: executePromise
        };
    }, [state, executePromise]);
};
