import { ReactNode, useContext, useMemo } from 'react';
import { AuthContext, UserDataContext } from '../../context';
import { usePromise } from '../../utils';

interface Props {
    children?: ReactNode;
}

export const UserDataContextProvider = ({ children }: Props) => {
    const auth = useContext(AuthContext);

    const { data, loading, error } = usePromise({
        disableAutostart: !auth.authenticated,
        variables: {},
        promiseBuilder: async () => {
            return auth.getUserInfo();
        }
    });

    const context = useMemo(() => {
        return {
            data: data,
            loading,
            error
        };
    }, [data, error, loading]);

    return (
        <UserDataContext.Provider value={context}>
            {children}
        </UserDataContext.Provider>
    );
};
