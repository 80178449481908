import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Typography } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import {
    MarketLanguage,
    ProductLabelValue,
    TemplateTranslation
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../../intl';
import { TranslationCard } from '../../../../layouts/ProductFormLayout/LabelsFormSection/TranslationCard';
import { getResolvedTranslations } from '../../../../layouts/ProductFormLayout/LabelsFormSection/utils';
import { Box } from '../../../Box';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const { Title } = Typography;

const ValuesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    dataSource: TableDataSource[];
    marketLanguages: MarketLanguage[];
    labelValues: ProductLabelValue[];
    translations?: TemplateTranslation[];
}

export const LabelValuesTable = ({
    dataSource,
    marketLanguages,
    labelValues,
    translations
}: Props) => {
    const { formatMessage } = useIntl();

    const columns = buildTableColumns();

    const labelTranslations = getResolvedTranslations(
        labelValues,
        translations
    );

    return (
        <>
            <Title level={5}>
                {formatMessage(Messages['common.label.values'])}
            </Title>
            <ValuesTable
                data-testid="label-values-table"
                className="label-values-table"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={false}
            />
            <Box maxWidth={600} mt={12} mb={24}>
                <TranslationCard
                    title={formatMessage(
                        Messages['common.label.resolved-translation']
                    )}
                    icon={<TagOutlined style={{ marginRight: 8 }} />}
                    translations={labelTranslations}
                    marketLanguages={marketLanguages}
                />
            </Box>
        </>
    );
};
