import { useIntl } from 'react-intl';
import { List } from 'antd';
import { OfferChangeDetails } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import { DateLabel } from '../DateLabel';
import { Section } from '../Section';
import { OfferChangeStatusTag } from '../StatusTag';

interface Props {
    offerChange: OfferChangeDetails;
}

export const FinishedOfferChangeProperties = ({ offerChange }: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const dataSource = [
        {
            label: formatMessage(Messages['common.label.name']),
            value: offerChange.name
        },
        {
            label: formatMessage(Messages['common.label.last-author']),
            value: offerChange.lastAuthor
        },
        {
            label: formatMessage(Messages['common.label.last-edited']),
            value: <DateLabel date={offerChange.updatedAt} />
        },
        {
            label: formatMessage(Messages['common.label.status']),
            value: <OfferChangeStatusTag status={offerChange.status} />
        }
    ];

    return (
        <Section
            title={formatMessage(Messages['common.label.properties'])}
            data-testid="offer-change-properties"
        >
            <List
                dataSource={dataSource}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.label}
                            description={item.value}
                        />
                    </List.Item>
                )}
            />
        </Section>
    );
};
