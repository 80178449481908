import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.min.css';

export const GlobalStyle = createGlobalStyle`
    html, body {
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .form-with-enhanced-labels .ant-form-item-label {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
    }

    .form-input-empty-label-value {
        margin: 0;
    }

    .form-input-empty-label-value .ant-form-item-row {
        flex-direction: row-reverse;
        gap: ${({ theme }) => theme.space[8]};
    }

    .form-input-empty-label-value .ant-form-item-row .ant-form-item-control {
        width: auto;
    }

    .label-values-table td {
        vertical-align: top;
    }

    .tab-navigation-list .ant-tabs-content-holder {
        border-top: ${({ theme }) => theme.borders.white4};
    }

    .tab-navigation-list .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
    }

    .tab-navigation-list .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
        padding: ${({ theme }) => `${theme.space[12]} ${theme.space[24]}`};
    }

    .tabs-with-validation-icon .ant-tabs-tab .anticon {
        margin-right: 0;
    }
    
    .ant-tabs [draggable] {
        margin: 0 !important;
    }
`;
