import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const LayoutWrapper = styled.section`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GuestAppLayout = () => {
    return (
        <LayoutWrapper data-testid="guest-app-layout">
            <Outlet />
        </LayoutWrapper>
    );
};
