import { createContext } from 'react';
import { ProductType } from '@olxeu-monetization/product-catalog-api-client';

export interface ProductTypesDataContextType {
    data?: ProductType[];
    loading: boolean;
    error?: Error;
}

const DefaultProductTypesDataContext: ProductTypesDataContextType = {
    data: undefined,
    loading: false,
    error: undefined
};

export const ProductTypesDataContext =
    createContext<ProductTypesDataContextType>(DefaultProductTypesDataContext);
