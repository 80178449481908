import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Input, InputNumber, Row } from 'antd';
import { Messages } from '../../intl';
import { Duration, DurationJson } from '../../types';
import { Box } from '../Box';
import { Switch } from '../Switch';

// NOTE: due to technical restrictions on backend we're going to temporarily
// allow only days, hours, minutes and seconds and prevent years and months
// from being used in this input duration component; in the future, we can
// restore support for years and months by reverting relevant changes
// Reference: https://naspersclassifieds.atlassian.net/browse/EUPP-1967

type DurationInputType = keyof DurationJson;

interface ExpirationOptions {
    showSwitch?: boolean;
    switchLabel?: string;
}

export enum InputDurationFallbackMode {
    DisplayZero,
    DisplayEmpty
}

const DefaultFallbackMode = InputDurationFallbackMode.DisplayZero;

const fallbackValueMapping = {
    [InputDurationFallbackMode.DisplayZero]: 0,
    [InputDurationFallbackMode.DisplayEmpty]: undefined
};

type DurationSegment = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

export type DurationAllowedSegments = Partial<Record<DurationSegment, boolean>>;

const DefaultAllowedSegments: DurationAllowedSegments = {
    second: false,
    minute: false,
    hour: false,
    day: true,
    month: false,
    year: false
};

interface Props {
    value?: Duration;
    minValue?: Duration;
    maxValue?: Duration;
    expiration?: ExpirationOptions;
    fallbackMode?: InputDurationFallbackMode;
    autoFocus?: boolean;
    disabled?: boolean;
    onChange?: (value?: Duration) => void;
    allowedSegments?: DurationAllowedSegments;
    hideDisallowedSegments?: boolean;
}

export const InputDuration = ({
    value,
    minValue,
    maxValue,
    expiration,
    fallbackMode = DefaultFallbackMode,
    autoFocus,
    disabled,
    onChange,
    allowedSegments = DefaultAllowedSegments,
    hideDisallowedSegments,
    ...rest
}: Props) => {
    const { formatMessage } = useIntl();
    const inputRefs = useRef<Record<DurationInputType, HTMLElement | null>>({
        years: null,
        months: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null
    });

    const durationJson = useMemo(() => {
        return value?.toJson() ?? {};
    }, [value]);

    const handleInputChange = (
        nextValue: number | null,
        field: DurationInputType
    ) => {
        const duration = Duration.fromJson({
            ...durationJson,
            [field]: nextValue === null ? 0 : nextValue
        });
        onChange?.(duration.getDefined());
    };

    const handleExpiresChange = (checked: boolean) => {
        onChange?.(checked ? Duration.zero : undefined);
    };

    const handleInputRef = (
        element: HTMLInputElement | null,
        type: DurationInputType
    ) => {
        inputRefs.current[type] = element;
    };

    const isDefined = value?.getDefined() !== undefined;
    const inputsDisabled = (expiration?.showSwitch && !isDefined) || disabled;
    const fallbackValue = isDefined ? 0 : fallbackValueMapping[fallbackMode];

    return (
        <Box minWidth={400} {...rest}>
            <Row gutter={[0, 8]}>
                {expiration?.showSwitch && (
                    <Switch
                        data-testid="input-duration-expires"
                        checked={isDefined}
                        disabled={disabled}
                        onChange={handleExpiresChange}
                        secondaryLabel={expiration.switchLabel}
                    />
                )}
                {!allowedSegments.day && hideDisallowedSegments ? null : (
                    <Input.Group compact>
                        <InputNumber
                            data-testid="input-duration-days"
                            style={{
                                width: '50%'
                            }}
                            addonBefore={formatMessage(
                                Messages['common.label.days']
                            )}
                            min={0}
                            value={durationJson.days || fallbackValue}
                            disabled={inputsDisabled || !allowedSegments.day}
                            ref={(element) => handleInputRef(element, 'days')}
                            onChange={(value) =>
                                handleInputChange(value, 'days')
                            }
                        />
                    </Input.Group>
                )}
                {!allowedSegments.hour && hideDisallowedSegments ? null : (
                    <Input.Group compact>
                        <InputNumber
                            data-testid="input-duration-hours"
                            style={{
                                width: '50%'
                            }}
                            addonBefore={formatMessage(
                                Messages['common.label.hours']
                            )}
                            min={0}
                            value={durationJson.hours || fallbackValue}
                            disabled={inputsDisabled || !allowedSegments.hour}
                            ref={(element) => handleInputRef(element, 'hours')}
                            onChange={(value) =>
                                handleInputChange(value, 'hours')
                            }
                        />
                    </Input.Group>
                )}
                {!allowedSegments.minute && hideDisallowedSegments ? null : (
                    <Input.Group compact>
                        <InputNumber
                            data-testid="input-duration-minutes"
                            style={{
                                width: '50%'
                            }}
                            addonBefore={formatMessage(
                                Messages['common.label.minutes']
                            )}
                            min={0}
                            value={durationJson.minutes || fallbackValue}
                            disabled={inputsDisabled || !allowedSegments.minute}
                            ref={(element) =>
                                handleInputRef(element, 'minutes')
                            }
                            onChange={(value) =>
                                handleInputChange(value, 'minutes')
                            }
                        />
                    </Input.Group>
                )}
                {!allowedSegments.second && hideDisallowedSegments ? null : (
                    <Input.Group compact>
                        <InputNumber
                            data-testid="input-duration-seconds"
                            style={{
                                width: '50%'
                            }}
                            addonBefore={formatMessage(
                                Messages['common.label.seconds']
                            )}
                            min={0}
                            value={durationJson.seconds || fallbackValue}
                            disabled={inputsDisabled || !allowedSegments.second}
                            ref={(element) =>
                                handleInputRef(element, 'seconds')
                            }
                            onChange={(value) =>
                                handleInputChange(value, 'seconds')
                            }
                        />
                    </Input.Group>
                )}
            </Row>
        </Box>
    );
};
