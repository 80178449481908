import { createContext } from 'react';

export interface UserInfo {
    email?: string;
    name?: string;
}

export interface UserDataContextType {
    data?: UserInfo;
    loading: boolean;
    error?: Error;
}

const DefaultUserDataContext: UserDataContextType = {
    data: undefined,
    loading: false,
    error: undefined
};

export const UserDataContext = createContext<UserDataContextType>(
    DefaultUserDataContext
);
