import { MarketId } from '../../routing';

export type OktaMarketClientId = string;

export interface OktaMarketConfig {
    marketId: MarketId;
    clientId: OktaMarketClientId;
}

// NOTE: eg. `app_monetization_backoffice_prd_olxpl_read-product-catalog`
export type OktaAccessScope = string;

export interface OktaJwt {
    groups?: OktaAccessScope[];
    aud?: OktaMarketClientId;
}

export enum OktaAccessScopeGrant {
    ProductCatalogRead = 'read-product-catalog',
    ProductCatalogWrite = 'write-product-catalog'
}
