import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { buildLink } from '../buildLink';
import { Path } from '../paths';

export const MarketRedirection = () => {
    const auth = useContext(AuthContext);

    if (auth.userMarketId === undefined) {
        throw new Error('User session contains no client id');
    }

    return (
        <Navigate
            to={buildLink(Path.Market, { marketId: auth.userMarketId })}
        />
    );
};
