import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AugmentedRoute, Button, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    Feature,
    MarketLanguage,
    OfferChangeProductDetails,
    ProductDetails
} from '@olxeu-monetization/product-catalog-api-client';
import { BottomBar } from '../../components/BottomBar';
import { PageHeader } from '../../components/PageHeader';
import { ProductCategorySets } from '../../components/ProductSections/ProductCategorySets';
import { ProductFeatures } from '../../components/ProductSections/ProductFeatures';
import { ProductLabels } from '../../components/ProductSections/ProductLabels';
import { ProductLocales } from '../../components/ProductSections/ProductLocales';
import { ProductProperties } from '../../components/ProductSections/ProductProperties';
import { FeaturePropertyValidationMap } from '../../context';
import { Messages } from '../../intl';
import { ProductTypeTreeNode } from '../ProductFormLayout/types';

interface Props {
    product: OfferChangeProductDetails;
    previousProduct: ProductDetails;
    features: Feature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
    productTypeTree: ProductTypeTreeNode[];
    breadcrumbRoutes: AugmentedRoute[];
    marketLanguages: MarketLanguage[];
}

export const EditedProductDetailsLayout = ({
    product,
    previousProduct,
    features,
    featurePropertyValidationMap,
    productTypeTree,
    breadcrumbRoutes,
    marketLanguages
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [diffVisible, setDiffVisible] = useState(true);

    const handleChangesButtonClick = () => {
        setDiffVisible((visible) => !visible);
    };

    return (
        <div data-testid="product-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.edited-product'])}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: product.id
                        })}
                    </Tag>
                }
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <ProductProperties
                product={product}
                productTypeTree={productTypeTree}
                previousProduct={previousProduct}
                diffVisible={diffVisible}
            />
            <ProductCategorySets
                categorySetIds={product.target.categorySetIds}
                previousCategorySetIds={previousProduct.target.categorySetIds}
                diffVisible={diffVisible}
            />
            <ProductLocales
                localizations={product.localizations}
                previousLocalizations={previousProduct.localizations}
                diffVisible={diffVisible}
                marketLanguages={marketLanguages}
            />
            <ProductFeatures
                features={product.features}
                previousFeatures={previousProduct.features}
                featurePropertyValidationMap={featurePropertyValidationMap}
                featuresTotalCount={features.length}
                diffVisible={diffVisible}
            />
            {product.labels && (
                <ProductLabels
                    labels={product.labels}
                    features={product.features}
                    marketLanguages={marketLanguages}
                    previousLabels={previousProduct.labels}
                    diffVisible={diffVisible}
                />
            )}
            <BottomBar>
                <Button
                    size="large"
                    type="primary"
                    onClick={handleChangesButtonClick}
                    data-cy="hide-show-changes"
                >
                    {diffVisible ? (
                        <FormattedMessage
                            {...Messages['common.button.hide-changes']}
                        />
                    ) : (
                        <FormattedMessage
                            {...Messages['common.button.show-changes']}
                        />
                    )}
                </Button>
            </BottomBar>
        </div>
    );
};
