import { DataNode } from 'antd/lib/tree';
import {
    CategorySet,
    CategorySetId,
    GetCategorySetsInput,
    GetCategorySetsMetadata,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';

export const buildCategorySets = (
    selectedCategorySetIds: CategorySetId[],
    categorySetNamesMap: Map<CategorySetId, string>
): DataNode[] => {
    if (categorySetNamesMap.size === 0) {
        return [];
    }

    const existingCategorySetsIds = selectedCategorySetIds.filter(
        (categorySetId) => categorySetNamesMap.has(categorySetId)
    );

    return existingCategorySetsIds.map((categorySetId) => ({
        key: categorySetId,
        title: categorySetNamesMap.get(categorySetId)
    }));
};

interface CategorySetNameGetterOptions {
    categorySetIds?: CategorySetId[];
    onCategorySetsGet: (
        input: GetCategorySetsInput
    ) => Promise<ResultEnvelope<CategorySet[], GetCategorySetsMetadata>>;
}

export const getCategorySetNames = async ({
    categorySetIds,
    onCategorySetsGet
}: CategorySetNameGetterOptions) => {
    if (!categorySetIds || categorySetIds.length === 0) {
        return new Map<CategorySetId, string>();
    }

    const requestVariables = {
        ids: categorySetIds,
        offset: 0,
        limit: 9999 // TODO: figure out how to get rid of arbitrary limit and handle proper pagination
    };

    const categorySetsResult = await onCategorySetsGet(requestVariables);

    return categorySetsResult.data.reduce((acc, categorySet) => {
        acc.set(categorySet.id, categorySet.name);
        return acc;
    }, new Map<CategorySetId, string>());
};
