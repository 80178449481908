import { ReactElement, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { AuthContext } from '../../context';
import { Messages } from '../../intl';
import { OktaAccessScopeGrant, parseAccessScopeGrant } from '../../providers';

interface Props {
    permission: OktaAccessScopeGrant;
    placement?: TooltipPlacement;
    children: (eligible: boolean) => ReactElement;
}

export const PermissionGuard = ({ permission, placement, children }: Props) => {
    const auth = useContext(AuthContext);
    const { formatMessage } = useIntl();

    const permissionGranted = useMemo(() => {
        return auth.userMarketScopes?.some((scope) => {
            const grant = parseAccessScopeGrant(scope);
            return grant === permission;
        });
    }, [permission, auth.userMarketScopes]);

    if (permissionGranted) {
        return children(true);
    }

    return (
        <Tooltip
            title={formatMessage(
                Messages['common.tooltip-message.permission-warning']
            )}
            placement={placement}
        >
            {children(false)}
        </Tooltip>
    );
};
