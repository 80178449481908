import { useContext, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, InputRef, Skeleton, Tree } from 'antd';
import { FilterDropdownProps, Key } from 'antd/lib/table/interface';
import { EventDataNode } from 'antd/lib/tree';
import {
    CategorySet,
    CategorySetType,
    getCategorySets,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';
import { AuthContext, ConfigContext } from '../../../context';
import { buildCheckedState, changeTreeNodeChecked } from '../../../helpers';
import { Messages } from '../../../intl';
import {
    CompletionParams,
    errorNotification,
    usePromise
} from '../../../utils';
import { Buttons, TreeSearch, Wrapper } from './CategorySetsFilter.styled';

const PaginationDefaultPageSize = 20;

interface CheckInfo {
    node: EventDataNode<unknown>;
}

interface CheckedState {
    checked: Key[];
    halfChecked: Key[];
}

interface Props extends FilterDropdownProps {
    placeholder: string;
    categorySetsTypes?: CategorySetType[];
}

export const CategorySetsFilter = ({
    confirm,
    clearFilters,
    selectedKeys,
    setSelectedKeys,
    filters = [],
    placeholder,
    categorySetsTypes
}: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [initialDataLoading, setInitialDataLoading] = useState(true);
    const [data, setData] = useState<CategorySet[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef<InputRef>(null);
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const hasSearchValue = Boolean(searchValue);
    const requestVariables = hasSearchValue
        ? {
              limit: 9999,
              offset: 0,
              types: categorySetsTypes ? categorySetsTypes : []
          }
        : {
              limit: PaginationDefaultPageSize,
              offset: data.length,
              types: categorySetsTypes ? categorySetsTypes : []
          };

    const handleClearFilters = () => {
        clearFilters?.();
        if (hasSearchValue) setSearchValue('');
    };

    const handleGetCategorySetsComplete = (
        completionParams: CompletionParams<
            unknown,
            ResultEnvelope<CategorySet[], unknown>
        >
    ) => {
        setInitialDataLoading(false);

        if (completionParams.error) {
            void errorNotification({
                error: completionParams.error,
                message: formatMessage(
                    Messages['common.error-message.category-sets-fetch']
                )
            });

            return;
        }

        const fetchedData = completionParams.data?.data ?? [];
        setData(hasSearchValue ? fetchedData : [...data, ...fetchedData]);
    };

    const categorySetsResult = usePromise({
        variables: requestVariables,
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        onComplete: handleGetCategorySetsComplete,
        disableAutostart: hasSearchValue ? false : Boolean(data.length)
    });

    const handleOnCheck = (checked: CheckedState | Key[], info: CheckInfo) => {
        if (!Array.isArray(checked)) {
            const nextSelectedIds = changeTreeNodeChecked({
                roots: filters,
                changedNodeKey: info.node.key,
                changedNodeChecked: !info.node.checked,
                selectedKeys: checked.checked.map(String),
                keyName: 'value'
            });

            setSelectedKeys(nextSelectedIds);
        }
    };

    const selectedIds = useMemo(() => {
        return selectedKeys.map(String);
    }, [selectedKeys]);

    const checkedState = useMemo(() => {
        return buildCheckedState(filters, selectedIds);
    }, [selectedIds, filters]);

    const searchFilteredData = hasSearchValue
        ? data.filter((item: CategorySet) => {
              return item.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
          })
        : [];

    const outputData = hasSearchValue ? searchFilteredData : data;

    const treeData = useMemo(() => {
        return outputData.map((item) => ({ key: item.id, title: item.name }));
    }, [outputData]);

    return (
        <Wrapper data-test-id="category-sets-dropdown">
            <TreeSearch
                placeholder={placeholder}
                disabled={initialDataLoading}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                ref={searchRef}
            />
            {categorySetsResult.data && (
                <div
                    id="scrollableDiv"
                    data-testid="categorySetsInfiniteScrollList"
                    style={{
                        height: 200,
                        overflow: 'auto'
                    }}
                >
                    <InfiniteScroll
                        dataLength={outputData.length}
                        next={() => {
                            if (initialDataLoading || hasSearchValue) {
                                return;
                            }
                            return categorySetsResult.execute(requestVariables);
                        }}
                        hasMore={
                            hasSearchValue
                                ? false
                                : data.length <
                                  categorySetsResult.data?.metadata.totalCount
                        }
                        loader={<Skeleton.Button block size="small" active />}
                        endMessage={
                            <Divider plain>
                                <FormattedMessage
                                    {...Messages['common.label.that-is-all']}
                                />
                            </Divider>
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        <Tree
                            checkedKeys={checkedState}
                            treeData={treeData}
                            checkStrictly={true}
                            onCheck={handleOnCheck}
                            checkable={true}
                            selectable={false}
                        />
                    </InfiniteScroll>
                </div>
            )}
            <Buttons>
                <Button
                    onClick={handleClearFilters}
                    size="small"
                    type="link"
                    disabled={selectedIds.length === 0 && !hasSearchValue}
                >
                    <FormattedMessage {...Messages['common.button.reset']} />
                </Button>
                <Button type="primary" onClick={() => confirm()} size="small">
                    <FormattedMessage {...Messages['common.button.ok']} />
                </Button>
            </Buttons>
        </Wrapper>
    );
};
