import { IntlShape } from 'react-intl';
import {
    FeatureId,
    FeaturePropertyName,
    FeaturePropertyType,
    FeaturePropertyValue,
    ProductFeature
} from '@olxeu-monetization/product-catalog-api-client';
import { FeaturePropertyValidationMap } from '../../../context';
import { getHumanTimeLabel } from '../utils';
import { FeatureChangeType } from './ProductFeatureDiffTab';

const buildFeatureValueLabel = ({
    id,
    name,
    value,
    typesMap,
    intl
}: {
    id: FeatureId;
    name: FeaturePropertyName;
    value: FeaturePropertyValue;
    typesMap: FeaturePropertyValidationMap;
    intl: IntlShape;
}) => {
    const propertyType = typesMap[id][name].type;

    switch (propertyType) {
        case FeaturePropertyType.TimeInterval:
            return getHumanTimeLabel(String(value), intl);
        case FeaturePropertyType.Number:
            return value;
    }
};

export const buildDataSource = (
    features: ProductFeature[],
    previousFeatures: ProductFeature[] | undefined,
    featurePropertyValidationMap: FeaturePropertyValidationMap,
    intl: IntlShape
) => {
    const featuresMap = new Map<string, ProductFeature>();

    features.forEach((feature) => {
        featuresMap.set(feature.id, feature);
    });

    const previousFeaturesMap = new Map<string, ProductFeature>();

    previousFeatures?.forEach((feature) => {
        previousFeaturesMap.set(feature.id, feature);
    });

    const aggregatedFeatures = [...features];

    previousFeatures?.forEach((feature) => {
        if (!featuresMap.get(feature.id)) {
            aggregatedFeatures.push(feature);
        }
    });

    const dataSource = aggregatedFeatures.map((feature) => {
        const currentFeature = featuresMap.get(feature.id);
        const previousFeature = previousFeaturesMap.get(feature.id);

        const previousFeaturePropertiesMap = new Map<
            string,
            FeaturePropertyValue
        >();

        previousFeature?.properties.forEach((property) => {
            previousFeaturePropertiesMap.set(property.name, property.value);
        });

        const resolvedFeature = currentFeature ?? previousFeature;

        const properties =
            resolvedFeature?.properties.map((property) => {
                const previousFeaturePropertyValue =
                    previousFeaturePropertiesMap.get(property.name);

                const propertyValueChanged =
                    previousFeature !== undefined &&
                    property.value !== previousFeaturePropertyValue;

                const previousDescription =
                    previousFeaturePropertyValue &&
                    buildFeatureValueLabel({
                        id: feature.id,
                        name: property.name,
                        value: previousFeaturePropertyValue,
                        typesMap: featurePropertyValidationMap,
                        intl
                    });

                return {
                    name: property.name,
                    description: buildFeatureValueLabel({
                        id: feature.id,
                        name: property.name,
                        value: property.value,
                        typesMap: featurePropertyValidationMap,
                        intl
                    }),
                    previousDescription: propertyValueChanged
                        ? previousDescription
                        : undefined,
                    hasChanged: propertyValueChanged
                };
            }) ?? [];

        const currentContains = currentFeature !== undefined;
        const previousContains = previousFeature !== undefined;

        const featureAdded = !previousContains && currentContains;
        const featureRemoved = previousContains && !currentContains;

        const changeType =
            previousFeatures !== undefined && featureAdded
                ? FeatureChangeType.Added
                : previousFeatures !== undefined && featureRemoved
                ? FeatureChangeType.Removed
                : FeatureChangeType.None;

        const changesCount = properties.reduce((acc, property) => {
            return acc + Number(property.hasChanged);
        }, 0);

        return {
            id: feature.id,
            target: feature.target,
            properties,
            changeType,
            changesCount
        };
    });

    const changesCount = dataSource.reduce((acc, feature) => {
        return (
            acc +
            feature.changesCount +
            Number(feature.changeType !== FeatureChangeType.None)
        );
    }, 0);

    return {
        dataSource,
        changesCount
    };
};
