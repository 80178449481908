import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Messages } from '../../../intl';
import {
    ChangeTag,
    ProductEntryNew,
    ProductEntryPrevious
} from '../../ProductEntryDiff';

interface Props {
    id: string;
    value?: string | number;
    previousValue?: string | number;
}

export const ProductLocaleDiff = ({ id, value, previousValue }: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    return (
        <div>
            <ProductEntryPrevious>
                <span
                    data-testid={`product-diff-locales-previous-value[${id}]`}
                >
                    {previousValue}
                </span>
                <ChangeTag color={theme.colors.pink} m={0} ml={8}>
                    {formatMessage(Messages['common.tag.previous'])}
                </ChangeTag>
            </ProductEntryPrevious>
            <ProductEntryNew>
                <span data-testid={`product-diff-locales-new-value[${id}]`}>
                    {value}
                </span>
                <ChangeTag color={theme.colors.green} m={0} ml={8}>
                    {formatMessage(Messages['common.tag.new'])}
                </ChangeTag>
            </ProductEntryNew>
        </div>
    );
};
