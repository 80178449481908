import { createContext } from 'react';
import { MarketLanguage } from '@olxeu-monetization/product-catalog-api-client';

export interface MarketLanguagesDataContextType {
    data?: {
        available: MarketLanguage[];
        current: MarketLanguage[];
        default?: MarketLanguage;
    };
    loading: boolean;
    error?: Error;
}

const DefaultMarketLanguagesDataContext: MarketLanguagesDataContextType = {
    data: undefined,
    loading: false,
    error: undefined
};

export const MarketLanguagesDataContext =
    createContext<MarketLanguagesDataContextType>(
        DefaultMarketLanguagesDataContext
    );
