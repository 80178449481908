import { FormattedMessage } from 'react-intl';
import { ProductChangeType } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';

interface Props {
    changeType: ProductChangeType;
}

export const ProductChangeTypeCell = ({ changeType }: Props) => {
    switch (changeType) {
        case ProductChangeType.NewProduct:
            return <FormattedMessage {...Messages['common.label.new']} />;
        case ProductChangeType.Updated:
            return <FormattedMessage {...Messages['common.label.edited']} />;
    }
};
