import { Navigate, useParams } from 'react-router-dom';
import { buildLink } from '../buildLink';
import { MarketId, Path } from '../paths';

type Params = {
    marketId: MarketId;
};

export const ProductListRedirection = () => {
    const params = useParams<Params>();

    return (
        <Navigate
            to={buildLink(Path.ProductList, { marketId: params.marketId })}
        />
    );
};
