import { FormattedMessage } from 'react-intl';
import { FormInstance, Typography } from 'antd';
import * as Sentry from '@sentry/react';
import { FieldError } from 'rc-field-form/es/interface';
import {
    CategorySet,
    CategorySetId,
    Feature,
    FeatureProperty,
    FeaturePropertyType,
    MarketLanguage,
    OfferChangeProductDetails,
    ProductDetails,
    ProductFeature,
    ProductFeatureProperty,
    ProductLabel,
    ProductLocalization,
    ProductStatus,
    ProductType,
    ProductTypeName,
    TemplateListItem,
    TranslationValueType
} from '@olxeu-monetization/product-catalog-api-client';
import { ErrorTooltip } from '../../components/ErrorTooltip';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { InputDurationFallbackMode } from '../../components/InputDuration';
import { FeaturePropertyValidationMap } from '../../context';
import { Messages } from '../../intl';
import { Duration } from '../../types';
import { FormListField } from './FormList';
import { getLabelValuesFromTranslation } from './LabelsFormSection/utils';
import {
    ProductFormPayload,
    ProductFormValues,
    ProductTypeTreeNode
} from './types';

const { Text } = Typography;

const labelMetadataDefaultValues = [
    {
        name: 'disabled',
        value: false
    }
];

const getPropertyFormValue = (
    feature: Feature | ProductFeature,
    property: FeatureProperty | ProductFeatureProperty,
    featurePropertyValidationMap: FeaturePropertyValidationMap
) => {
    const validation = featurePropertyValidationMap[feature.id][property.name];

    switch (validation.type) {
        case FeaturePropertyType.Number: {
            if ('value' in property) {
                return Number(property.value);
            }

            return undefined;
        }
        case FeaturePropertyType.TimeInterval: {
            if ('value' in property) {
                return Duration.fromFormatted(String(property.value));
            }

            return undefined;
        }
    }
};

type FeatureFormValue = ProductFormValues['features'][number];
type PropertyFormValue =
    ProductFormValues['features'][number]['properties'][number];

const getPropertyFormPayload = (
    feature: FeatureFormValue,
    property: PropertyFormValue,
    featurePropertyValidationMap: FeaturePropertyValidationMap
) => {
    const type = featurePropertyValidationMap[feature.id][property.name].type;

    switch (type) {
        case FeaturePropertyType.Number:
            return property.value as number;
        case FeaturePropertyType.TimeInterval:
            return (property.value as Duration).toFormatted();
    }
};

export const renderPropertyInput = (
    featureField: FormListField,
    propertyField: FormListField,
    featurePropertyValidationMap: FeaturePropertyValidationMap
) => {
    const name = [propertyField.name, 'value'];
    const propertyName = propertyField.getValue('name');
    const featureId = featureField.getValue('id');
    const validation = featurePropertyValidationMap[featureId][propertyName];

    switch (validation.type) {
        case FeaturePropertyType.Number:
            return (
                <FormInput
                    name={name}
                    label={propertyName}
                    required={validation.isRequired}
                    type={FormFieldType.Number}
                    min={validation.min}
                    max={validation.max}
                    key={propertyField.name}
                />
            );
        case FeaturePropertyType.TimeInterval:
            const min = Duration.fromFormatted(validation.min).getDefined();
            const max = Duration.fromFormatted(validation.max).getDefined();

            return (
                <FormInput
                    name={name}
                    label={propertyName}
                    required={validation.isRequired}
                    type={FormFieldType.Duration}
                    showExpirationSwitch={false}
                    fallbackMode={InputDurationFallbackMode.DisplayEmpty}
                    min={min}
                    max={max}
                    key={propertyField.name}
                    hideDisallowedSegments={true}
                />
            );
    }
};

export const mapProductCategorySetToValues = (categorySet: CategorySet) => {
    return {
        id: categorySet.id,
        name: categorySet.name,
        categoryIds: categorySet.categoryIds,
        localizations: categorySet.localizations
    };
};

export const mapProductCategorySetIdToValues = (
    categorySetId: CategorySetId,
    categorySetNamesMap: Map<CategorySetId, string>
) => {
    const name = categorySetNamesMap.get(categorySetId);

    if (name === undefined) {
        Sentry.captureException(
            `Could not resolve name for category set id: ${categorySetId}`
        );
        return {
            id: categorySetId,
            name: (
                <ErrorTooltip
                    title={
                        <FormattedMessage
                            {...Messages[
                                'common.label.category-set-no-longer-exists'
                            ]}
                            values={{ id: categorySetId }}
                        />
                    }
                >
                    <Text disabled>{categorySetId}</Text>
                </ErrorTooltip>
            )
        };
    }

    return {
        id: categorySetId,
        name
    };
};

export const mapProductFeatureToValues = (
    feature: Feature | ProductFeature,
    featurePropertyValidationMap: FeaturePropertyValidationMap
) => {
    return {
        id: feature.id,
        target: feature.target,
        properties: feature.properties.map((property) => {
            return {
                name: property.name,
                value: getPropertyFormValue(
                    feature,
                    property,
                    featurePropertyValidationMap
                )
            };
        })
    };
};

export const mapProductLabelToValues = (label: ProductLabel) => {
    return {
        templateId: label.templateId,
        featureId: label.featureId,
        values: label.values.map((value) => ({
            name: value.name,
            value: value.value,
            type: TranslationValueType.Static
        })),
        metadata: label.metadata
    };
};

export const mapTemplateToLabel = (template: TemplateListItem) => {
    const translationValues = getLabelValuesFromTranslation(
        template.defaultTranslation.value
    );

    return {
        templateId: template.id,
        defaultTranslation: template.defaultTranslation,
        featureId: undefined,
        values: translationValues.map((value) => ({
            name: value,
            value: undefined,
            type: TranslationValueType.Static
        })),
        metadata: labelMetadataDefaultValues
    };
};

export const mapProductToFormValues = (
    product: ProductDetails | OfferChangeProductDetails | undefined,
    featurePropertyValidationMap: FeaturePropertyValidationMap,
    marketLanguages: MarketLanguage[],
    categorySetNamesMap: Map<CategorySetId, string>
): ProductFormValues => {
    return {
        name: product?.name,
        type: product?.type,
        status: product?.status === ProductStatus.Enabled,
        validity: product?.validity
            ? Duration.fromFormatted(product.validity)
            : undefined,
        slots: product?.slots,
        invoicingId: product?.invoicingId,
        target: product?.target
            ? {
                  categorySets: product.target.categorySetIds.map(
                      (categorySetId) => {
                          return mapProductCategorySetIdToValues(
                              categorySetId,
                              categorySetNamesMap
                          );
                      }
                  ),
                  userIds: product.target.userIds
              }
            : {
                  categorySets: [],
                  userIds: []
              },
        features:
            product?.features.map((feature) => {
                return mapProductFeatureToValues(
                    feature,
                    featurePropertyValidationMap
                );
            }) ?? [],
        localizations:
            product?.localizations ??
            marketLanguages.map((language) => {
                return {
                    languageCode: language.code,
                    productName: undefined,
                    productDescription: undefined
                };
            }),
        labels:
            product?.labels.map((label) => {
                return mapProductLabelToValues(label);
            }) ?? []
    };
};

export const mapFormValuesToFormPayload = (
    values: ProductFormValues,
    featurePropertyValidationMap: FeaturePropertyValidationMap
): ProductFormPayload => {
    const localizations = values.localizations.reduce<
        ProductLocalization[] | undefined
    >((acc, item) => {
        if (
            item.productName === undefined ||
            item.productDescription === undefined ||
            acc === undefined
        ) {
            return undefined;
        }

        acc.push({
            languageCode: item.languageCode,
            productName: item.productName,
            productDescription: item.productDescription
        });

        return acc;
    }, []);

    const labels = values.labels?.reduce<ProductLabel[]>((acc, item) => {
        acc.push({
            templateId: item.templateId,
            values: item.values
                .filter(
                    (labelValue) =>
                        labelValue.type !== TranslationValueType.Reference
                )
                .map((value) => ({
                    name: value.name,
                    value: value.value
                })),
            metadata: item.metadata,
            featureId: item.featureId
        });

        return acc;
    }, []);

    if (
        values.name === undefined ||
        values.type === undefined ||
        values.slots === undefined ||
        localizations === undefined
    ) {
        throw new Error('Invalid input');
    }

    return {
        name: values.name,
        type: values.type,
        status: values.status ? ProductStatus.Enabled : ProductStatus.Disabled,
        validity: values.validity?.toFormatted(),
        slots: values.slots,
        invoicingId: values.invoicingId,
        target: {
            categorySetIds: values.target.categorySets.map((categorySet) => {
                return categorySet.id;
            }),
            userIds: values.target.userIds ?? []
        },
        localizations,
        features: values.features.map((feature) => {
            return {
                id: feature.id,
                properties: feature.properties.map((property) => {
                    return {
                        name: property.name,
                        value: getPropertyFormPayload(
                            feature,
                            property,
                            featurePropertyValidationMap
                        )
                    };
                })
            };
        }),
        labels: labels ?? []
    };
};

const extractErrorIndices = (errors: FieldError[]) => {
    const indices = errors.map((error) => {
        return Number(error.name[1]);
    });

    indices.sort((a, b) => a - b);

    return indices;
};

export const getSectionErrors = (form: FormInstance, sectionName: string) =>
    form.getFieldsError().filter(({ name, errors }) => {
        return errors.length && name[0] === sectionName;
    });

export const getNextErrorTabIndex = (
    previousSectionErrors: FieldError[],
    sectionErrors: FieldError[],
    activeTabIndex: number
) => {
    const errorIndices = extractErrorIndices(sectionErrors);
    const previousIndices = extractErrorIndices(previousSectionErrors);

    const subtractedIndices = errorIndices.filter((index) => {
        return !previousIndices.includes(index);
    });

    if (subtractedIndices.length === 0) {
        return activeTabIndex;
    }

    if (subtractedIndices.includes(activeTabIndex)) {
        return activeTabIndex;
    }

    return subtractedIndices[0];
};

export const createProductTypeTree = (productTypes: ProductType[]) => {
    const productTypeMap = new Map<ProductTypeName, ProductTypeTreeNode>();

    productTypes.forEach((type) => {
        productTypeMap.set(type.name, {
            label: type.name,
            value: type.name,
            children: []
        });
    });

    const productTypesTree = productTypes.reduce<ProductTypeTreeNode[]>(
        (acc, type) => {
            const node = productTypeMap.get(type.name);
            if (type.parentName) {
                const parentType = productTypeMap.get(type.parentName);
                if (parentType && node) {
                    parentType.children = parentType.children || [];
                    parentType.children.push(node);
                    parentType.selectable = false;
                }
            } else {
                if (node) {
                    acc.push(node);
                }
            }
            return acc;
        },
        []
    );

    return productTypesTree;
};
