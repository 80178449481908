import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType } from 'antd';
import { MappedCategoryTreeItem } from '../../../components/CategorySetSections/CategoriesSection/utils';
import { renderSearchIcon, SearchFilter } from '../../../components/Table';
import { Messages } from '../../../intl';
import { MappedColumnFilterItem, Tree } from '../../../utils';

export interface Filters {
    title?: string;
}

export type TableDataSource = Tree<
    MappedColumnFilterItem<
        Omit<MappedCategoryTreeItem, 'children' | 'text' | 'value'>
    >
>;

export type TableFilters = {
    title: string[] | null;
};

interface Options {
    filters: Filters;
    intl: IntlShape;
}

export const buildTableColumns = ({
    filters,
    intl
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'title',
            dataIndex: 'title',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.title ? [filters.title] : []
        }
    ];
};
