import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedRoute, Button } from 'antd';
import {
    Category,
    CategorySetId,
    Feature,
    MarketLanguage,
    ProductDetails,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { OfferChangeCreationDrawer } from '../../components/OfferChangeCreationDrawer';
import { FeaturePropertyValidationMap } from '../../context';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';
import { ProductFormLayout } from '../ProductFormLayout';
import { ProductFormPayload } from '../ProductFormLayout/types';

interface Props {
    product: ProductDetails;
    marketId: MarketId;
    breadcrumbRoutes: AugmentedRoute[];
    productTypes: ProductType[];
    features: Feature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
    marketLanguages: MarketLanguage[];
    categories: Category[];
    categorySetNamesMap: Map<CategorySetId, string>;
    categorySetsTotalCount: number;
}

export const ProductEditLayout = ({
    product,
    marketId,
    breadcrumbRoutes,
    productTypes,
    features,
    featurePropertyValidationMap,
    marketLanguages,
    categories,
    categorySetsTotalCount,
    categorySetNamesMap
}: Props) => {
    const intl = useIntl();
    const [editedProduct, setEditedProduct] = useState<ProductFormPayload>();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleSubmit = (values: ProductFormPayload) => {
        setEditedProduct(values);
        setDrawerOpen(true);
    };

    return (
        <>
            <ProductFormLayout
                title={intl.formatMessage(
                    Messages['common.label.edit-product']
                )}
                product={product}
                breadcrumbRoutes={breadcrumbRoutes}
                productTypes={productTypes}
                features={features}
                marketLanguages={marketLanguages}
                categories={categories}
                categorySetNamesMap={categorySetNamesMap}
                categorySetsTotalCount={categorySetsTotalCount}
                featurePropertyValidationMap={featurePropertyValidationMap}
                renderSubmitButton={() => (
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        data-testid="edit-product-button"
                    >
                        {intl.formatMessage(Messages['common.button.save'])}
                    </Button>
                )}
                onSubmit={handleSubmit}
            />
            {editedProduct && (
                <OfferChangeCreationDrawer
                    marketId={marketId}
                    editedProductId={product.id}
                    editedProduct={editedProduct}
                    visible={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                />
            )}
        </>
    );
};
