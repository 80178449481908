import { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Form, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { Section } from '../../components/Section';
import { ConfigContext } from '../../context';
import { Messages } from '../../intl';
import { getLanguageName, useChangeObserver } from '../../utils';
import { FormList } from './FormList';
import { getNextErrorTabIndex, getSectionErrors } from './helpers';

const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${({ theme }) => theme.colors.validationError};
    margin-left: ${({ theme }) => theme.space[8]};
`;

export const LocalizationsFormSection = () => {
    const [activeKey, setActiveKey] = useState(0);
    const { formatMessage } = useIntl();
    const config = useContext(ConfigContext);
    const form = Form.useFormInstance();

    const localizationErrors = getSectionErrors(form, 'localizations');

    const shouldDisplayErrorForLanguage = useCallback(
        (langIndex) =>
            localizationErrors.some((error) => error.name[1] === langIndex),
        [localizationErrors]
    );

    useChangeObserver(
        ([previousLocalizationErrors]) => {
            setActiveKey((currentTabKey) => {
                return getNextErrorTabIndex(
                    previousLocalizationErrors,
                    localizationErrors,
                    currentTabKey
                );
            });
        },
        [localizationErrors]
    );

    return (
        <Section
            title={formatMessage(Messages['common.label.locales'])}
            data-testid="product-locales"
        >
            <FormList name="localizations">
                {(fields) => {
                    const localizationTabs = fields.map((field) => ({
                        label: (
                            <span
                                data-cy={`product-locales-tab[${field.getValue(
                                    'languageCode'
                                )}]`}
                            >
                                {getLanguageName(
                                    field.getValue('languageCode'),
                                    config.intl.locale
                                )}
                                {shouldDisplayErrorForLanguage(field.name) && (
                                    <ErrorIcon />
                                )}
                            </span>
                        ),
                        key: String(field.key),
                        forceRender: true,
                        children: (
                            <Col
                                span={8}
                                data-cy={`product-locales-tab-content[${field.getValue(
                                    'languageCode'
                                )}]`}
                            >
                                <FormInput
                                    name={[field.name, 'productName']}
                                    label={formatMessage(
                                        Messages['common.label.product-name']
                                    )}
                                    required={true}
                                    type={FormFieldType.Input}
                                />
                                <FormInput
                                    name={[field.name, 'productDescription']}
                                    label={formatMessage(
                                        Messages[
                                            'common.label.product-description'
                                        ]
                                    )}
                                    required={true}
                                    type={FormFieldType.Input}
                                />
                            </Col>
                        )
                    }));

                    return (
                        <Tabs
                            type="card"
                            activeKey={String(activeKey)}
                            onChange={(key) => setActiveKey(Number(key))}
                            items={localizationTabs}
                            className="tabs-with-validation-icon"
                        />
                    );
                }}
            </FormList>
        </Section>
    );
};
