import { FormattedMessage } from 'react-intl';
import { AugmentedColumnsType } from 'antd';
import { LabelMetadataValue } from '@olxeu-monetization/product-catalog-api-client';
import {
    FormFieldType,
    FormInput
} from '../../../../components/FormInput/FormInput';
import { Messages } from '../../../../intl';

export enum LabelMetadataValueType {
    String = 'string',
    Boolean = 'boolean'
}

export type TableDataSource = {
    key: string;
    name: number;
    value: LabelMetadataValue;
    type: LabelMetadataValueType;
};

export type TableFilters = {
    key: string[] | null;
    value: string[] | null;
};

export const buildTableColumns = (): AugmentedColumnsType<
    TableDataSource,
    TableFilters
> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.key']} />,
            key: 'key',
            dataIndex: 'key'
        },
        {
            title: <FormattedMessage {...Messages['common.label.value']} />,
            key: 'value',
            dataIndex: 'value',
            align: 'right',
            render: (value, record) => {
                if (record.type === LabelMetadataValueType.Boolean) {
                    return (
                        <FormInput
                            name={[record.name, 'value']}
                            label={undefined}
                            required={false}
                            type={FormFieldType.Switch}
                            noStyle={true}
                        />
                    );
                }
                return value;
            }
        }
    ];
};
