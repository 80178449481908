import { ColumnFilterItem } from 'antd/lib/table/interface';
import groupBy from 'lodash/groupBy';
import {
    CategorySetId,
    Feature,
    OfferChangeId,
    Product,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { buildCategorySets } from '../../helpers';
import { buildLink, MarketId, Path } from '../../routing';
import { omitNodesWithSelectedDescendants } from '../../utils';
import { createProductTypeTree } from '../ProductFormLayout/helpers';
import { ProductTypeTreeNode } from '../ProductFormLayout/types';
import { ActionType, TableFilters } from './columns';

export const buildFeatureOptions = (features: Feature[]) => {
    const targetGroups = groupBy(features, 'target');
    const targets = Object.entries(targetGroups);

    return targets.map(([target, features]) => ({
        value: target,
        text: target,
        children: features.map((item) => ({
            value: item.id,
            text: item.id,
            children: []
        }))
    }));
};

export const buildTypeOptions = (productTypes: ProductType[]) => {
    const tree = createProductTypeTree(productTypes);

    const mapNode = (node: ProductTypeTreeNode): ColumnFilterItem => ({
        value: node.value,
        text: node.label,
        children: node.children?.map(mapNode)
    });

    const types = tree.map(mapNode);

    return types;
};

interface OpenActionLinkOptions {
    type: ActionType.Open;
}

interface EditActionLinkOptions {
    type: ActionType.Edit;
    offerChangeId: OfferChangeId;
}

export type ActionLinkOptions = OpenActionLinkOptions | EditActionLinkOptions;

const buildActionLink = (
    product: Product,
    marketId: MarketId,
    options: ActionLinkOptions
) => {
    switch (options.type) {
        case ActionType.Open:
            return buildLink(Path.ProductDetails, {
                id: product.id,
                marketId
            });
        case ActionType.Edit:
            return buildLink(Path.OfferChangeProductEdit, {
                id: options.offerChangeId,
                productId: product.id,
                marketId
            });
    }
};

export const buildProductsDataSource = (
    products: Product[],
    categorySetNamesMap: Map<CategorySetId, string>,
    marketId: MarketId,
    actionOptions: ActionLinkOptions
) => {
    return products.map((product) => {
        return {
            key: product.id,
            name: product.name,
            lockedBy: product.lockedBy,
            type: product.type,
            status: product.status,
            categorySets: buildCategorySets(
                product.target.categorySetIds,
                categorySetNamesMap
            ),
            features: product.features,
            actionLink: buildActionLink(product, marketId, actionOptions)
        };
    });
};

export const ProductFiltersDefaultValues = {
    search: undefined,
    types: undefined,
    status: undefined,
    categorySetIds: undefined,
    featureIds: undefined
};

export const mapTableFiltersToFilters = (
    tableFilters: TableFilters,
    featureOptions: ColumnFilterItem[]
) => {
    return {
        search: tableFilters.name?.[0],
        types: tableFilters.types ?? [],
        categorySetIds: tableFilters.categorySets ?? [],
        featureIds: omitNodesWithSelectedDescendants(
            featureOptions,
            tableFilters.features ?? [],
            'value'
        )
    };
};
