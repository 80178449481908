import { useIntl } from 'react-intl';
import { AugmentedRoute, Button, Col, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { MarketLanguage } from '@olxeu-monetization/product-catalog-api-client';
import { BottomBar } from '../../components/BottomBar';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { PageHeader } from '../../components/PageHeader';
import { Section } from '../../components/Section';
import { Messages } from '../../intl';
import { getLanguageLabel, mapFormValuesToFormPayload } from './helpers';
import { TemplateFormPayload, TemplateFormValues } from './types';

interface Props {
    title: string;
    breadcrumbRoutes: AugmentedRoute[];
    languages: MarketLanguage[];
    defaultLanguage?: MarketLanguage;
    initialValues?: TemplateFormValues;
    onSubmit?: (template: TemplateFormPayload) => void;
    submitDisabled: boolean;
}

export const TemplateFormLayout = ({
    title,
    breadcrumbRoutes,
    languages,
    defaultLanguage,
    initialValues,
    onSubmit,
    submitDisabled
}: Props) => {
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();

    const isEdition = initialValues !== undefined;

    const handleSubmit = (values: TemplateFormValues) => {
        const templatePayload = mapFormValuesToFormPayload(values);
        onSubmit?.(templatePayload);
    };

    const templateIdValidationRule = {
        validator: (rule: RuleObject, value: string) => {
            const pattern = /^[a-z]+(-[a-z]+)*$/;

            if (!pattern.test(value)) {
                return Promise.reject(
                    formatMessage(
                        Messages['common.error-message.template-id-rules']
                    )
                );
            }

            return Promise.resolve();
        }
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={initialValues}
            layout="horizontal"
            scrollToFirstError={true}
            data-testid="template-form-layout"
            className="form-with-enhanced-labels"
            wrapperCol={{ span: 16 }}
            labelCol={{ span: 4 }}
        >
            <PageHeader title={title} breadcrumbRoutes={breadcrumbRoutes} />

            <Section>
                <Col span={16}>
                    <FormInput
                        name="id"
                        label={formatMessage(Messages['common.label.id'])}
                        required={true}
                        validationRules={[templateIdValidationRule]}
                        type={FormFieldType.Input}
                        autoFocus={!isEdition}
                        disabled={isEdition}
                    />
                    <FormInput
                        name="name"
                        label={formatMessage(Messages['common.label.name'])}
                        required={true}
                        type={FormFieldType.Input}
                        autoFocus={isEdition}
                    />
                    <FormInput
                        name="description"
                        label={formatMessage(
                            Messages['common.label.description']
                        )}
                        required={false}
                        type={FormFieldType.Input}
                    />
                </Col>
            </Section>
            <Section
                title={formatMessage(Messages['common.label.translations'])}
            >
                <Col span={16}>
                    {defaultLanguage && (
                        <FormInput
                            key={defaultLanguage.code}
                            name={['translations', defaultLanguage.code]}
                            label={getLanguageLabel(defaultLanguage.code)}
                            required={true}
                            type={FormFieldType.Input}
                            disabled={
                                initialValues?.translations?.[
                                    defaultLanguage.code
                                ] !== undefined
                            }
                        />
                    )}
                    {languages.map((language) => (
                        <FormInput
                            key={language.code}
                            name={['translations', language.code]}
                            label={getLanguageLabel(language.code)}
                            required={false}
                            type={FormFieldType.Input}
                            disabled={
                                initialValues?.translations?.[language.code] !==
                                undefined
                            }
                        />
                    ))}
                </Col>
            </Section>
            <BottomBar>
                <Form.Item noStyle={true}>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={submitDisabled}
                    >
                        {formatMessage(Messages['common.button.save'])}
                    </Button>
                </Form.Item>
            </BottomBar>
        </Form>
    );
};
