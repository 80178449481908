import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    AugmentedRoute,
    AugmentedTableInterface,
    SpinProps,
    Table,
    TablePaginationConfig,
    Tabs,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySetId,
    Feature,
    OfferChangeId,
    Product,
    ProductId,
    ProductStatus,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { EditProductAction } from '../../components/EditProductAction';
import { PageHeader } from '../../components/PageHeader';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';
import {
    ActionType,
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import {
    buildFeatureOptions,
    buildProductsDataSource,
    buildTypeOptions,
    mapTableFiltersToFilters,
    ProductFiltersDefaultValues
} from './utils';

const ProductTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

enum ProductStatusTab {
    All = 'ALL',
    Disabled = 'DISABLED',
    Enabled = 'ENABLED'
}

const tabsDataSource = [
    {
        id: ProductStatusTab.All,
        name: <FormattedMessage {...Messages['common.label.all-products']} />
    },
    {
        id: ProductStatusTab.Enabled,
        name: <FormattedMessage {...Messages['common.label.enabled']} />
    },
    {
        id: ProductStatusTab.Disabled,
        name: <FormattedMessage {...Messages['common.label.disabled']} />
    }
];

interface Props {
    marketId: MarketId;
    products: Product[];
    breadcrumbRoutes: AugmentedRoute[];
    productsCount: number;
    productsLoading: boolean;
    categories: Category[];
    categorySetNamesMap: Map<CategorySetId, string>;
    features: Feature[];
    productTypes: ProductType[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    onFiltersChange?: (filters: Filters) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
}

export const ProductListLayout = ({
    marketId,
    products,
    breadcrumbRoutes,
    productsCount,
    productsLoading,
    categorySetNamesMap,
    features,
    productTypes,
    pageIndex,
    pageSize,
    filters,
    onPageIndexChange,
    onPageSizeChange,
    onFiltersChange
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();

    const featureOptions = useMemo(() => {
        return buildFeatureOptions(features);
    }, [features]);

    const typeOptions = useMemo(() => {
        return buildTypeOptions(productTypes);
    }, [productTypes]);

    const dataSource = useMemo(() => {
        return buildProductsDataSource(
            products,
            categorySetNamesMap,
            marketId,
            {
                type: ActionType.Open
            }
        );
    }, [products, categorySetNamesMap, marketId]);

    const handleChange = (
        pagination: TablePaginationConfig,
        tableFilters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    tableFilters,
                    featureOptions
                );

                onFiltersChange?.(nextFilters);

                break;
            }
            default:
                return;
        }
    };

    const handleStatusChange = (status: string) => {
        switch (status) {
            case ProductStatusTab.All: {
                onFiltersChange?.({ ...ProductFiltersDefaultValues });
                break;
            }
            case ProductStatusTab.Enabled: {
                onFiltersChange?.({
                    ...ProductFiltersDefaultValues,
                    status: ProductStatus.Enabled
                });
                break;
            }
            case ProductStatusTab.Disabled: {
                onFiltersChange?.({
                    ...ProductFiltersDefaultValues,
                    status: ProductStatus.Disabled
                });
                break;
            }
        }
    };

    const columns = useMemo(() => {
        const renderExtraActions = (
            productId: ProductId,
            offerChangeId?: OfferChangeId
        ) => (
            <EditProductAction
                productId={productId}
                offerChangeId={offerChangeId}
                marketId={marketId}
                size="small"
            />
        );

        return buildTableColumns({
            marketId,
            featureOptions,
            typeOptions,
            filters,
            intl,
            renderExtraActions
        });
    }, [featureOptions, typeOptions, filters, intl, marketId]);

    const statusTabs = tabsDataSource.map((tab) => ({
        label: tab.name,
        key: tab.id,
        children: (
            <ProductTable
                data-testid="products-table"
                columns={columns}
                dataSource={dataSource}
                loading={
                    productsLoading &&
                    ({ 'data-cy': 'table-loader' } as SpinProps)
                }
                pagination={{
                    total: productsCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
        )
    }));

    const activeTab = filters.status ?? ProductStatusTab.All;

    return (
        <div data-testid="product-list-layout" className="tab-navigation-list">
            <PageHeader
                title={intl.formatMessage(Messages['common.label.products'])}
                description={intl.formatMessage(
                    Messages['common.description.product-list']
                )}
                tags={
                    productsCount ? (
                        <Tag color={theme.colors.tagCounter}>
                            {intl.formatMessage(
                                Messages['common.tag.total-count'],
                                {
                                    count: productsCount
                                }
                            )}
                        </Tag>
                    ) : undefined
                }
                breadcrumbRoutes={breadcrumbRoutes}
                hideBackButton={true}
            />
            <Tabs
                onChange={handleStatusChange}
                type="card"
                activeKey={activeTab}
                items={statusTabs}
            />
        </div>
    );
};
