import { Component } from 'react';
import { ErrorOverlay } from '../../components/ErrorOverlay';

interface State {
    error?: Error;
}

export class ErrorBoundary extends Component {
    state: State = {
        error: undefined
    };

    static getDerivedStateFromError(error: Error) {
        return {
            error
        };
    }

    render() {
        if (this.state.error) {
            return <ErrorOverlay error={this.state.error} />;
        }

        return this.props.children;
    }
}
