import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import {
    createOfferChange,
    CreateOfferChangeInput,
    CreateOfferChangeOutput,
    ResultEnvelope
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalActions } from '../../components/ModalActions';
import { ModalTitle } from '../../components/ModalTitle';
import { AuthContext, ConfigContext, ModalContext } from '../../context';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { CompletionParams, usePromise } from '../../utils';

type PostOfferChangeFormData = {
    name: string;
};

interface Props {
    marketId: MarketId;
}

export const OfferChangeCreationModal = ({ marketId }: Props) => {
    const { onClose } = useContext(ModalContext);
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const intl = useIntl();
    const { formatMessage } = intl;
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleOfferChangeCreateComplete = (
        params: CompletionParams<
            unknown,
            ResultEnvelope<CreateOfferChangeOutput, unknown>
        >
    ) => {
        const offerChangeId = params.data?.data.offerChangeId;

        if (params.error || offerChangeId === undefined) {
            void message.error(
                formatMessage(
                    Messages['common.error-message.offer-change-submit']
                )
            );

            return;
        }

        void message.success(
            formatMessage(
                Messages['common.success-message.offer-change-submit']
            )
        );

        const detailsLink = buildLink(Path.OfferChangeDetails, {
            id: offerChangeId,
            marketId
        });

        navigate(detailsLink);
        onClose();
    };

    const offerChangeCreate = usePromise({
        variables: undefined as unknown as CreateOfferChangeInput,
        promiseBuilder: async (variables) => {
            return createOfferChange(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        },
        disableAutostart: true,
        onComplete: handleOfferChangeCreateComplete
    });

    const handleFormSubmit = async (values: PostOfferChangeFormData) => {
        const submitData = {
            name: values.name
        };

        await offerChangeCreate.execute(submitData);
    };

    return (
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
            <ModalTitle
                title={formatMessage(
                    Messages['common.label.offer-change-modal-title']
                )}
            />
            <Form.Item
                label={formatMessage(Messages['common.label.name'])}
                name="name"
                rules={[
                    {
                        required: true,
                        message: formatMessage(
                            Messages['common.error-message.required']
                        )
                    }
                ]}
            >
                <Input
                    placeholder={formatMessage(
                        Messages['common.placeholder.offer-change-name']
                    )}
                    data-testid="new-offer-change-name-input"
                    autoFocus={true}
                />
            </Form.Item>
            <ModalActions>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={offerChangeCreate.loading}
                >
                    {formatMessage(Messages['common.button.create'])}
                </Button>
            </ModalActions>
        </Form>
    );
};
