class QueryParam<
    T extends string | number | boolean | string[] | number[] | boolean[]
> {
    protected parseParams(values: string[]): T | undefined {
        throw new Error('Unimplemented');
    }

    public parse(value: string | string[]) {
        const values = Array.isArray(value) ? value : [value];
        return this.parseParams(values);
    }

    public serialize(value: T) {
        if (Array.isArray(value)) {
            return value.map((item) => {
                return String(item);
            });
        }

        return String(value);
    }
}

export class NumberQueryParam extends QueryParam<number> {
    protected parseParams(values: string[]) {
        const mapped = Number(values[0]);

        if (Number.isNaN(mapped)) {
            return undefined;
        }

        return mapped;
    }
}

export class NumberArrayQueryParam extends QueryParam<number[]> {
    protected parseParams(values: string[]) {
        return values.flatMap((value) => {
            const mapped = Number(value);

            if (Number.isNaN(mapped)) {
                return [];
            }

            return [mapped];
        });
    }
}

export class StringQueryParam extends QueryParam<string> {
    protected parseParams(values: string[]) {
        return values[0];
    }
}

export class StringArrayQueryParam extends QueryParam<string[]> {
    protected parseParams(values: string[]) {
        return values;
    }
}

export class BooleanQueryParam extends QueryParam<boolean> {
    protected parseParams(values: string[]) {
        return {
            [String(true)]: true,
            [String(false)]: false
        }[values[0]];
    }
}

export class BooleanArrayQueryParam extends QueryParam<boolean[]> {
    protected parseParams(values: string[]) {
        return values.flatMap((value) => {
            const mapped = {
                [String(true)]: true,
                [String(false)]: false
            }[value];

            if (mapped === undefined) {
                return [];
            }

            return [mapped];
        });
    }
}

export class EnumQueryParam<T extends string> extends QueryParam<T> {
    protected parseParams(values: string[]) {
        const value = values[0];

        if ((this.options as readonly string[]).includes(value)) {
            return value as T;
        }

        return undefined;
    }

    constructor(private options: readonly T[]) {
        super();
    }
}

export class EnumArrayQueryParam<T extends string> extends QueryParam<T[]> {
    protected parseParams(values: string[]) {
        return values.flatMap((value) => {
            if (!(this.options as readonly string[]).includes(value)) {
                return [];
            }

            return [value as T];
        });
    }

    constructor(private options: readonly T[]) {
        super();
    }
}
