import { ReactNode, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'antd';
import { ModalContext } from '../../context';

interface Props {
    children?: ReactNode;
}

export const ModalContextProvider = ({ children }: Props) => {
    const [modalContent, setModalContent] = useState<ReactNode>();
    const [modalOptions, setModalOptions] = useState<ModalProps>();

    const context = useMemo(() => {
        return {
            show: (content: ReactNode, props?: Partial<ModalProps>) => {
                setModalContent(content);
                setModalOptions((options) => ({
                    ...props,
                    ...options,
                    open: true
                }));
            },
            onClose: () => {
                setModalOptions((options) => ({
                    ...options,
                    open: false
                }));
            }
        };
    }, []);

    const handleAfterClose = () => {
        setModalContent(undefined);
        setModalOptions(undefined);
    };

    const handleOnCancel = () => {
        context.onClose();
    };

    return (
        <ModalContext.Provider value={context}>
            {children}
            {modalContent && (
                <Modal
                    afterClose={handleAfterClose}
                    onCancel={handleOnCancel}
                    {...modalOptions}
                >
                    {modalContent}
                </Modal>
            )}
        </ModalContext.Provider>
    );
};
