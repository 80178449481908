import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    AugmentedColumnsType,
    AugmentedTableInterface,
    Button,
    Space,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import { Category } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import {
    getHighlightedData,
    MappedColumnFilterItem,
    Tree
} from '../../../utils';
import { Section } from '../../Section';
import { renderSearchIcon, SearchFilter } from '../../Table';
import { buildDataSource, MappedCategoryTreeItem } from './buildDataSource';
import { getExpandableCategoryKeys } from './utils';

type TableDataSource = Tree<
    MappedColumnFilterItem<
        Omit<Tree<MappedCategoryTreeItem>, 'text' | 'children' | 'value'>
    >
>;

type TableFilters = {
    category: string[] | null;
};

const CategoriesSectionTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    categories: Category[];
    categoryIds: number[];
}

export const CategoriesSection = ({ categories, categoryIds }: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const dataSource = useMemo(() => {
        return buildDataSource(categoryIds, categories);
    }, [categories, categoryIds]);

    const treeData = useMemo(
        () =>
            getHighlightedData({
                roots: dataSource,
                query: searchQuery
            }),
        [dataSource, searchQuery]
    );

    const expandableCategoryKeys = useMemo(
        () => getExpandableCategoryKeys(dataSource),
        [dataSource]
    );

    const handleExpandedRowsChange = (keys: React.Key[]) =>
        setExpandedRowKeys(keys);

    const handleToggleExpandeAllRows = () => {
        setExpandedRowKeys((expandedRowKeys) =>
            expandedRowKeys.length ? [] : expandableCategoryKeys
        );
    };

    const handleOnChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'filter': {
                const query = filters.category?.[0] ?? '';
                setSearchQuery(query);
                break;
            }
            default:
                return;
        }
    };

    const columns: AugmentedColumnsType<TableDataSource, TableFilters> =
        useMemo(() => {
            return [
                {
                    title: formatMessage(Messages['common.label.categories']),
                    key: 'category',
                    dataIndex: 'title',
                    filterDropdown: (props) => (
                        <SearchFilter
                            {...props}
                            placeholder={formatMessage(
                                Messages['filters.placeholder.name-search']
                            )}
                        />
                    ),
                    filterIcon: renderSearchIcon
                }
            ];
        }, [formatMessage]);

    return (
        <Section
            title={formatMessage(Messages['common.label.categories'])}
            tags={
                <>
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.selected-of'], {
                            selected: categoryIds.length,
                            available: categories.length
                        })}
                    </Tag>
                </>
            }
            data-testid="categories-section"
        >
            <Space direction="vertical" size="large">
                {categoryIds.length > 0 && (
                    <Button onClick={() => handleToggleExpandeAllRows()}>
                        {expandedRowKeys.length
                            ? formatMessage(
                                  Messages['common.button.collapse-all']
                              )
                            : formatMessage(
                                  Messages['common.button.expand-all']
                              )}
                    </Button>
                )}
                <CategoriesSectionTable
                    columns={columns}
                    dataSource={treeData}
                    scroll={{ y: theme.sizesRaw.categoriesTableMaxHeight }}
                    expandable={{
                        expandedRowKeys
                    }}
                    onRow={(record: TableDataSource) =>
                        ({
                            'data-cy': `categories-table-row[${record.key}]`
                        } as React.HTMLAttributes<HTMLElement>)
                    }
                    onExpandedRowsChange={handleExpandedRowsChange}
                    onChange={handleOnChange}
                />
            </Space>
        </Section>
    );
};
