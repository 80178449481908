import { ReactNode } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

type ActionBarWrapperProps = {
    justify?: 'start' | 'center' | 'end';
};

const ActionsBarWrapper = styled(Space)<ActionBarWrapperProps & SpaceProps>`
    width: 100%;
    justify-content: ${({ justify }) => justify};
    ${space};
`;

interface Props extends SpaceProps {
    children: ReactNode;
    justify?: 'start' | 'center' | 'end';
}

export const ActionsBar = ({ children, ...rest }: Props) => (
    <ActionsBarWrapper {...rest}>{children}</ActionsBarWrapper>
);
