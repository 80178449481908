import { ReactNode } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Space } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { DataNode } from 'antd/lib/tree';
import {
    CategorySetId,
    FeatureId,
    FeatureTarget,
    ProductChangeType,
    ProductId,
    ProductStatus
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import { ProductStatusTag } from '../StatusTag';
import {
    ProductChangeTypeCell,
    ProductFeaturesCell,
    renderSearchIcon,
    SearchFilter,
    TreeSelectFilter
} from '../Table';
import { CategorySetsFilter } from '../Table/CategorySetsFilter';
import { ProductCategorySetsCell } from '../Table/ProductCategorySetsCell';

export interface Filters {
    search?: string;
    changeType?: ProductChangeType;
    types?: string[];
    status?: ProductStatus;
    categorySetIds?: CategorySetId[];
    featureIds?: FeatureId[];
}

export type TableDataSource = {
    key: string;
    changeType: ProductChangeType;
    name: string;
    type: string;
    status: ProductStatus;
    categorySets: DataNode[];
    features: {
        id: FeatureId;
        target: FeatureTarget;
    }[];
    detailsLink: string;
};

export type TableFilters = {
    changeType: ProductChangeType[] | null;
    name: string[] | null;
    types: string[] | null;
    status: ProductStatus[] | null;
    categorySets: CategorySetId[] | null;
    features: FeatureId[] | null;
    actions: null;
};

interface Options {
    featureOptions?: ColumnFilterItem[];
    typeOptions?: ColumnFilterItem[];
    filters: Filters;
    intl: IntlShape;
    renderExtraActions?: (productId: ProductId) => ReactNode;
}

export const buildTableColumns = ({
    featureOptions,
    typeOptions,
    filters,
    intl,
    renderExtraActions
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: (
                <FormattedMessage {...Messages['common.label.change-type']} />
            ),
            key: 'changeType',
            dataIndex: 'changeType',
            render: (changeType) => (
                <ProductChangeTypeCell changeType={changeType} />
            ),
            filters: [
                {
                    value: ProductChangeType.NewProduct,
                    text: <FormattedMessage {...Messages['common.label.new']} />
                },
                {
                    value: ProductChangeType.Updated,
                    text: (
                        <FormattedMessage
                            {...Messages['common.label.edited']}
                        />
                    )
                }
            ],
            filterMultiple: false,
            filteredValue: filters.changeType ? [filters.changeType] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.search ? [filters.search] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.type']} />,
            key: 'types',
            dataIndex: 'type',
            filterDropdown: (props) => <TreeSelectFilter {...props} />,
            filters: typeOptions,
            filterMultiple: true,
            filteredValue: filters.types ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.status']} />,
            key: 'status',
            dataIndex: 'status',
            render: (status) => <ProductStatusTag status={status} />,
            filters: [
                {
                    value: ProductStatus.Enabled,
                    text: (
                        <FormattedMessage
                            {...Messages['common.label.enabled']}
                        />
                    )
                },
                {
                    value: ProductStatus.Disabled,
                    text: (
                        <FormattedMessage
                            {...Messages['common.label.disabled']}
                        />
                    )
                }
            ],
            filterMultiple: false,
            filteredValue: filters.status ? [filters.status] : []
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.category-sets']} />
            ),
            key: 'categorySets',
            dataIndex: 'categorySets',
            render: (categorySets) => (
                <ProductCategorySetsCell categorySets={categorySets} />
            ),
            filterDropdown: (props) => (
                <CategorySetsFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.category-set-search']
                    )}
                />
            ),
            filteredValue: filters.categorySetIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.features']} />,
            key: 'features',
            dataIndex: 'features',
            render: (features) => <ProductFeaturesCell features={features} />,
            filterDropdown: (props) => (
                <TreeSelectFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.features-search']
                    )}
                />
            ),
            filters: featureOptions,
            filteredValue: filters.featureIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'detailsLink',
            align: 'right',
            render: (detailsLink: string, row: TableDataSource) => (
                <Space>
                    <Link to={detailsLink}>
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.open']}
                            />
                        </Button>
                    </Link>
                    {renderExtraActions?.(row.key)}
                </Space>
            )
        }
    ];
};
