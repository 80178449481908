import { useRef } from 'react';

export const useCurrentFunction = <T extends unknown[], U>(
    baseFunction: (...args: T) => U
) => {
    const baseFunctionRef = useRef(baseFunction);
    baseFunctionRef.current = baseFunction;

    const wrapperFunctionRef = useRef((...args: T) => {
        return baseFunctionRef.current(...args);
    });

    return wrapperFunctionRef.current;
};
