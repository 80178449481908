import { createContext } from 'react';
import { OktaMarketConfig } from '../providers';

interface ServicesConfig {
    productCatalogService: {
        baseUrl: string;
    };
    upsService: {
        baseUrl: string;
    };
    oktaAuthService: {
        marketConfigs: OktaMarketConfig[];
        issuer: string;
        redirectUri: string;
        scopes: string[];
        pkce: boolean;
    };
}

interface ApplicationConfig {
    environment: string;
    featureFlags: string[];
}

interface IntlConfig {
    locale: string;
}

export interface ConfigContextType {
    services: ServicesConfig;
    application: ApplicationConfig;
    intl: IntlConfig;
}

const DefaultConfigContext: ConfigContextType = {
    services: {
        productCatalogService: {
            baseUrl: 'Unimplemented'
        },
        upsService: {
            baseUrl: 'Unimplemented'
        },
        oktaAuthService: {
            marketConfigs: [],
            issuer: 'Unimplemented',
            redirectUri: 'Unimplemented',
            scopes: [],
            pkce: true
        }
    },
    application: {
        environment: 'Unimplemented',
        featureFlags: []
    },
    intl: {
        locale: 'Unimplemented'
    }
};

export const ConfigContext =
    createContext<ConfigContextType>(DefaultConfigContext);
