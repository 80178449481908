import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    AugmentedTableInterface,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import {
    Category,
    CategoryId,
    CategorySet,
    CategorySetId,
    CategorySetType,
    getCategorySets
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalTitle } from '../../components/ModalTitle';
import { AuthContext, ConfigContext, ModalContext } from '../../context';
import { Messages } from '../../intl';
import { buildCategoryOptions } from '../../layouts/CategorySetListLayout/utils';
import { usePromise } from '../../utils';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';
import { buildCategorySetsDataSource, mapTableFiltersToFilters } from './utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const CategorySetTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Params {
    name?: string;
    categoryIds?: CategoryId[];
    limit: number;
    page: number;
}

interface Props {
    types: CategorySetType[];
    categories: Category[];
    selectedIds: string[];
    onSelect?: (categorySet: CategorySet) => void;
}

export const CategorySetSelectionModal = ({
    types,
    categories,
    selectedIds,
    onSelect
}: Props) => {
    const { onClose } = useContext(ModalContext);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const [params, setParams] = useState<Params>({
        page: PaginationInitialPageIndex,
        limit: PaginationDefaultPageSize
    });

    const categorySetsResult = usePromise({
        variables: {
            types,
            name: params.name,
            categoryIds: params.categoryIds,
            limit: params.limit,
            offset: params.page * params.limit
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetsLoading =
        categorySetsResult.loading && !categorySetsResult.data;

    const categorySets = categorySetsResult.data?.data;
    const categorySetsCount = categorySetsResult.data?.metadata.totalCount;

    const categoryOptions = useMemo(() => {
        return buildCategoryOptions(categories);
    }, [categories]);

    const handleSelect = useCallback(
        (id: CategorySetId) => {
            const categorySet = categorySets?.find(
                (categorySet) => categorySet.id === id
            );

            if (!categorySet) {
                throw new Error('Could not resolve category set by given id');
            }

            onSelect?.(categorySet);
            onClose();
        },
        [categorySets, onSelect, onClose]
    );

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== params.page) {
                    setParams((params) => ({
                        ...params,
                        page: nextPageIndex
                    }));
                }

                if (nextPageSize !== params.limit) {
                    setParams((params) => ({
                        ...params,
                        limit: nextPageSize
                    }));
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    filters,
                    categoryOptions
                );

                setParams((params) => ({
                    ...params,
                    ...nextFilters
                }));

                break;
            }
            default:
                return;
        }
    };

    const dataSource = useMemo(() => {
        if (!categorySets) {
            return [];
        }

        return buildCategorySetsDataSource(
            categorySets,
            categories,
            selectedIds
        );
    }, [categories, categorySets, selectedIds]);

    const columns = useMemo(() => {
        const filters = {
            name: params.name,
            categoryIds: params.categoryIds
        };

        return buildTableColumns({
            categoryOptions,
            filters,
            intl,
            onSelect: handleSelect
        });
    }, [categoryOptions, handleSelect, intl, params.categoryIds, params.name]);

    return (
        <>
            <ModalTitle
                title={formatMessage(
                    Messages['common.label.select-category-set']
                )}
                tags={
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.available'], {
                            count: categorySetsCount
                        })}
                    </Tag>
                }
            />
            <CategorySetTable
                data-testid="select-category-set-table"
                columns={columns}
                dataSource={dataSource}
                loading={categorySetsLoading}
                pagination={{
                    total: categorySetsCount,
                    pageSize: params.limit,
                    current: params.page + 1
                }}
                onChange={handleChange}
            />
        </>
    );
};
