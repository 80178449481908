import { fetchData, RequestContext } from './fetchData';
import { Limit } from './models';
import {
    CreateLimitInput,
    CreateLimitOutput,
    GetLimitsInput,
    GetLimitInput,
    LimitListOutput,
    UpdateLimitInput,
    UpdateLimitOutput
} from './types';

export const getLimits = (
    input: GetLimitsInput,
    context: RequestContext
): Promise<LimitListOutput> => {
    return fetchData({
        path: '/limits/configuration',
        query: { ...input },
        context
    });
};

export const getLimit = (
    input: GetLimitInput,
    context: RequestContext
): Promise<Limit> => {
    return fetchData({
        path: `/limits/configuration/${input.id}`,
        context
    });
};

export const createLimit = (
    input: CreateLimitInput[],
    context: RequestContext
): Promise<CreateLimitOutput[]> => {
    return fetchData({
        path: `/limits/configurations`,
        baseInit: {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json'
            }
        },
        context
    });
};

export const updateLimit = (
    input: UpdateLimitInput[],
    context: RequestContext
): Promise<UpdateLimitOutput> => {
    const { id, ...body } = input[0];
    return fetchData({
        path: `/limits/configuration/${id}`,
        baseInit: {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        },
        context
    });
};
