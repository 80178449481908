import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    getCategorySets,
    getProduct,
    ProductId
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext,
    FeaturesDataContext,
    MarketLanguagesDataContext,
    ProductTypesDataContext
} from '../../context';
import { getCategorySetNames } from '../../helpers';
import { Messages } from '../../intl';
import { ProductEditLayout } from '../../layouts/ProductEditLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../ProductDetailsPage';

type Params = PathParams[Path.ProductEdit];

const buildBreadcrumbsRoutes = (
    productId: ProductId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(productId, marketId, intl),
        {
            path: buildLink(Path.ProductEdit, { id: productId, marketId }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.edit-product']
            )
        }
    ];
};

export const ProductEditPage = () => {
    const intl = useIntl();

    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);
    const marketLanguagesData = useContext(MarketLanguagesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const categoriesData = useContext(CategoriesDataContext);

    const params = useParams<Params>();

    const productVariables = {
        id: params.id
    };

    const productResult = usePromise({
        variables: productVariables,
        promiseBuilder: async (variables) => {
            return getProduct(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetsResult = usePromise({
        variables: {
            offset: 0,
            limit: 0
        },
        promiseBuilder: async (variables) => {
            return getCategorySets(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: productResult.data?.data.target.categorySetIds
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables, {
                        baseUrl: config.services.productCatalogService.baseUrl,
                        accessToken: await auth.getAccessToken()
                    });
                }
            });
        },
        disableAutostart: !productResult.data
    });

    const marketLanguagesLoading =
        marketLanguagesData.loading && !marketLanguagesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productLoading = productResult.loading && !productResult.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const categorySetsLoading =
        categorySetsResult.loading && !categorySetsResult.data;
    const categoriesLoading = categoriesData.loading && !categoriesData.data;

    const loading =
        marketLanguagesLoading ||
        featuresLoading ||
        productLoading ||
        productTypesLoading ||
        categorySetsLoading ||
        categoriesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const marketLanguages = marketLanguagesData.data?.current;
    const productTypes = productTypesData.data;
    const features = featuresData.data;
    const featurePropertyValidationMap =
        featuresData.featurePropertyValidationMap;
    const categorySetsTotalCount = categorySetsResult.data?.metadata.totalCount;
    const categorySetNamesMap = categorySetNamesResult.data;
    const categories = categoriesData.data;
    const product = productResult.data?.data;

    if (
        !marketLanguages ||
        !features ||
        !featurePropertyValidationMap ||
        !product ||
        !productTypes ||
        !categorySetsTotalCount ||
        !categorySetNamesMap ||
        !categories
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        product.id,
        params.marketId,
        intl
    );

    return (
        <ProductEditLayout
            product={product}
            marketId={params.marketId}
            breadcrumbRoutes={breadcrumbRoutes}
            productTypes={productTypes}
            features={features}
            marketLanguages={marketLanguages}
            categories={categories}
            categorySetNamesMap={categorySetNamesMap}
            categorySetsTotalCount={categorySetsTotalCount}
            featurePropertyValidationMap={featurePropertyValidationMap}
        />
    );
};
