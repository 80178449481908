import queryString, { StringifiableRecord } from 'query-string';
import { HTTPError } from './error';

export interface RequestContext {
    baseUrl: string;
    accessToken?: string;
}

const getAuthHeaders = (context?: RequestContext) => {
    if (!context?.accessToken) {
        return undefined;
    }

    return {
        Authorization: `Bearer ${context.accessToken}`
    };
};

interface FetchDataOptions {
    path: string;
    context: RequestContext;
    baseInit?: Parameters<typeof fetch>[1];
    query?: StringifiableRecord;
}

export const fetchData = async <RequestResult>({
    path,
    context,
    baseInit,
    query
}: FetchDataOptions) => {
    const authHeaders = getAuthHeaders(context);

    const url = queryString.stringifyUrl({
        url: `${context.baseUrl}${path}`,
        query
    });

    const init = {
        ...baseInit,
        headers: {
            ...baseInit?.headers,
            ...authHeaders
        }
    };

    const response = await fetch(url, init);

    if (!response.ok) {
        const { status, statusText } = response;
        const reason = await response.text();

        throw new HTTPError(statusText, status, reason);
    }

    return (await response.json()) as RequestResult;
};
