import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const ShortListWrapper = styled.div<SpaceProps>`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${space}
`;

interface Props<T, K extends keyof T> extends SpaceProps {
    items: T[];
    property: K;
    limit?: number;
}

export const ShortList = <T, K extends keyof T>({
    items,
    property,
    limit = 3,
    ...rest
}: Props<T, K>) => {
    const shortList = items.slice(0, limit).map((item, index) => {
        const itemValue = item[property];
        const isLastItem = index === Math.min(items.length, limit) - 1;
        const separator = isLastItem ? null : ', ';
        return (
            <React.Fragment key={index}>
                {itemValue}
                {separator}
                {limit < items.length && isLastItem ? '…' : ''}
            </React.Fragment>
        );
    });

    return <ShortListWrapper {...rest}>{shortList}</ShortListWrapper>;
};
