import { useContext, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthContext } from '../context';
import { routes } from './routes';
import { buildRoutes } from './utils';

export const AuthorizedRoutes = () => {
    const { authenticated } = useContext(AuthContext);

    const resolvedRoutes = useMemo(() => {
        return buildRoutes(routes, authenticated);
    }, [authenticated]);

    const routeElement = useRoutes(resolvedRoutes);

    return <>{routeElement}</>;
};
