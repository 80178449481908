import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    buildSelectionList,
    buildSelectionMap,
    buildTreeMap,
    searchTree,
    traverseTree,
    TreeTraversalMethod
} from '../utils';

export const buildCheckedState = (
    roots: ColumnFilterItem[],
    selectedIds: (string | number)[]
) => {
    const selectionMap = buildSelectionMap(selectedIds);
    const halfSelectionMap = new Map<string, boolean>();

    roots.forEach((root) => {
        traverseTree(
            [root],
            TreeTraversalMethod.DFSPreOrder,
            (node, parent) => {
                if (parent && selectionMap.get(String(parent.value))) {
                    selectionMap.set(String(node.value), true);
                }
            }
        );

        traverseTree(
            [root],
            TreeTraversalMethod.DFSPostOrder,
            (node, parent) => {
                if (!node.children || node.children.length === 0) {
                    return;
                }

                const allSelected = node.children.every((child) => {
                    return selectionMap.get(String(child.value));
                });

                if (allSelected) {
                    selectionMap.set(String(node.value), true);
                    return;
                }

                const someSelected = node.children.some((child) => {
                    const checked = selectionMap.get(String(child.value));
                    const halfChecked = halfSelectionMap.get(
                        String(child.value)
                    );

                    return checked || halfChecked;
                });

                if (someSelected) {
                    halfSelectionMap.set(String(node.value), true);
                }
            }
        );
    });

    return {
        checked: buildSelectionList(selectionMap),
        halfChecked: buildSelectionList(halfSelectionMap)
    };
};

interface ChangeTreeNodeCheckedOptions<T, P extends keyof T, K> {
    roots: T[];
    changedNodeKey: T[P];
    changedNodeChecked: boolean;
    selectedKeys: K[];
    keyName: P;
}

export const changeTreeNodeChecked = <
    T extends { children?: T[] },
    P extends keyof T,
    K = T[P]
>({
    roots,
    changedNodeKey,
    changedNodeChecked,
    selectedKeys,
    keyName
}: ChangeTreeNodeCheckedOptions<T, P, K>) => {
    const treeMap = buildTreeMap(roots, keyName);

    const referencedNode = searchTree(
        roots,
        TreeTraversalMethod.DFSPostOrder,
        (node) => {
            return node[keyName] === changedNodeKey;
        }
    );

    if (!referencedNode) {
        return selectedKeys;
    }

    const selectionMap = buildSelectionMap(selectedKeys);

    traverseTree([referencedNode], TreeTraversalMethod.DFSPostOrder, (node) => {
        selectionMap.set(String(node[keyName]), false);
    });

    selectionMap.set(String(changedNodeKey), changedNodeChecked);

    for (
        let iterator = treeMap.get(
            treeMap.get(String(changedNodeKey))?.parentKey
        );
        iterator !== undefined;
        iterator = treeMap.get(iterator.parentKey)
    ) {
        const allChildrenChecked = iterator.childrenKeys.every((childKey) => {
            return selectionMap.get(String(childKey));
        });

        selectionMap.set(iterator[keyName], allChildrenChecked);
    }

    return buildSelectionList(selectionMap);
};
