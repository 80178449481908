import { ReactNode, useContext, useMemo } from 'react';
import { getCategories } from '@olxeu-monetization/product-catalog-api-client';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext
} from '../../context';
import { usePromise } from '../../utils';

interface Props {
    children?: ReactNode;
}

export const CategoriesDataContextProvider = ({ children }: Props) => {
    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);

    const { data, loading, error } = usePromise({
        disableAutostart: !auth.authenticated,
        variables: undefined,
        promiseBuilder: async () => {
            return getCategories({
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const context = useMemo(() => {
        return {
            data: data?.data,
            loading,
            error
        };
    }, [data?.data, error, loading]);

    return (
        <CategoriesDataContext.Provider value={context}>
            {children}
        </CategoriesDataContext.Provider>
    );
};
