import { useContext, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getOfferChanges } from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import { buildHomeBreadcrumbRoute } from '../../components/PageHeader';
import { AuthContext, ConfigContext } from '../../context';
import { Messages } from '../../intl';
import { OfferChangeListLayout } from '../../layouts/OfferChangeListLayout';
import { Filters } from '../../layouts/OfferChangeListLayout/columns';
import {
    buildLink,
    MarketId,
    Path,
    PathParams,
    QueryParamsSchemas
} from '../../routing';
import { usePromise, useQueryParams } from '../../utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const QueryParamsSchema = QueryParamsSchemas[Path.OfferChangeList];

type Params = PathParams[Path.OfferChangeList];

export const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        buildHomeBreadcrumbRoute(marketId),
        {
            path: buildLink(Path.OfferChangeList, { marketId }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.offer-changes']
            )
        }
    ];
};

export const OfferChangeListPage = () => {
    const intl = useIntl();

    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const params = useParams<Params>();

    const [queryParams, setQueryParams] = useQueryParams({
        schema: QueryParamsSchema,
        defaults: {
            page: PaginationInitialPageIndex,
            limit: PaginationDefaultPageSize
        }
    });

    const filters = useMemo(() => {
        return {
            search: queryParams.search,
            status: queryParams.status
        };
    }, [queryParams.search, queryParams.status]);

    const offerChangesResult = usePromise({
        variables: {
            ...queryParams,
            offset: queryParams.page * queryParams.limit
        },
        promiseBuilder: async (variables) => {
            return getOfferChanges(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const handlePageIndexChange = (page: number) => {
        setQueryParams({ page });
    };

    const handlePageSizeChange = (limit: number) => {
        const page = Math.floor((queryParams.page * queryParams.limit) / limit);
        setQueryParams({ page, limit });
    };

    const handleFiltersChange = (filters: Filters) => {
        setQueryParams({
            ...filters,
            page: 0
        });
    };

    const offerChangesLoading =
        offerChangesResult.loading && !offerChangesResult.data;

    if (offerChangesLoading) {
        return <Loader type="center" />;
    }

    const offerChanges = offerChangesResult.data?.data;
    const offerChangesCount = offerChangesResult.data?.metadata?.totalCount;

    if (!offerChanges || offerChangesCount === undefined) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    return (
        <OfferChangeListLayout
            marketId={params.marketId}
            offerChangesLoading={offerChangesResult.loading}
            pageIndex={queryParams.page}
            pageSize={queryParams.limit}
            offerChanges={offerChanges}
            offerChangesCount={offerChangesCount}
            breadcrumbRoutes={breadcrumbRoutes}
            onFiltersChange={handleFiltersChange}
            filters={filters}
            onPageIndexChange={handlePageIndexChange}
            onPageSizeChange={handlePageSizeChange}
        />
    );
};
