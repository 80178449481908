import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType, Button } from 'antd';
import { CategorySetId } from '@olxeu-monetization/product-catalog-api-client';
import { renderSearchIcon, SearchFilter } from '../../components/Table';
import { Messages } from '../../intl';
import { normalizeText } from '../../utils';

export interface Filters {
    search?: string;
}

export type TableDataSource = {
    index: number;
    id: CategorySetId;
    name: string;
};

export type TableFilters = {
    name: string[] | null;
    actions: null;
};

interface Options {
    intl: IntlShape;
    onRemove?: (index: number) => void;
}

export const buildTableColumns = ({
    intl,
    onRemove
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            render: (text) => text,
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            onFilter: (value, record) =>
                normalizeText(record.name).includes(
                    normalizeText(String(value))
                ),
            filterIcon: renderSearchIcon
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'index',
            align: 'right',
            render: (index: number) => (
                <Button
                    danger={true}
                    size="small"
                    onClick={() => onRemove?.(index)}
                >
                    {intl.formatMessage(Messages['common.button.remove'])}
                </Button>
            )
        }
    ];
};
