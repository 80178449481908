import { ReactNode } from 'react';
import {
    Category,
    CategoryId
} from '@olxeu-monetization/product-catalog-api-client';
import { buildTree, mapTree } from '../../../utils';

const DefaultRootCategoryId = 0;

type RequiredTree<T> = T & {
    children: RequiredTree<T>[];
};

export interface MappedCategoryTreeItem {
    value: string | number;
    text: ReactNode;
    children?: MappedCategoryTreeItem[];
}

type CategoryItem = {
    id: CategoryId;
    name: string;
    parentId: CategoryId;
};

export const buildDataSource = (
    categoryIds: number[],
    categories: Category[]
) => {
    const tree = buildTree(
        categories as CategoryItem[],
        'id',
        'parentId',
        DefaultRootCategoryId
    );
    const categoryIdsMap = new Map<number, boolean>();

    categoryIds.forEach((categoryId) => {
        categoryIdsMap.set(categoryId, true);
    });

    const dataSource = mapTree<RequiredTree<Category>, MappedCategoryTreeItem>(
        tree,
        (node, nextChildren) => {
            const contains = categoryIdsMap.get(node.id) ?? false;

            if (!contains && nextChildren.length === 0) {
                return undefined;
            }

            return {
                value: node.id,
                text: node.name,
                ...(nextChildren.length > 0 && { children: nextChildren })
            };
        }
    );

    return dataSource;
};
