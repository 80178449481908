import React, { useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { FormInstance, TabsProps } from 'antd';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { ProductLabel } from '@olxeu-monetization/product-catalog-api-client';

const type = 'DraggableTabNode';

const StyledTabs = styled(Tabs)`
    .ant-tabs-tab {
        display: flex;
    }
`;

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    index?: React.Key;
    moveNode: (dragIndex: React.Key, hoverIndex: React.Key) => void;
}

interface DraggableTabsProps extends TabsProps {
    form: FormInstance;
}

const DraggableTabNode = ({
    index,
    children,
    moveNode
}: DraggableTabPaneProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: 'dropping'
            };
        },
        drop: (item: { index: React.Key }) => {
            moveNode(item.index, index ?? '');
        }
    });

    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drop(drag(ref));

    return (
        <div
            ref={ref}
            style={{ marginRight: 24 }}
            className={isOver ? dropClassName : ''}
        >
            {children}
        </div>
    );
};

export const DraggableTabs = (props: DraggableTabsProps) => {
    const { items = [], form } = props;
    const [order, setOrder] = useState<React.Key[]>([]);

    const moveTabNode = (dragKey: React.Key, hoverKey: React.Key) => {
        const newOrder = order.slice();

        items.forEach((item) => {
            if (item.key && newOrder.indexOf(item.key) === -1) {
                newOrder.push(item.key);
            }
        });

        const dragIndex = newOrder.indexOf(dragKey);
        const hoverIndex = newOrder.indexOf(hoverKey);

        newOrder.splice(dragIndex, 1);
        newOrder.splice(hoverIndex, 0, dragKey);

        const fields = form.getFieldValue('labels') as ProductLabel[];

        const sortedFields = newOrder.map((key) =>
            fields.find((field) => field.templateId === key)
        );

        setOrder(newOrder);

        form.setFieldsValue({
            labels: sortedFields
        });
    };

    const renderTabBar: TabsProps['renderTabBar'] = (
        tabBarProps,
        DefaultTabBar
    ) => (
        <DefaultTabBar {...tabBarProps}>
            {(node) => (
                <DraggableTabNode
                    key={node.key}
                    index={node.key ?? ''}
                    moveNode={moveTabNode}
                >
                    {node}
                </DraggableTabNode>
            )}
        </DefaultTabBar>
    );

    const orderItems = [...items].sort((a, b) => {
        const orderA = order.indexOf(a.key);
        const orderB = order.indexOf(b.key);

        if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB;
        }
        if (orderA !== -1) {
            return -1;
        }
        if (orderB !== -1) {
            return 1;
        }

        const ia = items.indexOf(a);
        const ib = items.indexOf(b);

        return ia - ib;
    });

    return (
        <DndProvider backend={HTML5Backend}>
            <StyledTabs
                renderTabBar={renderTabBar}
                {...props}
                items={orderItems}
            />
        </DndProvider>
    );
};
