import { useIntl } from 'react-intl';
import { Form } from 'antd';
import {
    ProductFeature,
    ProductLabel
} from '@olxeu-monetization/product-catalog-api-client';
import {
    FormFieldType,
    FormInput
} from '../../../../components/FormInput/FormInput';
import { Messages } from '../../../../intl';
import { FormListField } from '../../FormList';

interface Props {
    productFeatures: ProductFeature[];
    labelField: FormListField;
}

export const ReferencedFeaturePicker = ({
    labelField,
    productFeatures
}: Props) => {
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();

    const productLabels = form.getFieldValue('labels') as ProductLabel[];

    const selectedFeatures = productLabels
        .map((label) => label.featureId)
        .filter((featureId) => featureId);

    const productFeatureOptions = productFeatures.map((feature) => ({
        label: feature.id,
        value: feature.id,
        disabled: selectedFeatures.includes(feature.id)
    }));

    return (
        <FormInput
            name={[labelField.name, 'featureId']}
            label={formatMessage(Messages['common.label.referenced-feature'])}
            required={false}
            allowClear={true}
            notFoundContent={formatMessage(
                Messages['common.label.no-feature-selected']
            )}
            type={FormFieldType.Select}
            options={productFeatureOptions}
        />
    );
};
