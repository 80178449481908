import {
    TemplateId,
    TemplateListItem
} from '@olxeu-monetization/product-catalog-api-client';

export const buildTemplatesDataSource = (
    templates: TemplateListItem[],
    selectedIds: TemplateId[]
) => {
    const selectedIdsMap = selectedIds.reduce((acc, id) => {
        acc.set(id, true);
        return acc;
    }, new Map<TemplateId, boolean>());

    return templates.map((template) => ({
        key: template.id,
        id: template.id,
        name: template.name,
        translation: template.defaultTranslation.value,
        selected: selectedIdsMap.get(template.id) ?? false
    }));
};
