import {
    CategoryId,
    LanguageCode
} from '@olxeu-monetization/product-catalog-api-client';
import {
    CategorySetId,
    LimitStatus,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { Duration } from '../../types';

export interface Localization {
    languageCode: LanguageCode;
    name: string;
}

export interface PartialCategorySet {
    id: CategorySetId;
    name: string;
    categoryIds: CategoryId[];
    localizations?: Localization[];
}

export interface LimitFormPayload {
    limit_type: LimitType;
    categories_configuration: string[];
    capacity: number;
    validity_period: string;
    labels: {
        name: Partial<Record<LanguageCode, string>>;
    };
    status: LimitStatus;
    product_category_set_id: CategorySetId;
    options: string[];
}

type LimitTypeFormValue = {
    id?: string;
    type: LimitType;
    unlimited: boolean;
    capacity: number;
    validity: Duration; // NOTE: iso-8601;
    status: boolean;
};

export type LimitTypesFormValues = {
    default: LimitTypeFormValue;
    business: LimitTypeFormValue;
    extra: LimitTypeFormValue;
};

export type LimitFormValues = {
    target: {
        categorySets: PartialCategorySet[];
    };
} & LimitTypesFormValues;

export type InitialLimitFormValues = {
    id: string;
} & LimitFormValues;

export const limitTypesSet = [
    LimitType.Default,
    LimitType.Business,
    LimitType.Extra
];
