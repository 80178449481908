import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context';
import { MarketId } from '../paths';

interface Props {
    marketId: MarketId;
}

export const AuthenticationRedirection = ({ marketId }: Props) => {
    const auth = useContext(AuthContext);

    useEffect(() => {
        auth.openAuthenticationPage(marketId);
    }, [auth, marketId]);

    return null;
};
