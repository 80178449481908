import { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Space, Tabs, Tag, Typography } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import findIndex from 'lodash/findIndex';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import {
    Feature,
    FeatureId,
    ProductFeature
} from '@olxeu-monetization/product-catalog-api-client';
import { Box } from '../../components/Box';
import { Section } from '../../components/Section';
import { FeaturePropertyValidationMap, ModalContext } from '../../context';
import { Messages } from '../../intl';
import { FeatureSelectionModal } from '../../modals/FeatureSelectionModal';
import { useChangeObserver } from '../../utils';
import { FormList } from './FormList';
import {
    getNextErrorTabIndex,
    getSectionErrors,
    mapProductFeatureToValues,
    renderPropertyInput
} from './helpers';

const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${({ theme }) => theme.colors.validationError};
    margin-left: ${({ theme }) => theme.space[8]};
`;

const FeatureTabs = styled(Tabs)`
    [role='tab'] {
        flex-grow: 1;
        text-align: left;
    }
`;

const FeatureTargetLabel = styled(Space)`
    margin: ${({ theme }) => `${theme.space[16]} 0`};

    > * {
        flex-shrink: 0;
    }
`;

interface Props {
    features: Feature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
}

export const FeaturesFormSection = ({
    features,
    featurePropertyValidationMap
}: Props) => {
    const modal = useContext(ModalContext);
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();
    const [activeKey, setActiveKey] = useState(() => {
        const featuresInitialValue = form.getFieldValue(
            'features'
        ) as ProductFeature[];
        return featuresInitialValue[0]?.id ?? '';
    });

    const featuresErrors = getSectionErrors(form, 'features');

    const shouldDisplayErrorForFeature = useCallback(
        (featureIndex) =>
            featuresErrors.some((error) => error.name[1] === featureIndex),
        [featuresErrors]
    );

    useChangeObserver(
        ([previousFeaturesErrors]) => {
            setActiveKey((currentTabKey) => {
                const selectedFeatures = form.getFieldValue(
                    'features'
                ) as ProductFeature[];

                const currentTabIndex = findIndex(
                    selectedFeatures,
                    (feature) => feature.id === currentTabKey
                );

                const index = getNextErrorTabIndex(
                    previousFeaturesErrors,
                    featuresErrors,
                    currentTabIndex
                );

                return selectedFeatures[index].id;
            });
        },
        [featuresErrors]
    );

    const handleModalShow = (
        add: (value?: unknown) => void,
        selectedIds: FeatureId[]
    ) => {
        modal.show(
            <FeatureSelectionModal
                features={features}
                selectedIds={selectedIds}
                onSelect={(feature) => {
                    const mappedFeature = mapProductFeatureToValues(
                        feature,
                        featurePropertyValidationMap
                    );

                    add(mappedFeature);
                    setActiveKey(feature.id);
                }}
            />,
            {
                footer: null,
                centered: true,
                width: 700
            }
        );
    };

    return (
        <FormList name="features">
            {(featureFields, featureListOperation, featureListName) => {
                const selectedIds = featureFields.map((field) =>
                    field.getValue('id')
                );

                const getPreviousAvailableKey = (removedId: FeatureId) => {
                    const removedIndex = selectedIds.indexOf(removedId);
                    const previousIndex = Math.max(removedIndex - 1, 0);

                    const availableKeys = selectedIds.filter(
                        (key) => key !== removedId
                    );

                    return availableKeys.length
                        ? availableKeys[previousIndex]
                        : '';
                };

                const featureTabs = featureFields.map(
                    (featureField, featureIndex) => ({
                        label: (
                            <span>
                                {featureField.getValue('id')}
                                {shouldDisplayErrorForFeature(
                                    featureField.name
                                ) && <ErrorIcon />}
                            </span>
                        ),
                        key: featureField.getValue('id'),
                        children: (
                            <Box width="min-content">
                                <FeatureTargetLabel>
                                    <Typography.Text strong>
                                        {formatMessage(
                                            Messages['common.label.target']
                                        )}
                                        :{' '}
                                    </Typography.Text>
                                    <Typography.Text code>
                                        {featureField.getValue('target')}
                                    </Typography.Text>
                                </FeatureTargetLabel>
                                <FormList
                                    name={[featureField.name, 'properties']}
                                    parentName={featureListName}
                                >
                                    {(propertyFields) =>
                                        propertyFields.map((propertyField) =>
                                            renderPropertyInput(
                                                featureField,
                                                propertyField,
                                                featurePropertyValidationMap
                                            )
                                        )
                                    }
                                </FormList>
                                <Form.Item noStyle={true}>
                                    <Button
                                        type="primary"
                                        danger={true}
                                        onClick={async () => {
                                            const lastAvailableKey =
                                                getPreviousAvailableKey(
                                                    featureField.getValue('id')
                                                );

                                            setActiveKey(lastAvailableKey);

                                            featureListOperation.remove(
                                                featureIndex
                                            );

                                            await form.validateFields();
                                        }}
                                    >
                                        {formatMessage(
                                            Messages['common.button.remove']
                                        )}
                                    </Button>
                                </Form.Item>
                            </Box>
                        )
                    })
                );

                return (
                    <Section
                        title={formatMessage(Messages['common.label.features'])}
                        tags={
                            <Tag color={theme.colors.tagCounter}>
                                {formatMessage(
                                    Messages['common.tag.selected-of'],
                                    {
                                        selected: featureFields.length,
                                        available: features.length
                                    }
                                )}
                            </Tag>
                        }
                        extra={[
                            <Button
                                key="add-feature"
                                icon={<PlusOutlined />}
                                onClick={() =>
                                    handleModalShow(
                                        featureListOperation.add,
                                        selectedIds
                                    )
                                }
                            >
                                {formatMessage(
                                    Messages['common.button.add-feature']
                                )}
                            </Button>
                        ]}
                        data-testid="product-features"
                    >
                        <FeatureTabs
                            tabPosition="left"
                            type="card"
                            items={featureTabs}
                            activeKey={activeKey}
                            onChange={(key) => setActiveKey(key)}
                            className="tabs-with-validation-icon"
                        />
                    </Section>
                );
            }}
        </FormList>
    );
};
