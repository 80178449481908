import { TableFilters } from './columns';
import { Template } from './TemplateListLayout';

export const buildTemplatesData = (templates: Template[]) =>
    templates.map((template) => ({
        id: template.id,
        key: template.id,
        name: template.name,
        defaultTranslation: template.defaultTranslation
    }));

export const mapTableFiltersToFilters = (filters: TableFilters) => {
    return {
        search: filters.name?.[0]
    };
};
