import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Messages } from '../../../intl';
import {
    ChangeTag,
    ProductEntryNew,
    ProductEntryPrevious
} from '../../ProductEntryDiff';

interface Props {
    id: string;
    value: string | number | ReactNode;
    previousValue: string | number | ReactNode;
}

export const ProductPropertyDiff = (props: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    return (
        <div>
            <ProductEntryPrevious>
                <span
                    data-testid={`product-diff-properties-previous-value[${props.id}]`}
                >
                    {props.previousValue}
                </span>
                <ChangeTag color={theme.colors.pink} m={0} ml={8}>
                    {formatMessage(Messages['common.tag.previous'])}
                </ChangeTag>
            </ProductEntryPrevious>
            <ProductEntryNew>
                <span
                    data-testid={`product-diff-properties-new-value[${props.id}]`}
                >
                    {props.value}
                </span>
                <ChangeTag color={theme.colors.green} m={0} ml={8}>
                    {formatMessage(Messages['common.tag.new'])}
                </ChangeTag>
            </ProductEntryNew>
        </div>
    );
};
