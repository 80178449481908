import { ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    getAvailableLanguages,
    getCurrentMarketLanguages,
    MarketLanguage
} from '@olxeu-monetization/product-catalog-api-client';
import {
    AuthContext,
    ConfigContext,
    MarketLanguagesDataContext
} from '../../context';
import { MarketId } from '../../routing';
import { usePromise } from '../../utils';

type Params = {
    marketId: MarketId;
};

const DEFAULT_LANGUAGE_CODE = 'en';

const getTemplateDefaultLanguage = (languages: MarketLanguage[]) =>
    languages.find((language) => language.code === DEFAULT_LANGUAGE_CODE);

const getTemplateLanguages = (languages: MarketLanguage[]) =>
    languages.filter((language) => language.code !== DEFAULT_LANGUAGE_CODE);

interface Props {
    children?: ReactNode;
}

export const MarketLanguagesDataContextProvider = ({ children }: Props) => {
    const config = useContext(ConfigContext);
    const auth = useContext(AuthContext);
    const params = useParams<Params>();

    const availableLanguagesResult = usePromise({
        disableAutostart: !auth.authenticated,
        variables: undefined,
        promiseBuilder: async () => {
            return getAvailableLanguages({
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const currentMarketLanguagesResult = usePromise({
        disableAutostart: !auth.authenticated,
        variables: {
            market: params.marketId
        },
        promiseBuilder: async (variables) => {
            return getCurrentMarketLanguages(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const data = useMemo(() => {
        if (
            availableLanguagesResult.data &&
            currentMarketLanguagesResult.data
        ) {
            return {
                available: getTemplateLanguages(
                    availableLanguagesResult.data?.data
                ),
                current: currentMarketLanguagesResult.data?.data,
                default: getTemplateDefaultLanguage(
                    availableLanguagesResult.data?.data
                )
            };
        }
    }, [availableLanguagesResult.data, currentMarketLanguagesResult.data]);

    const loading =
        availableLanguagesResult.loading ||
        currentMarketLanguagesResult.loading;

    const error =
        availableLanguagesResult.error || currentMarketLanguagesResult.error;

    const context = useMemo(() => {
        return {
            data,
            loading,
            error
        };
    }, [data, error, loading]);

    return (
        <MarketLanguagesDataContext.Provider value={context}>
            {children}
        </MarketLanguagesDataContext.Provider>
    );
};
