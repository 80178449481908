import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useChangeObserver = <T extends readonly unknown[]>(
    listener: (newDependencyList: T) => void,
    dependencyList: T
) => {
    const previousList = useRef(dependencyList);

    const listenerRef = useRef(listener);
    listenerRef.current = listener;

    useEffect(() => {
        if (isEqual(dependencyList, previousList.current)) {
            return;
        }

        listenerRef.current(previousList.current);

        previousList.current = dependencyList;
    }, [dependencyList]);
};
