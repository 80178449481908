export enum HTTPResponseStatus {
    NotFound = 404
}

export class HTTPError extends Error {
    constructor(
        message: string,
        public status?: HTTPResponseStatus,
        public details?: string
    ) {
        super(`HTTPError: ${message}`);
    }
}
