import { ReactNode } from 'react';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/lib/form/interface';

const buildList = <T,>(value?: T | T[]) => {
    if (value === undefined) {
        return [];
    }

    if (Array.isArray(value)) {
        return value;
    }

    return [value];
};

export interface FormListField extends FormListFieldData {
    getValue: (subName: string) => string;
}

interface Props {
    name: NamePath;
    parentName?: NamePath;
    children: (
        fields: FormListField[],
        operation: FormListOperation,
        name: NamePath
    ) => ReactNode;
}

export const FormList = (props: Props) => {
    const form = Form.useFormInstance();

    const mapFields = (fields: FormListFieldData[]) => {
        return fields.map((field) => {
            return {
                ...field,
                getValue: (subName: string) => {
                    return form?.getFieldValue([
                        ...buildList(props.parentName),
                        ...buildList(props.name),
                        field.name,
                        subName
                    ]) as string;
                }
            };
        });
    };

    return (
        <Form.List {...props}>
            {(fields, operation) =>
                props.children(mapFields(fields), operation, props.name)
            }
        </Form.List>
    );
};
