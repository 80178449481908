import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType, Button } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    Category,
    CategoryId,
    CategorySetId,
    FeatureId
} from '@olxeu-monetization/product-catalog-api-client';
import {
    CategoriesCell,
    renderSearchIcon,
    SearchFilter,
    TreeSelectFilter
} from '../../components/Table';
import { Messages } from '../../intl';

export interface Filters {
    name?: string;
    categoryIds?: CategoryId[];
}

export type TableDataSource = {
    key: string;
    id: CategorySetId;
    name: string;
    selected: boolean;
    categories: Category[];
};

export type TableFilters = {
    name: string[] | null;
    categories: CategoryId[] | null;
    actions: null;
};

interface Options {
    categoryOptions?: ColumnFilterItem[];
    filters: Filters;
    intl: IntlShape;
    onSelect?: (id: FeatureId) => void;
}

export const buildTableColumns = ({
    categoryOptions,
    filters,
    intl,
    onSelect
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.name ? [filters.name] : []
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.categories']} />
            ),
            key: 'categories',
            dataIndex: 'categories',
            render: (categories) => <CategoriesCell categories={categories} />,
            filterDropdown: (props) => (
                <TreeSelectFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.category-search']
                    )}
                />
            ),
            filters: categoryOptions,
            filteredValue: filters.categoryIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'id',
            align: 'right',
            render: (id: CategorySetId, record) => (
                <Button
                    size="small"
                    type="primary"
                    disabled={record.selected}
                    onClick={() => onSelect?.(id)}
                    data-testid={`category-sets-modal-select-button[${id}]`}
                >
                    <FormattedMessage {...Messages['common.button.select']} />
                </Button>
            )
        }
    ];
};
