import { Switch as BaseSwitch, SwitchProps } from 'antd';
import { SwitchLabel } from './Switch.styled';

interface Props extends SwitchProps {
    secondaryLabel?: string;
}

export const Switch = ({ secondaryLabel, ...rest }: Props) => (
    <SwitchLabel>
        {secondaryLabel}
        <BaseSwitch {...rest} />
    </SwitchLabel>
);
