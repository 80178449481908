import { ReactNode } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const { Title } = Typography;

const ModalTitleWrapper = styled.div<SpaceProps>`
    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.space[16]};
    ${space};
`;

const TitleStyled = styled(Title)<SpaceProps>`
    margin-bottom: 0 !important;
`;

interface Props {
    title: string;
    tags?: ReactNode;
}

export const ModalTitle = ({ title, tags }: Props) => (
    <ModalTitleWrapper mb={16}>
        <TitleStyled level={4}>{title}</TitleStyled>
        {tags}
    </ModalTitleWrapper>
);
