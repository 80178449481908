import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { SpaceProps } from 'styled-system';
import { Box } from '../../components/Box';
import { buildLink, MarketId, marketNameMap, Path } from '../../routing';

const { Option } = Select;

interface Props extends SpaceProps {
    selectedMarket?: MarketId;
    markets: MarketId[];
}

export const MarketSelector = ({ selectedMarket, markets, ...rest }: Props) => {
    const navigate = useNavigate();

    const handleMarketChange = (marketId: MarketId) => {
        navigate(buildLink(Path.Market, { marketId }));
    };

    return (
        <Box {...rest} data-testid="app-market-selector">
            <Select
                style={{ width: 150 }}
                onChange={handleMarketChange}
                value={selectedMarket}
                aria-label="Market selector"
            >
                {markets.map((marketId) => (
                    <Option key={marketId} value={marketId}>
                        {marketNameMap[marketId]}
                    </Option>
                ))}
            </Select>
        </Box>
    );
};
