import { ReactNode, useContext } from 'react';
import { CustomFormats, IntlProvider } from 'react-intl';
import { ConfigContext } from '../context';

interface LanguageProviderProps {
    messages: Record<string, string>;
    formats: CustomFormats;
    children?: ReactNode;
}

export const LanguageProvider = ({
    messages,
    formats,
    children
}: LanguageProviderProps) => {
    const config = useContext(ConfigContext);

    return (
        <IntlProvider
            locale={config.intl.locale}
            messages={messages}
            formats={formats}
        >
            {children}
        </IntlProvider>
    );
};
