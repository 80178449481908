import { ParsedQueryParamsSchema, QueryParamsSchema } from './types';

type SearchParamsGrouped = Record<string, string[] | undefined>;

export const parseSearchParams = <S extends QueryParamsSchema>(
    searchParams: URLSearchParams,
    schema: S
) => {
    const tuples = Array.from(searchParams.entries());

    const grouped = tuples.reduce<SearchParamsGrouped>((acc, item) => {
        const [key, value] = item;

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key]?.push(value);

        return acc;
    }, {});

    const typeKeys = Object.keys(schema) as (keyof S)[];

    return typeKeys.reduce<Partial<ParsedQueryParamsSchema<S>>>(
        (acc, typeKey) => {
            const values = grouped[typeKey as string];
            const type = schema[typeKey];

            if (values !== undefined) {
                const parsedValue = type.parse(
                    values
                ) as ParsedQueryParamsSchema<S>[typeof typeKey];
                acc[typeKey] = parsedValue;
            }

            return acc;
        },
        {}
    );
};
