import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
    AugmentedTableInterface,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import styled, { useTheme } from 'styled-components';
import {
    CategorySetId,
    Feature,
    OfferChangeId,
    OfferChangeProduct,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import {
    buildFeatureOptions,
    buildTypeOptions
} from '../../layouts/ProductListLayout/utils';
import { MarketId } from '../../routing';
import { Section } from '../Section';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import {
    buildOfferChangeProductsDataSource,
    mapTableFiltersToFilters
} from './utils';

const OfferChangeProductsTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

const CounterTag = styled(Tag)`
    font-weight: normal;
`;

interface Props {
    marketId: MarketId;
    offerChangeId: OfferChangeId;
    offerChangeProducts: OfferChangeProduct[];
    productsTotalCount: number;
    categorySetNamesMap: Map<CategorySetId, string>;
    features: Feature[];
    productTypes: ProductType[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onFiltersChange?: (filters: Filters) => void;
}

export const FinishedOfferChangeProducts = ({
    marketId,
    offerChangeId,
    offerChangeProducts,
    productsTotalCount,
    categorySetNamesMap,
    features,
    productTypes,
    pageIndex,
    pageSize,
    filters,
    onPageIndexChange,
    onPageSizeChange,
    onFiltersChange
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const featureOptions = useMemo(() => {
        return buildFeatureOptions(features);
    }, [features]);

    const typeOptions = useMemo(() => {
        return buildTypeOptions(productTypes);
    }, [productTypes]);

    const columns = useMemo(() => {
        return buildTableColumns({
            featureOptions,
            typeOptions,
            filters,
            intl
        });
    }, [featureOptions, typeOptions, filters, intl]);

    const dataSource = useMemo(() => {
        return buildOfferChangeProductsDataSource(
            marketId,
            offerChangeId,
            offerChangeProducts,
            categorySetNamesMap
        );
    }, [offerChangeId, offerChangeProducts, categorySetNamesMap, marketId]);

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    filters,
                    featureOptions
                );

                onFiltersChange?.(nextFilters);
                break;
            }
            default:
                return;
        }
    };

    return (
        <Section
            title={formatMessage(Messages['common.label.changed-products'])}
            tags={
                <CounterTag color={theme.colors.tagCounter}>
                    {formatMessage(Messages['common.tag.changed-of'], {
                        changed: offerChangeProducts.length,
                        available: productsTotalCount
                    })}
                </CounterTag>
            }
            data-testid="offer-change-products"
        >
            <OfferChangeProductsTable
                data-testid="offer-change-products-table"
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    total: productsTotalCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
        </Section>
    );
};
