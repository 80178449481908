import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    AugmentedRoute,
    AugmentedTableInterface,
    Button,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { PlusOutlined } from '@ant-design/icons';
import {
    TemplateId,
    TemplateListItem,
    TemplateTranslation
} from '@olxeu-monetization/product-catalog-api-client';
import { PageHeader } from '../../components/PageHeader';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { theme } from '../../styles';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import { buildTemplatesData, mapTableFiltersToFilters } from './utils';

export interface Template {
    id: TemplateId;
    name: string;
    description?: string;
    translations?: TemplateTranslation[];
    defaultTranslation: TemplateTranslation;
}

const TemplateTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    marketId: MarketId;
    title: string;
    breadcrumbRoutes: AugmentedRoute[];
    templates: TemplateListItem[];
    templatesCount: number;
    filters: Filters;
    pageIndex: number;
    pageSize: number;
    onFiltersChange?: (filters: Filters) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
}

export const TemplateListLayout = ({
    marketId,
    title,
    breadcrumbRoutes,
    templates,
    templatesCount,
    filters,
    pageIndex,
    pageSize,
    onFiltersChange,
    onPageIndexChange,
    onPageSizeChange
}: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(filters);

                onFiltersChange?.(nextFilters);

                break;
            }
            default:
                return;
        }
    };

    const columns = useMemo(() => {
        return buildTableColumns({
            filters,
            marketId,
            intl
        });
    }, [filters, marketId, intl]);

    const dataSource = useMemo(() => {
        return buildTemplatesData(templates);
    }, [templates]);

    return (
        <div data-testid="template-list-layout">
            <PageHeader
                title={title}
                tags={
                    templatesCount ? (
                        <Tag color={theme.colors.tagCounter}>
                            {formatMessage(Messages['common.tag.total-count'], {
                                count: templatesCount
                            })}
                        </Tag>
                    ) : undefined
                }
                actions={[
                    <Link
                        key="create-template"
                        to={buildLink(Path.TemplateCreate, {
                            marketId
                        })}
                    >
                        <Button icon={<PlusOutlined />}>
                            {formatMessage(
                                Messages['common.button.create-new-template']
                            )}
                        </Button>
                    </Link>
                ]}
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <TemplateTable
                data-testid="templates-table"
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    total: templatesCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
        </div>
    );
};
