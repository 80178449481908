import {
    Limit,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';

export const arrangeLimits = (limits?: Limit[]) => {
    if (limits === undefined) return;

    const alteredLimits = [...limits];

    const businessLimitIndex = alteredLimits.findIndex(
        (limit) => limit.limit_type === LimitType.Business
    );

    if (businessLimitIndex !== -1) {
        const businessLimit = alteredLimits[businessLimitIndex];
        alteredLimits.splice(businessLimitIndex, 1);
        alteredLimits.unshift(businessLimit);
    }

    const defaultLimitIndex = alteredLimits.findIndex(
        (limit) => limit.limit_type === LimitType.Default
    );
    const defaultLimit = alteredLimits[defaultLimitIndex];
    alteredLimits.splice(defaultLimitIndex, 1);
    alteredLimits.unshift(defaultLimit);

    return alteredLimits;
};
