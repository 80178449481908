import pickBy from 'lodash/pickBy';

export const buildQueryParamsWithoutEmpties = <
    T extends Record<string, unknown>
>(
    source: T
) => {
    return pickBy<T>(source, (value) => {
        return value !== '';
    });
};

export const buildQueryParamsWithoutDefaults = <
    T extends Record<string, unknown>,
    D extends Record<string, unknown>
>(
    source: T,
    defaults: D
) => {
    return pickBy<Partial<T>>(source, (value, key) => {
        return value !== defaults[key];
    });
};
