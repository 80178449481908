import { useIntl } from 'react-intl';
import { Form, Input, InputNumber, Select, TreeSelect } from 'antd';
import { Rule, RuleObject } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { DefaultOptionType } from 'antd/lib/select';
import { Messages } from '../../intl';
import { Duration } from '../../types';
import {
    DurationAllowedSegments,
    InputDuration,
    InputDurationFallbackMode
} from '../InputDuration';
import { Switch } from '../Switch';
import { stringifyNamePath } from './utils';

export enum FormFieldType {
    Input,
    Number,
    Duration,
    Switch,
    Select,
    TreeSelect
}

interface FormFieldBaseData {
    name: NamePath;
    label: React.ReactNode;
    value?: string | number;
    previousValue?: string | number;
    required: boolean;
    validationRules?: Rule[];
    disabled?: boolean;
    type: FormFieldType;
    autoFocus?: boolean;
    noStyle?: boolean;
    className?: string;
}

interface FormOtherFieldData {
    type: Exclude<
        FormFieldType,
        | FormFieldType.Duration
        | FormFieldType.Number
        | FormFieldType.Select
        | FormFieldType.TreeSelect
        | FormFieldType.Switch
    >;
}

interface FormDurationFieldData {
    fallbackMode?: InputDurationFallbackMode;
    showExpirationSwitch?: boolean;
    type: FormFieldType.Duration;
    min?: Duration;
    max?: Duration;
    allowedSegments?: DurationAllowedSegments;
    hideDisallowedSegments?: boolean;
}

interface FormNumberFieldData {
    type: FormFieldType.Number;
    min?: number;
    max?: number;
}

interface FormSelectFieldOption {
    label: string;
    value: string;
    disabled?: boolean;
}

interface FormSelectFieldData {
    type: FormFieldType.Select;
    allowClear?: boolean;
    notFoundContent?: React.ReactNode;
    options?: (string | FormSelectFieldOption)[];
}

interface FormTreeSelectFieldData {
    type: FormFieldType.TreeSelect;
    treeData: DefaultOptionType[];
}

interface FormSwitchFieldData {
    type: FormFieldType.Switch;
    secondaryLabel?: string;
}

export type FormFieldData = FormFieldBaseData &
    (
        | FormOtherFieldData
        | FormDurationFieldData
        | FormNumberFieldData
        | FormSelectFieldData
        | FormTreeSelectFieldData
        | FormSwitchFieldData
    );

type Props = FormFieldData;

export const FormInput = (props: Props) => {
    const intl = useIntl();

    const popupContainer = ({
        parentElement
    }: {
        parentElement: HTMLElement;
    }) => parentElement;

    const requiredRule = {
        required: props.required,
        message: intl.formatMessage(Messages['common.error-message.required'])
    };

    switch (props.type) {
        case FormFieldType.Input:
            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[
                        ...[requiredRule],
                        ...(props.validationRules ?? [])
                    ]}
                    noStyle={props.noStyle}
                    className={props.className}
                >
                    <Input
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        data-cy={`form-input-text[${stringifyNamePath(
                            props.name
                        )}]`}
                    />
                </Form.Item>
            );
        case FormFieldType.Number:
            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[requiredRule]}
                    noStyle={props.noStyle}
                    className={props.className}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        min={props.min}
                        max={props.max}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        data-cy={`form-input-number[${stringifyNamePath(
                            props.name
                        )}]`}
                    />
                </Form.Item>
            );
        case FormFieldType.Duration:
            const durationRule = {
                validator: (rule: RuleObject, value: Duration) => {
                    const durationMinValue = props.min;
                    const durationMaxValue = props.max;

                    if (durationMinValue && value < durationMinValue) {
                        return Promise.reject(
                            intl.formatMessage(
                                Messages['common.error-message.min-duration'],
                                {
                                    duration: durationMinValue.toHumanized()
                                }
                            )
                        );
                    }

                    if (durationMaxValue && value > durationMaxValue) {
                        return Promise.reject(
                            intl.formatMessage(
                                Messages['common.error-message.max-duration'],
                                {
                                    duration: durationMaxValue.toHumanized()
                                }
                            )
                        );
                    }

                    return Promise.resolve();
                }
            };

            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[requiredRule, durationRule]}
                    noStyle={props.noStyle}
                >
                    <InputDuration
                        fallbackMode={props.fallbackMode}
                        expiration={{
                            showSwitch: props.showExpirationSwitch,
                            switchLabel: intl.formatMessage(
                                Messages['common.label.expires']
                            )
                        }}
                        minValue={props.min}
                        maxValue={props.max}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        data-cy={`form-input-duration[${stringifyNamePath(
                            props.name
                        )}]`}
                        allowedSegments={props.allowedSegments}
                        hideDisallowedSegments={props.hideDisallowedSegments}
                    />
                </Form.Item>
            );
        case FormFieldType.Select:
            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[requiredRule]}
                    noStyle={props.noStyle}
                >
                    <Select
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        allowClear={props.allowClear}
                        notFoundContent={props.notFoundContent}
                        getPopupContainer={popupContainer}
                        data-cy={`form-input-select[${stringifyNamePath(
                            props.name
                        )}]`}
                        style={{ minWidth: 150 }}
                    >
                        {props.options?.map((option) => {
                            const value =
                                typeof option === 'string'
                                    ? option
                                    : option.value;

                            const label =
                                typeof option === 'string'
                                    ? option
                                    : option.label;

                            const disabled =
                                typeof option !== 'string' && option.disabled;

                            return (
                                <Select.Option
                                    key={value}
                                    value={value}
                                    disabled={disabled}
                                    data-cy={`form-input-select-option[${stringifyNamePath(
                                        props.name
                                    )}][${value}]`}
                                >
                                    {label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            );
        case FormFieldType.TreeSelect:
            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[requiredRule]}
                    noStyle={props.noStyle}
                >
                    <TreeSelect
                        treeData={props.treeData}
                        treeDefaultExpandAll
                        getPopupContainer={popupContainer}
                        multiple={false}
                        data-cy={`form-input-tree-select[${stringifyNamePath(
                            props.name
                        )}]`}
                    />
                </Form.Item>
            );
        case FormFieldType.Switch:
            return (
                <Form.Item
                    name={props.name}
                    label={props.label}
                    rules={[requiredRule]}
                    noStyle={props.noStyle}
                    valuePropName="checked"
                >
                    <Switch
                        secondaryLabel={props.secondaryLabel}
                        autoFocus={props.autoFocus}
                        disabled={props.disabled}
                        data-cy={`form-input-switch[${stringifyNamePath(
                            props.name
                        )}]`}
                    />
                </Form.Item>
            );
    }
};
