import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PriceProjectLogo } from '../../components/Icon';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';
import { SidebarMenu } from './SidebarMenu';

interface SidebarProps {
    collapsed: boolean;
}

const LogoWrapper = styled.div`
    ${({ theme }) => `
        height: ${theme.sizes.headerHeight};
        background: ${theme.colors.black30p};
    `};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HomepageLink = styled(Link)`
    display: block;
    line-height: 1;
`;

const SidebarWrapper = styled.aside<SidebarProps>`
    ${({ theme, collapsed }) => `
        max-width: ${theme.sizes.sidebarWidth};
        min-width: ${
            collapsed
                ? theme.sizes.sidebarWidthCollapsed
                : theme.sizes.sidebarWidth
        };
        background: ${theme.colors.darkerBlue};
        transition: min-width ${theme.transitions.sidebar};
        z-index: ${theme.zIndices.fixed};
    `};
`;

const LogoLabel = styled.span`
    ${({ theme }) => `
        font-size: ${theme.fontSizes.large};
        color: ${theme.colors.white};
    `}
`;

interface Props {
    collapsed: boolean;
    marketId: MarketId;
}

export const Sidebar = ({ collapsed, marketId }: Props) => (
    <SidebarWrapper collapsed={collapsed} data-testid="app-sidebar">
        <HomepageLink to="/" data-testid="app-sidebar-link">
            <LogoWrapper>
                <PriceProjectLogo
                    role="img"
                    fontSize={36}
                    mr={collapsed ? 0 : 12}
                />
                {!collapsed && (
                    <LogoLabel>
                        <FormattedMessage
                            {...Messages['common.label.monetization-hub']}
                        />
                    </LogoLabel>
                )}
            </LogoWrapper>
        </HomepageLink>
        <SidebarMenu collapsed={collapsed} marketId={marketId} mt={16} />
    </SidebarWrapper>
);
