import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Config } from '../config';
import { ConfigContext } from '../context';
import { DefaultFormats, EnglishMessages } from '../intl';
import {
    AuthContextProvider,
    ErrorBoundary,
    LanguageProvider,
    ModalContextProvider,
    UserDataContextProvider
} from '../providers';
import { AuthorizedRoutes } from '../routing';
import { GlobalStyle, theme } from '../styles';

export const App = () => (
    <StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ErrorBoundary>
                <ConfigContext.Provider value={Config}>
                    <BrowserRouter>
                        <AuthContextProvider>
                            <UserDataContextProvider>
                                <LanguageProvider
                                    messages={EnglishMessages}
                                    formats={DefaultFormats}
                                >
                                    <ModalContextProvider>
                                        <AuthorizedRoutes />
                                    </ModalContextProvider>
                                </LanguageProvider>
                            </UserDataContextProvider>
                        </AuthContextProvider>
                    </BrowserRouter>
                </ConfigContext.Provider>
            </ErrorBoundary>
        </ThemeProvider>
    </StrictMode>
);
