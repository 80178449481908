import { SVGProps } from 'react';
import styled from 'styled-components';
import { color, margin, MarginProps } from 'styled-system';
import { ThemeType } from '../../styles';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

const IconContent = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 58 67"
        width="1em"
        height="1em"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <style>
            {
                '.price-project-logo_svg__shp0{fill:#e9e9e9}.price-project-logo_svg__shp1{fill:#fff}'
            }
        </style>
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp0"
            d="m5.07 47.44.29-27.03L29.07 7.06v6.66l-6.02 3.44v6.71l-5.78 3.41v-6.87l-5.78 3.46v19.91l-5.66 3.47-.76.19Z"
        />
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp1"
            d="m46.83 31.21-5.93-3.42-11.94 6.69-11.85-7.16 5.93-3.45 5.91 3.46 12.15-6.5 5.83 3.2-.1 7.18Z"
        />
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp1"
            d="m28.99 6.77 23.73 13.94-.29 27.09-5.73-3.31-.02-6.83-5.81-3.36-.06-6.71 5.95 3.44-.11-6.74-17.59-10.58-.07-6.94Z"
        />
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp1"
            d="M52.81 47.74 29.26 61.01 5.69 47.31l5.72-3.58 6.19 3.57 5.7-3.41 6.43 3.41-5.6 3.29 5.06 3.37 17.42-9.55 5.66 2.76.54.57ZM.21 17.19l5.59 3.5L29.06 7.14l23.19 13.55 5.66-3.5L28.8.46.21 17.19Z"
        />
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp1"
            d="M5.85 20.77v26.49l23.21 13.37c-.04 4.41-.04 6.56 0 6.43.04-.13-9.58-5.64-28.85-16.55v-33.3l5.64 3.56Z"
        />
        <path
            fillRule="evenodd"
            d="M57.84 17.13v33.3C38.57 61.34 28.95 66.86 28.99 66.98c.05.13.05-2.01 0-6.43L52.2 47.18V20.69l5.64-3.56Z"
            fill="#e6e6e6"
        />
        <path
            fillRule="evenodd"
            d="M17.3 20.39v6.82l12.01 6.38v13.72l-6.07-3.43V37.2l-11.75-6.58v-6.76l5.81-3.47Z"
            fill="#f3f3f3"
        />
        <path
            fillRule="evenodd"
            className="price-project-logo_svg__shp0"
            d="m23.34 50.6 5.9-3.27.04-13.76 11.52-5.9.15 6.7-5.86 2.92-.04 13.43-6.07 3.36-5.64-3.48Z"
        />
    </svg>
);

interface IconProps extends MarginProps {
    color?: keyof ThemeType['colors'];
}
const SvgPriceProjectLogo = styled(IconContent)<IconProps>`
    ${margin}
    ${color}
`;
export default SvgPriceProjectLogo;
