import { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, InputRef, Tree } from 'antd';
import { FilterDropdownProps, Key } from 'antd/lib/table/interface';
import { EventDataNode } from 'antd/lib/tree';
import { useTheme } from 'styled-components';
import { buildCheckedState, changeTreeNodeChecked } from '../../../helpers';
import { Messages } from '../../../intl';
import { getHighlightedData, useChangeObserver } from '../../../utils';
import { Buttons, TreeSearch, Wrapper } from './TreeSelectFilter.styled';

interface CheckInfo {
    node: EventDataNode<unknown>;
}

interface CheckedState {
    checked: Key[];
    halfChecked: Key[];
}

interface Props extends FilterDropdownProps {
    placeholder?: string;
}

export const TreeSelectFilter = ({
    visible,
    confirm,
    clearFilters,
    selectedKeys,
    setSelectedKeys,
    filters = [],
    placeholder
}: Props) => {
    const searchRef = useRef<InputRef>(null);
    const theme = useTheme();
    const [searchValue, setSearchValue] = useState('');

    useChangeObserver(() => {
        if (!visible) {
            const input = searchRef.current?.input;

            if (input) {
                input.value = '';
            }
        }
    }, [visible]);

    const handleOnCheck = (checked: CheckedState | Key[], info: CheckInfo) => {
        if (!Array.isArray(checked)) {
            const nextSelectedIds = changeTreeNodeChecked({
                roots: filters,
                changedNodeKey: info.node.key,
                changedNodeChecked: !info.node.checked,
                selectedKeys: checked.checked.map(String),
                keyName: 'value'
            });

            setSelectedKeys(nextSelectedIds);
        }
    };

    const treeData = useMemo(() => {
        return getHighlightedData({
            roots: filters,
            query: searchValue
        });
    }, [filters, searchValue]);

    const selectedIds = useMemo(() => {
        return selectedKeys.map(String);
    }, [selectedKeys]);

    const checkedState = useMemo(() => {
        return buildCheckedState(filters, selectedIds);
    }, [selectedIds, filters]);

    return (
        <Wrapper data-testid="tree-select-dropdown">
            {placeholder && (
                <TreeSearch
                    placeholder={placeholder}
                    onSearch={setSearchValue}
                    ref={searchRef}
                />
            )}
            <Tree
                checkedKeys={checkedState}
                treeData={treeData}
                checkStrictly={true}
                onCheck={handleOnCheck}
                checkable={true}
                selectable={false}
                height={theme.sizesRaw.filterTreeMaxHeight}
            />
            <Buttons>
                <Button
                    onClick={clearFilters}
                    size="small"
                    type="link"
                    disabled={selectedIds.length === 0}
                >
                    <FormattedMessage {...Messages['common.button.reset']} />
                </Button>
                <Button type="primary" onClick={() => confirm()} size="small">
                    <FormattedMessage {...Messages['common.button.ok']} />
                </Button>
            </Buttons>
        </Wrapper>
    );
};
