import { useContext, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    getCategorySets,
    getProducts
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import { buildHomeBreadcrumbRoute } from '../../components/PageHeader';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext,
    FeaturesDataContext,
    ProductTypesDataContext
} from '../../context';
import { getCategorySetNames } from '../../helpers';
import { Messages } from '../../intl';
import { Filters, ProductListLayout } from '../../layouts/ProductListLayout';
import {
    buildLink,
    MarketId,
    Path,
    PathParams,
    QueryParamsSchemas
} from '../../routing';
import { usePromise, useQueryParams } from '../../utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 20;

const QueryParamsSchema = QueryParamsSchemas[Path.ProductList];

type Params = PathParams[Path.ProductList];

export const buildBreadcrumbsRoutes = (marketId: MarketId, intl: IntlShape) => {
    return [
        buildHomeBreadcrumbRoute(marketId),
        {
            path: buildLink(Path.ProductList, { marketId }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.products']
            )
        }
    ];
};

export const ProductListPage = () => {
    const intl = useIntl();
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const categoriesData = useContext(CategoriesDataContext);
    const featuresData = useContext(FeaturesDataContext);
    const productTypesData = useContext(ProductTypesDataContext);
    const params = useParams<Params>();

    const [queryParams, setQueryParams] = useQueryParams({
        schema: QueryParamsSchema,
        defaults: {
            page: PaginationInitialPageIndex,
            limit: PaginationDefaultPageSize
        }
    });

    const productsResult = usePromise({
        variables: {
            ...queryParams,
            offset: queryParams.page * queryParams.limit
        },
        promiseBuilder: async (variables) => {
            return getProducts(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categorySetNamesResult = usePromise({
        variables: {
            categorySetIds: productsResult.data?.data?.flatMap(
                (product) => product.target.categorySetIds
            )
        },
        promiseBuilder: (variables) => {
            return getCategorySetNames({
                categorySetIds: variables.categorySetIds,
                onCategorySetsGet: async (requestVariables) => {
                    return await getCategorySets(requestVariables, {
                        baseUrl: config.services.productCatalogService.baseUrl,
                        accessToken: await auth.getAccessToken()
                    });
                }
            });
        },
        disableAutostart: !productsResult.data
    });

    const filters = useMemo(() => {
        return {
            search: queryParams.search,
            types: queryParams.types,
            status: queryParams.status,
            categorySetIds: queryParams.categorySetIds,
            featureIds: queryParams.featureIds
        };
    }, [
        queryParams.search,
        queryParams.types,
        queryParams.status,
        queryParams.categorySetIds,
        queryParams.featureIds
    ]);

    const categoriesLoading = categoriesData.loading && !categoriesData.data;
    const featuresLoading = featuresData.loading && !featuresData.data;
    const productTypesLoading =
        productTypesData.loading && !productTypesData.data;
    const productsLoading = productsResult.loading && !productsResult.data;
    const categorySetNamesLoading =
        categorySetNamesResult.loading && !categorySetNamesResult.data;

    if (
        productsLoading ||
        categoriesLoading ||
        categorySetNamesLoading ||
        featuresLoading ||
        productTypesLoading
    ) {
        return <Loader type="center" />;
    }

    const products = productsResult.data?.data;
    const productsCount = productsResult.data?.metadata?.totalCount;
    const categories = categoriesData.data;
    const categorySetNames = categorySetNamesResult.data;
    const features = featuresData.data;
    const productTypes = productTypesData.data;

    if (
        !products ||
        productsCount === undefined ||
        !categories ||
        !features ||
        !productTypes ||
        !categorySetNames
    ) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(params.marketId, intl);

    const handlePageIndexChange = (page: number) => {
        setQueryParams({ page });
    };

    const handlePageSizeChange = (limit: number) => {
        const page = Math.floor((queryParams.page * queryParams.limit) / limit);
        setQueryParams({ page, limit });
    };

    const handleFiltersChange = (filters: Filters) => {
        setQueryParams({
            ...filters,
            page: 0
        });
    };

    return (
        <ProductListLayout
            marketId={params.marketId}
            products={products}
            breadcrumbRoutes={breadcrumbRoutes}
            productsCount={productsCount}
            productsLoading={productsResult.loading}
            categories={categories}
            categorySetNamesMap={categorySetNames}
            features={features}
            productTypes={productTypes}
            pageIndex={queryParams.page}
            pageSize={queryParams.limit}
            filters={filters}
            onPageIndexChange={handlePageIndexChange}
            onPageSizeChange={handlePageSizeChange}
            onFiltersChange={handleFiltersChange}
        />
    );
};
