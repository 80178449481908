import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import { CategorySetId } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { ErrorTooltip } from '../../ErrorTooltip';
import { CategorySetChangeType } from '../../Table';

const { Text } = Typography;

export const buildDataSource = (
    categorySetIds: CategorySetId[],
    categorySetNamesMap: Map<CategorySetId, string>,
    previousCategorySetIds?: CategorySetId[]
) => {
    const categorySetIdsMap = new Map<CategorySetId, boolean>();

    categorySetIds.forEach((categorySetId) => {
        categorySetIdsMap.set(categorySetId, true);
    });

    const previousCategorySetIdsMap = new Map<CategorySetId, boolean>();

    previousCategorySetIds?.forEach((categorySetId) => {
        previousCategorySetIdsMap.set(categorySetId, true);
    });

    const combinedCategorySetsIds = new Set([
        ...categorySetIds,
        ...(previousCategorySetIds ? previousCategorySetIds : [])
    ]);

    const dataSource = Array.from(combinedCategorySetsIds).map((id) => {
        const currentContains = categorySetIdsMap.get(id) ?? false;
        const previousContains = previousCategorySetIdsMap.get(id) ?? false;

        if (!currentContains && !previousContains) {
            return {
                id: id,
                key: id,
                name: categorySetIdsMap.get(id) ?? (
                    <ErrorTooltip
                        title={
                            <FormattedMessage
                                {...Messages[
                                    'common.label.category-set-no-longer-exists'
                                ]}
                                values={{ id }}
                            />
                        }
                    >
                        <Text disabled>{id}</Text>
                    </ErrorTooltip>
                ),
                changeType: CategorySetChangeType.None,
                changesCount: 0
            };
        }

        const categorySetsAdded = !previousContains && currentContains;
        const categorySetsRemoved = previousContains && !currentContains;

        const changeType =
            previousCategorySetIds !== undefined && categorySetsAdded
                ? CategorySetChangeType.Added
                : previousCategorySetIds !== undefined && categorySetsRemoved
                ? CategorySetChangeType.Removed
                : CategorySetChangeType.None;

        const changesCount = Number(changeType !== CategorySetChangeType.None);

        return {
            id: id,
            key: id,
            name: categorySetNamesMap.get(id) ?? (
                <ErrorTooltip
                    title={
                        <FormattedMessage
                            {...Messages[
                                'common.label.category-set-no-longer-exists'
                            ]}
                            values={{ id }}
                        />
                    }
                >
                    <Text disabled>{id}</Text>
                </ErrorTooltip>
            ),
            changeType,
            changesCount
        };
    });

    const changesCount = dataSource.reduce((acc, item) => {
        return acc + item.changesCount;
    }, 0);

    return {
        dataSource,
        changesCount
    };
};
