import { FormattedMessage } from 'react-intl';
import { AugmentedColumnsType, Typography } from 'antd';
import { LabelMetadataValue } from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../../intl';
import { ProductPropertyDiff } from '../../ProductProperties/ProductPropertyDiff';

const { Text } = Typography;

export enum LabelMetadataValueType {
    String = 'string',
    Boolean = 'boolean'
}

export type TableDataSource = {
    key: string;
    value: LabelMetadataValue;
    previousValue?: LabelMetadataValue;
    type: LabelMetadataValueType;
    diffVisible?: boolean;
};

export type TableFilters = {
    key: string[] | null;
    value: string[] | null;
};

export const buildTableColumns = (): AugmentedColumnsType<
    TableDataSource,
    TableFilters
> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.key']} />,
            key: 'key',
            dataIndex: 'key'
        },
        {
            title: <FormattedMessage {...Messages['common.label.value']} />,
            key: 'value',
            dataIndex: 'value',
            align: 'right',
            render: (value, record) => {
                if (record.type === LabelMetadataValueType.Boolean) {
                    return record.diffVisible &&
                        record.previousValue !== undefined ? (
                        <ProductPropertyDiff
                            id={record.key}
                            value={<Text code>{value.toString()}</Text>}
                            previousValue={
                                <Text code>
                                    {record.previousValue.toString()}
                                </Text>
                            }
                        />
                    ) : (
                        <Text
                            code
                            data-testid={`product-diff-properties-current-value[${record.key}]`}
                        >
                            {value.toString()}
                        </Text>
                    );
                }
                return (
                    <ProductPropertyDiff
                        id={record.key}
                        value={value}
                        previousValue={record.previousValue}
                    />
                );
            }
        }
    ];
};
