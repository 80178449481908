import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Typography } from 'antd';
import { OfferChangeStatus } from '@olxeu-monetization/product-catalog-api-client';
import { DateLabel } from '../../components/DateLabel';
import { OfferChangeStatusTag } from '../../components/StatusTag';
import { renderSearchIcon, SearchFilter } from '../../components/Table';
import { Messages } from '../../intl';

export interface Filters {
    search?: string;
    status?: OfferChangeStatus;
}

export type TableFilters = {
    name: string[] | null;
    status: null;
    lastAuthor: null;
    updatedAt: null;
    actions: null;
};

export type TableDataSource = {
    name: string;
    status: OfferChangeStatus;
    lastAuthor: string;
    updatedAt: string;
    detailsLink: string;
};

interface BuildTableOptions {
    intl: IntlShape;
    filters: Filters;
}

export const buildTableColumns = ({
    intl,
    filters
}: BuildTableOptions): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={record.detailsLink}>
                    <Typography.Text data-cy="offer-change-name">
                        {name}
                    </Typography.Text>
                </Link>
            ),
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.search ? [filters.search] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.status']} />,
            key: 'status',
            dataIndex: 'status',
            render: (status) => <OfferChangeStatusTag status={status} />
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.last-author']} />
            ),
            key: 'lastAuthor',
            dataIndex: 'lastAuthor'
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.last-edited']} />
            ),
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            render: (updatedAt) => <DateLabel date={updatedAt} />
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'detailsLink',
            align: 'right',
            render: (detailsLink: string) => (
                <Link to={detailsLink}>
                    <Button size="small">
                        <FormattedMessage {...Messages['common.button.open']} />
                    </Button>
                </Link>
            )
        }
    ];
};
