import { ChangeEvent, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Input, InputProps, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Messages } from '../../../intl';
import { useChangeObserver } from '../../../utils';
import { Buttons, Wrapper } from './SearchFilter.styled';

type Props = FilterDropdownProps & InputProps;

export const SearchFilter = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
    visible,
    close,
    prefixCls,
    ...rest
}: Props) => {
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (visible) {
            // NOTE: we need to delay setting focus, otherwise the element is fresh new and not yet focusable
            setTimeout(() => {
                inputRef.current?.focus();
            });
        }
    }, [visible]);

    useChangeObserver(() => {
        if (!visible) {
            confirm();
        }
    }, [visible]);

    const searchQuery = selectedKeys[0] ? String(selectedKeys[0]) : undefined;
    const isQueryEmpty = !searchQuery || searchQuery.length === 0;

    const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedKeys([event.target.value]);
    };

    return (
        <Wrapper>
            <Input
                {...rest}
                value={searchQuery}
                onChange={handleSearchQueryChange}
                onPressEnter={() => confirm()}
                ref={inputRef}
            />
            <Buttons>
                <Button
                    onClick={clearFilters}
                    size="small"
                    type="link"
                    disabled={isQueryEmpty}
                >
                    <FormattedMessage {...Messages['common.button.reset']} />
                </Button>
                <Button type="primary" onClick={() => confirm()} size="small">
                    <FormattedMessage {...Messages['common.button.ok']} />
                </Button>
            </Buttons>
        </Wrapper>
    );
};
