import { default as productionFeatureFlags } from './productionFeatureFlags.json';
import { default as stagingFeatureFlags } from './stagingFeatureFlags.json';

export const getFeatureFlags = (env: string) => {
    switch (env) {
        case 'production':
            return productionFeatureFlags;
        case 'staging':
            return stagingFeatureFlags;
        default:
            return [];
    }
};
