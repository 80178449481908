import { createSearchParams } from 'react-router-dom';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { ParsedQueryParamsSchema, QueryParamsSchema } from './types';

export const serializeSearchParams = <S extends QueryParamsSchema>(
    parsedParams: Partial<ParsedQueryParamsSchema<S>>,
    schema: S
) => {
    const filtered = pickBy(parsedParams, (value) => {
        return value !== undefined;
    });

    const mapped = mapValues(filtered, (value, key) => {
        return schema[key].serialize(value);
    });

    return createSearchParams(mapped);
};
