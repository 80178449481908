import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tabs, Tag } from 'antd';
import styled, { useTheme } from 'styled-components';
import {
    MarketLanguage,
    ProductFeature,
    ProductLabel
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { Section } from '../../Section';
import { buildDataSource } from './buildDataSource';
import { ProductLabelDiffTab } from './ProductLabelDiffTab';
import { ProductLabelTabContent } from './ProductLabelTabContent';

const LabelTabs = styled(Tabs)`
    [role='tab'] {
        flex-grow: 1;
        text-align: left;
    }
`;

interface Props {
    labels: ProductLabel[];
    features: ProductFeature[];
    marketLanguages: MarketLanguage[];
    previousLabels?: ProductLabel[];
    diffVisible?: boolean;
}

export const ProductLabels = ({
    labels,
    features,
    marketLanguages,
    previousLabels,
    diffVisible
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const { dataSource, changesCount } = useMemo(() => {
        return buildDataSource(
            labels,
            diffVisible ? previousLabels : undefined,
            intl
        );
    }, [labels, previousLabels, diffVisible, intl]);

    const labelTabs = dataSource.map((label) => {
        const valuesSource = label.values.map((value) => ({
            key: value.key,
            value: value.value,
            previousValue: value.previousValue,
            diffVisible
        }));

        const metadataSource = label.metadata.map((metadata) => ({
            key: metadata.key,
            value: metadata.value,
            previousValue: metadata.previousValue,
            type: metadata.type,
            diffVisible
        }));

        return {
            label: (
                <ProductLabelDiffTab
                    changeType={label.changeType}
                    changesCount={label.changesCount}
                >
                    {label.templateId}
                </ProductLabelDiffTab>
            ),
            key: label.templateId,
            children: (
                <ProductLabelTabContent
                    templateId={label.templateId}
                    marketLanguages={marketLanguages}
                    staticProperties={label.staticProperties}
                    valuesSource={valuesSource}
                    features={features}
                    metadataSource={metadataSource}
                    diffVisible={diffVisible}
                />
            )
        };
    });

    return (
        <Section
            title={formatMessage(Messages['common.label.labels'])}
            description={formatMessage(Messages['common.description.labels'])}
            tags={
                <>
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.items-selected'], {
                            count: labels.length
                        })}
                    </Tag>
                    {diffVisible && (
                        <Tag
                            color={theme.colors.tagChanges}
                            data-testid="product-diff-labels-changes-tag"
                        >
                            {formatMessage(Messages['common.tag.changes'], {
                                count: changesCount
                            })}
                        </Tag>
                    )}
                </>
            }
            data-testid="product-labels"
        >
            <LabelTabs tabPosition="left" type="card" items={labelTabs} />
        </Section>
    );
};
