import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import { useTheme } from 'styled-components';
import { LimitStatus } from '@olxeu-monetization/user-product-service-api-client';
import { Messages } from '../../intl';

interface Props {
    status: LimitStatus;
}

export const LimitStatusTag = ({ status }: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    switch (status) {
        case LimitStatus.Active:
            return (
                <Tag color={theme.colors.tagStatusEnabled}>
                    {formatMessage(Messages['common.label.enabled'])}
                </Tag>
            );
        case LimitStatus.Disabled:
            return (
                <Tag>{formatMessage(Messages['common.label.disabled'])}</Tag>
            );
    }
};
