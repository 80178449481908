import { createContext } from 'react';
import {
    Feature,
    FeatureId,
    FeaturePropertyName,
    FeaturePropertyValidation
} from '@olxeu-monetization/product-catalog-api-client';

export type FeaturePropertyValidationMap = Record<
    FeatureId,
    Record<FeaturePropertyName, FeaturePropertyValidation>
>;

export interface FeaturesDataContextType {
    data?: Feature[];
    featurePropertyValidationMap?: FeaturePropertyValidationMap;
    loading: boolean;
    error?: Error;
}

const DefaultFeaturesDataContext: FeaturesDataContextType = {
    data: undefined,
    featurePropertyValidationMap: undefined,
    loading: false,
    error: undefined
};

export const FeaturesDataContext = createContext<FeaturesDataContextType>(
    DefaultFeaturesDataContext
);
