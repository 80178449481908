type Narrow<T, K extends keyof T, U> = U extends T[K] ? K : never;

export const updateElementAttribute = <
    T extends HTMLElement,
    K extends keyof T
>(
    element: T,
    attribute: Narrow<T, K, boolean | string | number | undefined> & string
) => {
    const value = element[attribute];

    switch (typeof value) {
        case 'boolean': {
            if (value) {
                element.setAttribute(attribute, '');
            } else {
                element.removeAttribute(attribute);
            }

            break;
        }
        case 'string': {
            element.setAttribute(attribute, value);
            break;
        }
        case 'number': {
            element.setAttribute(attribute, String(value));
            break;
        }
        case 'undefined': {
            element.removeAttribute(attribute);
            break;
        }
        default: {
            throw new Error('Unsupported attribute');
        }
    }
};
