import { useIntl } from 'react-intl';
import { Form } from 'antd';
import {
    Category,
    CategorySetType,
    ProductType,
    ProductTypeName
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { CategorySetsFormLayout } from '../../../sections/CategorySetsFormLayout';
import { useChangeObserver } from '../../../utils';
import { FormList } from '../FormList';

interface Props {
    categories: Category[];
    categorySetsTotalCount: number;
    productTypes: ProductType[];
}

export const CategorySetsFormSection = ({
    categories,
    categorySetsTotalCount,
    productTypes
}: Props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const form = Form.useFormInstance();
    const productType = Form.useWatch('type') as ProductTypeName;

    const allowedCategorySetMap = new Map<ProductTypeName, CategorySetType[]>();

    productTypes.forEach((item) => {
        allowedCategorySetMap.set(item.name, item.allowedCategorySetTypes);
    });

    const categorySetsTypes = allowedCategorySetMap.get(productType);

    useChangeObserver(
        ([categorySetsTypePrevious]) => {
            if (!categorySetsTypePrevious) {
                return;
            }

            form.setFieldsValue({
                target: {
                    categorySets: []
                }
            });
        },
        [categorySetsTypes]
    );

    const categorySetAddDisabledReason = productType
        ? undefined
        : formatMessage(
              Messages['common.tooltip-message.category-sets-type-requirement']
          );

    return (
        <FormList name={['target', 'categorySets']}>
            {(categorySetFields, categorySetListOperation) => (
                <CategorySetsFormLayout
                    categorySetFields={categorySetFields}
                    categorySetListOperation={categorySetListOperation}
                    categorySetsTypes={categorySetsTypes}
                    categories={categories}
                    categorySetsTotalCount={categorySetsTotalCount}
                    categorySetAddDisabledReason={categorySetAddDisabledReason}
                />
            )}
        </FormList>
    );
};
