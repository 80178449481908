import { createContext } from 'react';
import { Category } from '@olxeu-monetization/product-catalog-api-client';

export interface CategoriesDataContextType {
    data?: Category[];
    loading: boolean;
    error?: Error;
}

const DefaultCategoriesDataContext: CategoriesDataContextType = {
    data: undefined,
    loading: false,
    error: undefined
};

export const CategoriesDataContext = createContext<CategoriesDataContextType>(
    DefaultCategoriesDataContext
);
