import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
    Alert,
    AugmentedTableInterface,
    Button,
    FormListOperation,
    Table,
    Tag,
    Tooltip
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySetType
} from '@olxeu-monetization/product-catalog-api-client';
import { Section } from '../../components/Section';
import { ModalContext } from '../../context';
import { Messages } from '../../intl';
import { FormListField } from '../../layouts/ProductFormLayout/FormList';
import { mapProductCategorySetToValues } from '../../layouts/ProductFormLayout/helpers';
import { CategorySetSelectionModal } from '../../modals/CategorySetSelectionModal';
import { buildDataSource } from './buildDataSource';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const ProductCategorySetsTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    categorySetFields: FormListField[];
    categorySetListOperation: FormListOperation;
    categorySetsTypes?: CategorySetType[];
    categories: Category[];
    categorySetsTotalCount: number;
    categorySetAddDisabledReason?: string;
    categorySetsLimit?: number;
}

export const CategorySetsFormLayout = ({
    categorySetFields,
    categorySetListOperation,
    categorySetsTypes,
    categories,
    categorySetsTotalCount,
    categorySetAddDisabledReason,
    categorySetsLimit
}: Props) => {
    const modal = useContext(ModalContext);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const handleModalShow = (
        add: (value?: unknown) => void,
        selectedIds: string[]
    ) => {
        if (categorySetsTypes === undefined) {
            throw new Error(
                'Could not map chosen product type into a related category set type'
            );
        }

        modal.show(
            <CategorySetSelectionModal
                types={categorySetsTypes}
                categories={categories}
                selectedIds={selectedIds}
                onSelect={(categorySet) => {
                    const mappedCategorySet =
                        mapProductCategorySetToValues(categorySet);

                    add(mappedCategorySet);
                }}
            />,
            {
                footer: null,
                centered: true,
                width: 'auto'
            }
        );
    };

    return (
        <Section
            title={
                categorySetsLimit === 1
                    ? formatMessage(Messages['common.label.category-set'])
                    : formatMessage(Messages['common.label.category-sets'])
            }
            tags={
                <Tag color={theme.colors.tagCounter}>
                    {formatMessage(Messages['common.tag.selected-of'], {
                        selected: categorySetFields.length,
                        available: categorySetsTotalCount
                    })}
                </Tag>
            }
            extra={[
                <Tooltip
                    key="add-category-set"
                    trigger={
                        categorySetAddDisabledReason === undefined
                            ? []
                            : 'hover'
                    }
                    title={categorySetAddDisabledReason}
                >
                    <Button
                        icon={<PlusOutlined />}
                        disabled={
                            categorySetAddDisabledReason !== undefined ||
                            (!!categorySetsLimit &&
                                categorySetsLimit <= categorySetFields.length)
                        }
                        onClick={() =>
                            handleModalShow(
                                categorySetListOperation.add,
                                categorySetFields.map((field) =>
                                    field.getValue('id')
                                )
                            )
                        }
                    >
                        {formatMessage(Messages['common.button.add'])}
                    </Button>
                </Tooltip>,
                <Button
                    key="remove-all-category-sets"
                    icon={<DeleteOutlined />}
                    danger={true}
                    disabled={categorySetFields.length === 0}
                    onClick={() =>
                        categorySetListOperation.remove(
                            categorySetFields.map((field, index) => index)
                        )
                    }
                >
                    {formatMessage(Messages['common.button.remove-all'])}
                </Button>
            ]}
            data-testid="product-category-sets"
        >
            {categorySetFields.length > 0 ? (
                <ProductCategorySetsTable
                    columns={buildTableColumns({
                        intl,
                        onRemove: (index) => {
                            categorySetListOperation.remove(index);
                        }
                    })}
                    dataSource={buildDataSource(categorySetFields)}
                    scroll={{
                        y: theme.sizesRaw.categoriesTableMaxHeight
                    }}
                />
            ) : (
                <Alert
                    message={formatMessage(
                        Messages['common.info.no-category-sets-selected']
                    )}
                    showIcon
                />
            )}
        </Section>
    );
};
