import { ReactNode } from 'react';
import styled from 'styled-components';
import {
    display,
    DisplayProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    space,
    SpaceProps
} from 'styled-system';

type BoxWrapperProps = LayoutProps & SpaceProps & GridProps & DisplayProps;

const BoxWrapper = styled.div<BoxWrapperProps>`
    ${layout}
    ${space}
    ${grid}
    ${display}
`;

interface Props extends BoxWrapperProps {
    children: ReactNode;
}

export const Box = ({ children, ...rest }: Props) => (
    <BoxWrapper {...rest}>{children}</BoxWrapper>
);
