import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Typography } from 'antd';
import { Messages } from '../../../../intl';
import { FormListField } from '../../FormList';
import { buildTranslationAttributesDataSource } from '../utils';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';

const { Title } = Typography;

const AttributesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    metadataFields: FormListField[];
}

export const LabelAttributesTable = ({ metadataFields }: Props) => {
    const { formatMessage } = useIntl();

    const columns = buildTableColumns();

    const dataSource = useMemo(() => {
        return buildTranslationAttributesDataSource(metadataFields);
    }, [metadataFields]);

    if (!dataSource.length) {
        return null;
    }

    return (
        <>
            <Title level={5}>
                {formatMessage(Messages['common.label.attributes'])}
            </Title>
            <AttributesTable
                data-testid="label-attributes-table"
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={false}
            />
        </>
    );
};
