import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Tag, Typography } from 'antd';
import {
    MarketLanguage,
    TemplateTranslation
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';

const { CheckableTag } = Tag;
const { Text } = Typography;

interface Props {
    title: string;
    icon?: React.ReactNode;
    marketLanguages: MarketLanguage[];
    translations?: TemplateTranslation[];
}

export const TranslationCard = ({
    title,
    icon,
    translations,
    marketLanguages
}: Props) => {
    const [currentLocale, setCurrentLocale] = useState(marketLanguages[0].code);
    const { formatMessage } = useIntl();

    const displayedTranslationValue = translations?.find(
        (translation) => translation.language === currentLocale
    )?.value || (
        <Tag color="warning">
            {formatMessage(Messages['common.label.translation-not-defined'])}
        </Tag>
    );

    const localeIndicator = marketLanguages?.map((language) => (
        <CheckableTag
            key={language.code}
            checked={currentLocale === language.code}
            onChange={() => setCurrentLocale(language.code)}
        >
            {language.code}
        </CheckableTag>
    ));

    return (
        <Card title={title} extra={localeIndicator} size="small">
            {icon}
            <Text type="secondary" data-testid="label-translation-card-text">
                {displayedTranslationValue}
            </Text>
        </Card>
    );
};
