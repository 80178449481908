import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Space } from 'antd';
import { DataNode } from 'antd/lib/tree';
import {
    CategorySetId,
    CategorySetType
} from '@olxeu-monetization/product-catalog-api-client';
import {
    LimitId,
    LimitStatus,
    LimitType
} from '@olxeu-monetization/user-product-service-api-client';
import { LimitStatusTag } from '../../components/StatusTag';
import { CategorySetsFilter } from '../../components/Table/CategorySetsFilter';
// TODO: extract `ProductCategorySetsCell` outside of product context so it can be reused across product and limit tables
import { ProductCategorySetsCell } from '../../components/Table/ProductCategorySetsCell';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';

export interface Filters {
    name?: string;
    categorySetIds?: CategorySetId[];
    status?: LimitStatus;
    type?: LimitType;
}

export type TableDataSource = {
    categorySet: DataNode[];
    status: LimitStatus;
    id: LimitId;
};

export type TableFilters = {
    categorySet: CategorySetId[] | null;
    status: LimitStatus[] | null;
    id: null;
};

interface Options {
    filters: Filters;
    marketId: MarketId;
    intl: IntlShape;
}

export const buildTableColumns = ({
    filters,
    marketId,
    intl
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: (
                <FormattedMessage {...Messages['common.label.category-set']} />
            ),
            key: 'categorySet',
            dataIndex: 'categorySet',
            render: (categorySet) => (
                <ProductCategorySetsCell categorySets={categorySet} />
            ),
            filterDropdown: (props) => (
                <CategorySetsFilter
                    {...props}
                    categorySetsTypes={[CategorySetType.Limit]}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.category-set-search']
                    )}
                />
            ),
            filteredValue: filters.categorySetIds ?? []
        },
        {
            title: (
                <FormattedMessage
                    {...Messages['common.label.default-status']}
                />
            ),
            key: 'status',
            dataIndex: 'status',
            render: (status) => <LimitStatusTag status={status} />,
            filters: [
                {
                    value: LimitStatus.Active,
                    text: (
                        <FormattedMessage
                            {...Messages['common.label.enabled']}
                        />
                    )
                },
                {
                    value: LimitStatus.Disabled,
                    text: (
                        <FormattedMessage
                            {...Messages['common.label.disabled']}
                        />
                    )
                }
            ],
            filterMultiple: false,
            filteredValue: filters.status ? [filters.status] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'id',
            dataIndex: 'id',
            align: 'right',
            render: (id) => (
                <Space>
                    <Link
                        to={buildLink(Path.LimitDetails, {
                            id: id,
                            marketId
                        })}
                    >
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.open']}
                            />
                        </Button>
                    </Link>
                    <Link
                        to={buildLink(Path.LimitEdit, {
                            id: id,
                            marketId
                        })}
                    >
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.edit']}
                            />
                        </Button>
                    </Link>
                </Space>
            )
        }
    ];
};
