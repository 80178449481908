import { ReactNode } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Space, Typography } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { DataNode } from 'antd/lib/tree';
import {
    CategorySetId,
    FeatureId,
    FeatureTarget,
    OfferChangeId,
    ProductId,
    ProductStatus
} from '@olxeu-monetization/product-catalog-api-client';
import { ProductLockedWarning } from '../../components/ProductLockedWarning';
import { ProductStatusTag } from '../../components/StatusTag';
import {
    ProductFeaturesCell,
    renderSearchIcon,
    SearchFilter,
    TreeSelectFilter
} from '../../components/Table';
import { CategorySetsFilter } from '../../components/Table/CategorySetsFilter';
import { ProductCategorySetsCell } from '../../components/Table/ProductCategorySetsCell';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';

export interface Filters {
    search?: string;
    types?: string[];
    status?: ProductStatus;
    categorySetIds?: CategorySetId[];
    featureIds?: FeatureId[];
}

export type TableDataSource = {
    key: string;
    name: string;
    lockedBy?: OfferChangeId;
    type: string;
    status: ProductStatus;
    categorySets: DataNode[];
    features: {
        id: FeatureId;
        target: FeatureTarget;
    }[];
    actionLink: string;
};

export type TableFilters = {
    name: string[] | null;
    types: string[] | null;
    status: null;
    categorySets: CategorySetId[] | null;
    features: FeatureId[] | null;
    actions: null;
};

export enum ActionType {
    Open,
    Edit
}

const getMainActionButton = ({
    actionType,
    actionLink,
    onActionClick,
    marketId,
    offerChangeId
}: {
    actionType: ActionType;
    actionLink: string;
    onActionClick?: () => void;
    marketId: MarketId;
    offerChangeId?: OfferChangeId;
}) => {
    switch (actionType) {
        case ActionType.Open:
            return (
                <Link to={actionLink} onClick={onActionClick}>
                    <Button size="small">
                        <FormattedMessage {...Messages['common.button.open']} />
                    </Button>
                </Link>
            );
        case ActionType.Edit:
            if (offerChangeId !== undefined) {
                return (
                    <ProductLockedWarning
                        offerChangeId={offerChangeId}
                        marketId={marketId}
                    >
                        <Button size="small" disabled={true}>
                            <FormattedMessage
                                {...Messages['common.button.select']}
                            />
                        </Button>
                    </ProductLockedWarning>
                );
            }
            return (
                <Link to={actionLink} onClick={onActionClick}>
                    <Button size="small" type="primary">
                        <FormattedMessage
                            {...Messages['common.button.select']}
                        />
                    </Button>
                </Link>
            );
    }
};

interface Options {
    marketId: MarketId;
    featureOptions?: ColumnFilterItem[];
    typeOptions?: ColumnFilterItem[];
    filters: Filters;
    intl: IntlShape;
    actionType?: ActionType;
    onActionClick?: () => void;
    renderExtraActions?: (
        productId: ProductId,
        offerChangeId?: OfferChangeId
    ) => ReactNode;
}

export const buildTableColumns = ({
    marketId,
    featureOptions,
    typeOptions,
    filters,
    intl,
    actionType = ActionType.Open,
    onActionClick,
    renderExtraActions
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={record.actionLink}>
                    <Typography.Text data-cy="product-name">
                        {name}
                    </Typography.Text>
                </Link>
            ),
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.search ? [filters.search] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.type']} />,
            key: 'types',
            dataIndex: 'type',
            filterDropdown: (props) => <TreeSelectFilter {...props} />,
            filters: typeOptions,
            filterMultiple: true,
            filteredValue: filters.types ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.status']} />,
            key: 'status',
            dataIndex: 'status',
            render: (status) => <ProductStatusTag status={status} />
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.category-sets']} />
            ),
            key: 'categorySets',
            dataIndex: 'categorySets',
            render: (categorySets) => (
                <ProductCategorySetsCell categorySets={categorySets} />
            ),
            filterDropdown: (props) => (
                <CategorySetsFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.category-set-search']
                    )}
                />
            ),
            filteredValue: filters.categorySetIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.features']} />,
            key: 'features',
            dataIndex: 'features',
            render: (features) => <ProductFeaturesCell features={features} />,
            filterDropdown: (props) => (
                <TreeSelectFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.features-search']
                    )}
                />
            ),
            filters: featureOptions,
            filteredValue: filters.featureIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'actionLink',
            align: 'right',
            render: (actionLink, record) => (
                <Space>
                    {getMainActionButton({
                        actionType,
                        actionLink,
                        onActionClick,
                        marketId,
                        offerChangeId: record.lockedBy
                    })}
                    {renderExtraActions?.(record.key, record.lockedBy)}
                </Space>
            )
        }
    ];
};
