import { useContext } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Result } from 'antd';
import {
    CategorySetId,
    getCategorySet
} from '@olxeu-monetization/product-catalog-api-client';
import { Loader } from '../../components/Loader';
import {
    AuthContext,
    CategoriesDataContext,
    ConfigContext
} from '../../context';
import { Messages } from '../../intl';
import { CategorySetDetailsLayout } from '../../layouts/CategorySetDetailsLayout';
import { buildLink, MarketId, Path, PathParams } from '../../routing';
import { usePromise } from '../../utils';
import { buildBreadcrumbsRoutes as buildUpstreamBreadcrumbsRoutes } from '../CategorySetListPage';

type Params = PathParams[Path.CategorySetDetails];

const buildBreadcrumbsRoutes = (
    categorySetId: CategorySetId,
    marketId: MarketId,
    intl: IntlShape
) => {
    return [
        ...buildUpstreamBreadcrumbsRoutes(marketId, intl),
        {
            path: buildLink(Path.CategorySetDetails, {
                id: categorySetId,
                marketId
            }),
            breadcrumbName: intl.formatMessage(
                Messages['common.label.category-set']
            )
        }
    ];
};

export const CategorySetDetailsPage = () => {
    const intl = useIntl();
    const config = useContext(ConfigContext);
    const categoriesData = useContext(CategoriesDataContext);
    const auth = useContext(AuthContext);
    const params = useParams<Params>();

    const navigate = useNavigate();

    const listLink = buildLink(Path.CategorySetList, {
        marketId: params.marketId
    });

    const categorySetResult = usePromise({
        variables: {
            id: params.id
        },
        promiseBuilder: async (variables) => {
            return getCategorySet(variables, {
                baseUrl: config.services.productCatalogService.baseUrl,
                accessToken: await auth.getAccessToken()
            });
        }
    });

    const categoriesLoading = categoriesData.loading && !categoriesData.data;
    const categorySetLoading =
        categorySetResult.loading && !categorySetResult.data;

    const loading = categorySetLoading || categoriesLoading;

    if (loading) {
        return <Loader type="center" />;
    }

    const categories = categoriesData.data;
    const categorySet = categorySetResult.data?.data;

    const error = categorySetResult.error;

    if (error) {
        return (
            <Result
                status={error.status === 404 ? '404' : 'error'}
                title={error.status}
                subTitle={error?.details}
                extra={
                    <Button type="primary" onClick={() => navigate(listLink)}>
                        {intl.formatMessage(
                            Messages['common.button.go-to-category-set-list']
                        )}
                    </Button>
                }
            />
        );
    }

    if (!categorySet || !categories) {
        return null;
    }

    const breadcrumbRoutes = buildBreadcrumbsRoutes(
        params.id,
        params.marketId,
        intl
    );

    return (
        <CategorySetDetailsLayout
            categorySet={categorySet}
            categories={categories}
            marketId={params.marketId}
            breadcrumbRoutes={breadcrumbRoutes}
        />
    );
};
