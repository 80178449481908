import { UnorderedListOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CategoriesBox = styled.span`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

export const ListIcon = styled(UnorderedListOutlined)`
    color: ${({ theme }) => theme.colors.blue};
`;
