import { ColumnFilterItem } from 'antd/lib/table/interface';
import {
    CategorySetId,
    OfferChangeId,
    OfferChangeProduct
} from '@olxeu-monetization/product-catalog-api-client';
import { buildCategorySets } from '../../helpers';
import { buildLink, MarketId, Path } from '../../routing';
import { omitNodesWithSelectedDescendants } from '../../utils';
import { TableFilters } from './columns';

export const buildOfferChangeProductsDataSource = (
    marketId: MarketId,
    offerChangeId: OfferChangeId,
    products: OfferChangeProduct[],
    categorySetNamesMap: Map<CategorySetId, string>
) =>
    products.map((product) => ({
        key: product.id,
        changeType: product.changeType,
        name: product.name,
        type: product.type,
        status: product.status,
        categorySets: buildCategorySets(
            product.target.categorySetIds,
            categorySetNamesMap
        ),
        features: product.features,
        detailsLink: buildLink(Path.OfferChangeProductDetails, {
            id: offerChangeId,
            productId: product.id,
            marketId
        })
    }));

export const mapTableFiltersToFilters = (
    filters: TableFilters,
    featureOptions: ColumnFilterItem[]
) => {
    return {
        changeType: filters.changeType?.[0],
        search: filters.name?.[0],
        types: filters.types ?? [],
        status: filters.status?.[0],
        categorySetIds: filters.categorySets ?? [],
        featureIds: omitNodesWithSelectedDescendants(
            featureOptions,
            filters.features ?? [],
            'value'
        )
    };
};
